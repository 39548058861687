import {useQuery} from "@apollo/client";
import {GET_ALL_ROLES} from "./commands";
import {enqueueSnackbar} from "notistack";

/**
 *
 * @param props
 */
export function useRolesList(props) {
  const propsDefault = {
    role: null
  };
  const variables = {
    ...propsDefault, ...props
  };

  const {
    data,
    loading
  } = useQuery(GET_ALL_ROLES, {
    variables: variables,
    onError: (err) => {
      enqueueSnackbar(
        err.message,
        {
          variant: "error"
        }
      );
    },
    // fetchPolicy: "cache-and-network",
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
    errorPolicy: "all"
  });

  return {
    rolesList: data?.comRoles || [],
    loading
  };
}
