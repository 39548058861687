import { gql } from "@apollo/client";

export const UPDATE_CURRENT_USER_PROFILE = gql`
  mutation comUpdateAccount(
    $countryCode: String
    $currentTeam: String
    $currentTeamId: String
    $file: String
    $lang: String
    $name: String
    $profilePhotoPath: String
    $timezone: String
  ) {
    comUpdateAccount(
        countryCode: $countryCode
        currentTeam: $currentTeam
        currentTeamId: $currentTeamId
        file: $file
        lang: $lang
        name: $name
        profilePhotoPath: $profilePhotoPath
        timezone: $timezone
      )
    {
      success
      message
      user {
          id
          name
          email
          customerKey
          lang
          profilePhotoPath
          profilePhotoUrl
          countryCode
          timezone
          isSuperAdmin
          roles
          isVerified
          isActive
          currentTeam
          currentTeamId
      }
    }
  }
`;
