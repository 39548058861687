import {Box} from "@mui/system";
import React from "react";

export const UploadModalCarousel = (props) => {

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignContent: "space-between",
          p: 3,
          m: 0,
          color: "white",
          maxHeight: `calc(100vh - 150px )`,
          height: "500px",
          // height: `calc(100vh - 200px )`,
          // height: "inherit",
          borderRadius: 1,
          // maxHeight: 450,
          // height: `calc(100% - 150px )`,

          // backgroundColor: "Yellow"
        }}
      >
        <Box>
          <h2>{props.item.name}</h2>
        </Box>
        <Box>
          <h1>{props.item.heading}</h1>
          <p>{props.item.description}</p>
        </Box>
      </Box>
    </>
  );
};
