import { ModulesList } from "../../components";

export const DeveloperModules = (props) => {

  return (
    <ModulesList
      showEdit={true}
      showDelete={true}
    />
  );
};
