import { gql } from "@apollo/client";

export const GET_METERS_DATA_QUERY = gql`
  query metersData(
    $meterType: String!
    $meteringPointId: String
    $address: String
    $customer: String!
    $fromDate: DateTime!
    $toDate: DateTime!
    $resolution: String!
    $fileType: String!
  ) {
    metersData(
      meterType: $meterType
      meteringPointId: $meteringPointId
      address: $address
      customer: $customer
      fromDate: $fromDate
      toDate: $toDate
      resolution: $resolution
      fileType: $fileType
    ) {
      Id
      address
      meteringPointId
      ident
      customer
      cvr
      ident
      meteringPointId
      period {
        fromDate
        month
        toDate
      }
      unit
      type
      typeOfMP
      electricityGreen
      fileUrl
      colorSettings {
        electricity {
          background
          text
        }
        heating {
          background
          text
        }
        water {
          background
          text
        }
        gas {
          background
          text
        }
        energy {
          background
          text
        }
        co2 {
          background
          text
        }
      }
      total {
        consumptionV
        consumptionCost
        consumptionWeightedAvgAggregatedTax
        consumptionWeightedAvgPrice
        productionV
        productionCost
        productionWeightedAvgAggregatedTax
        productionWeightedAvgPrice
      }
      data {
        id: ts
        ts
        label
        consumptionConversionRate
        productionConversionRate
        consumptionCost
        productionCost
        consumptionV
        productionV
        consumptionCo2
        productionCo2
        vPerM2
        co2PerM2
        productionPrice
        consumptionPrice
        productionAggregatedTax
        consumptionAggregatedTax
      }
    }
  }
`;

// export const GET_METERS_DATA_QUERY = gql`
//   query metersData(
//     $meterType: String!
//     $meteringPointId: String
//     $address: String
//     $customer: String!
//     $fromDate: DateTime!
//     $toDate: DateTime!
//     $resolution: String!
//   ) {
//     metersData(
//       meterType: $meterType
//       meteringPointId: $meteringPointId
//       address: $address
//       customer: $customer
//       fromDate: $fromDate
//       toDate: $toDate
//       resolution: $resolution
//     ) {
//       Id
//       electricityGreen
//       data {
//         ts
//         consumptionV
//         consumptionCo2
//         vPerM2
//         co2PerM2
//         productionV
//         productionCo2
//         productionEur
//         productionNok
//         productionSek
//         productionDkk
//         consumptionEur
//         consumptionNok
//         consumptionSek
//         consumptionDkk
//       }
//       fileUrl
//     }
//   }
// `;
