import { UsersList } from "../../components";

export const SystemUsers = (props) => {
  return (
    <UsersList
      // showAdd
      showEdit
      // showDeleteSingle
      // showChangePassword
      // showCompanies
      userFilter="system"
    />
  );
};
