import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useTextDomainContext} from "app/providers";
import {useAddUserCompany, useCompanies, useCountries, useRolesList} from "hooks";
import {Autocomplete, Box, Button, Drawer, Grid, Paper, Stack, TextField, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useSnackbar} from "notistack";
import PropTypes from "prop-types";
import {isEqual} from "lodash";
import {useParams} from "react-router";

export const AddUserCompany = (props) => {
  //region Loading data
  const {uid: userId} = useParams();
  const {enqueueSnackbar} = useSnackbar();
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);

  const {
    countries,
    loading: countriesLoading
  } = useCountries();
  const {
    customers,
    loading: loadingCustomers
  } = useCompanies();
  const {rolesList} = useRolesList();

  const initialValue = useMemo(() => {
    return {
      inputCountry: countries[0] || null,
      inputCompany: customers[0] || null,
      inputRole: rolesList[rolesList.length - 1] || null
    };
  }, [countries, customers, rolesList]);
  //endregion Loading data

  //region React Hook (useState)
  const [inputCountry, setInputCountry] = useState(initialValue.inputCountry);
  const [inputCustomer, setInputCustomer] = useState(initialValue.inputCompany);
  const [inputRole, setInputRole] = useState(initialValue.inputRole);
  //endregion React Hook (useState)

  const allCustomers = customers.filter((row) => row.country === inputCountry?.code);
  const companyRoles = rolesList.filter((row) => row.key.startsWith("company-"));

  //region React Hook (useEffect)
  useEffect(() => {
    if (!inputCountry?.code) {
      if (countries.length) {
        if (!isEqual(inputCountry, countries[0])) {
          setInputCountry(countries[0]);
        }
      }
    }
  }, [countries, initialValue, inputCountry]);

  //endregion React Hook (useEffect)

  //region Handlers

  const resetForm = useCallback(() => {
    // setUserId(initialValue.userId);
    // setInputName(initialValue.inputName);
    // setInputEmail(initialValue.inputEmail);
    // setInputPassword(initialValue.inputPassword);
  }, []);

  const {
    addCustomer,
    loading: adding
  } = useAddUserCompany(
    () => {
      enqueueSnackbar(inputCustomer?.name + gettext(" company has been added"), {
        variant: "success"
      });
      // setCustomerName("");
      // setinputRole("");
    });

  // const { addUserByAdmin, loading: adding } = useAddUserByAdmin(
  //   () => {
  //     resetForm();
  //     // handleClose();
  //     enqueueSnackbar(gettext("New User Added Successfully"), {
  //       variant: "success"
  //     });
  //   },
  //   error => {
  //     if (error === "409") {
  //       // setEmailError(true);
  //     } else if (error === "410") {
  //       enqueueSnackbar(gettext("You are not authorized to perform this action"), {
  //         variant: "error"
  //       });
  //     } else {
  //       enqueueSnackbar(gettext("Error: Something went wrong!"), {
  //         variant: "error"
  //       });
  //     }
  //   }
  // );

  // const { updateUser, loading: updating } = useUpdateUser(
  //   () => {
  //     resetForm();
  //     // handleClose();
  //     enqueueSnackbar(gettext("User Updated Successfully"), {
  //       variant: "success"
  //     });
  //   },
  //   error => {
  //     if (error === "409") {
  //       // setEmailError(true);
  //     } else if (error === "410") {
  //       enqueueSnackbar(gettext("You are not authorized to perform this action"), {
  //         variant: "error"
  //       });
  //     } else {
  //       enqueueSnackbar(`Backend Error: ${error}`, {
  //         variant: "error"
  //       });
  //     }
  //   }
  // );

  const submitHandler = e => {
    e.preventDefault();
    // addUserByAdmin({
    //   variables: {
    //     name: inputName,
    //     email: inputEmail,
    //     password: inputPassword,
    //     role: [inputRole?.key],
    //     partnerId: inputPartner?.id,
    //     countryCode: inputCountry?.code,
    //     lang: inputLang?.value,
    //     timezone: inputTimezone.zoneName,
    //     status: inputStatus.value
    //   },
    //   refetchQueries: ["comMyUsers"]
    // });

    const variables = {
      userId: userId,
      role: inputRole?.key,
      customerName: inputCustomer?.name
    };

    addCustomer(variables);

    // addCustomer({
    //   variables: variables,
    //   refetchQueries: ["getUserCustomers"]
    // });
  };

  const handleClose = () => {
    resetForm();
    props.handleClose();
  };
  //endregion Handlers

  return (
    <Drawer
      anchor={"right"}
      open={props.open}
      onClose={handleClose}
      sx={{
        zIndex: theme => theme.zIndex.appBar + 1
      }}
      PaperProps={{
        sx: {
          padding: 4,
          minWidth: 400,
          maxWidth: 720
        }
      }}
    >
      <Box sx={{
        width: "auto"
      }}>
        <form onSubmit={submitHandler}>
          <Stack spacing={4} sx={{
            height: "100%"
          }}>
            <Typography fontSize={22} fontWeight={700}>
              {!props.row.id && gettext("Attach Company with User")}
              {props.row.id && gettext("Edit User Company")}
            </Typography>

            <Box sx={{
              height: "100%"
            }}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    options={countries}
                    getOptionLabel={option => option.name || ""}
                    getOptionKey={option => option.code || ""}
                    value={inputCountry}
                    onChange={(e, newValue) => {
                      setInputCountry(newValue);
                      // setInputTimezone(initialValue.inputTimezone);
                    }}
                    size="small"
                    fullWidth
                    isOptionEqualToValue={(option, value) => option?.value?.toLowerCase() === value?.value?.toLowerCase()}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label={gettext("Country")}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    options={companyRoles}
                    getOptionLabel={option => option.role || ""}
                    getOptionKey={option => option.key || ""}
                    value={inputRole}
                    onChange={(e, newValue) => setInputRole(newValue)}
                    size="small"
                    fullWidth
                    isOptionEqualToValue={(option, value) => option?.value?.toLowerCase() === value?.value?.toLowerCase()}
                    renderInput={params => (
                      <TextField {...params} required label={gettext("Role")}/>
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    options={allCustomers}
                    getOptionLabel={option => option.name || ""}
                    getOptionKey={option => option.id || ""}
                    value={inputCustomer}
                    onChange={(e, newValue) => setInputCustomer(newValue)}
                    size="small"
                    fullWidth
                    isOptionEqualToValue={(option, value) => option?.value?.toLowerCase() === value?.value?.toLowerCase()}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label={gettext("Company")}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>

            <Paper elevation={0}>
              <Grid container spacing={4}>
                <Grid item xs={6} textAlign="center">
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => handleClose()}
                  >
                    {gettext("Close")}
                  </Button>
                </Grid>

                <Grid item xs={6} textAlign="center">
                  <LoadingButton
                    variant="contained"
                    loading={adding || countriesLoading || loadingCustomers}
                    fullWidth
                    type="submit"
                  >
                    {gettext("Add")}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Paper>
          </Stack>
        </form>
      </Box>
    </Drawer>
  );
};

AddUserCompany.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  row: PropTypes.object
};
