import React, {createContext, useContext, useEffect, useState} from "react";
import {Box} from "@mui/system";
import {CircularProgress} from "@mui/material";

const SplashScreenContext = createContext();

export function SplashScreenProvider({children}) {
  const [count, setCount] = useState(0);
  const visible = count > 0;

  useEffect(() => {
    // const splashScreen = document.getElementById("splash-screen");
    const splashScreen = SplashScreen;

    // Show SplashScreen
    if (splashScreen && visible) {
      // splashScreen.classList.remove("hidden");

      return () => {
        // splashScreen.classList.add("hidden");
      };
    }

    // Hide SplashScreen
    let timeout;
    if (splashScreen && !visible) {
      timeout = setTimeout(() => {
        // splashScreen.classList.add("hidden");
      }, 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [visible]);

  return (
    <SplashScreenContext.Provider value={setCount}>
      {children}
    </SplashScreenContext.Provider>
  );
}

export function SplashScreen({visible = true}) {

  const setCount = useContext(SplashScreenContext);

  useEffect(() => {
    if (!visible) {
      return;
    }

    setCount(prev => {
      return prev + 1;
    });

    return () => {
      setCount(prev => {
        return prev - 1;
      });
    };
  }, [setCount, visible]);

  // return null;

  return (
    <Box style={{
    }} sx={{
      position: "absolute",
      zIndex: 1000,
      width: "100vw",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "background.default",
      opacity: 0.5
    }}>
      <img src={"/media/partners/energiteam/logos/logo-dark.png"} alt="Your Logo" style={{
        width: "100%",
        height: "auto",
        maxWidth: "300px"
      }}/>
      <br/>
      <CircularProgress/>
    </Box>
  );
}

export default SplashScreen;
