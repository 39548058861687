import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import ReactGA from "react-ga4";
import { REGISTER } from "./commands";
import { useAuth } from "../../app/providers";

/**
 *
 * @param onCompleted
 * @param onError
 */
export function useRegister(
  onCompleted = () => null,
  onError = () => null
) {
  const { setToken } = useAuth();

  const [comRegister, { loading }] = useMutation(REGISTER, {
    onCompleted: ({ comRegister }) => {
      localStorage.clear();

      setToken(comRegister?.token);
    },
    onError: error => {
      onError(error);
    }
  });

  const submit = useCallback(
    async credentials => {
      try {
        await comRegister({
          variables: credentials
        });

        ReactGA.event({
          category: "Authentication",
          action: "Login",
          label: credentials?.email.replace("@", "[at]")
        });
      } catch (error) { /* empty */
      }
    },
    [comRegister]
  );

  return {
    submit,
    isSubmitting: loading
  };
}
