/**
 *
 * @param theme
 */
export default function TextField(theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderRadius: 4,
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
        }
      }
    }
  };
}
