import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

export const ExportToPdf = (data, filename = "data.pdf") => {
  if (!Array.isArray(data) || !data.length) {
    throw new Error("Invalid data format. Expected an array of objects or a 2D array.");
  }

  // Create a new jsPDF instance
  const doc = new jsPDF();

  // // Add page header with logo
  // const logoUrl = 'path/to/logo.png'; // Replace 'path/to/logo.png' with the actual path to your logo
  // const logoWidth = 50; // Adjust logo width as needed
  // const logoHeight = 50; // Adjust logo height as needed
  // const headerText = 'Your Company Name'; // Add your company name or any other header text
  //
  // doc.addImage(logoUrl, 'PNG', 10, 10, logoWidth, logoHeight); // Adjust position as needed
  // doc.text(headerText, 70, 30); // Adjust position as needed
  //
  // let y = 50; // Start position for table below header

  const y = 10;

  if (typeof data[0] === "object" && !Array.isArray(data[0])) {
    // For array of objects
    const headers = Object.keys(data[0]);
    const tableData = data.map(item => headers.map(header => item[header] || ""));

    autoTable(doc, {
      headStyles: {
        fillColor: [0, 0, 0],
        textColor: [255, 255, 255]
      },
      head: [headers],
      body: tableData
    });

    // // Add headers to the PDF
    // autoTable(doc, {
    //   startY: y,
    //   head: [headers]
    // });
    //
    // // Add data to the PDF
    // autoTable(doc, {
    //   startY: y + 10,
    //   body: tableData
    // });

  } else if (Array.isArray(data[0])) {
    // For 2D array
    const tableData = data.map(row => row.map(cell => cell || ""));

    // Add data to the PDF
    autoTable(doc, {
      startY: y,
      body: tableData
    });
  } else {
    throw new Error("Invalid data format. Expected an array of objects or a 2D array.");
  }

  // Save the PDF
  doc.save(filename);
};
