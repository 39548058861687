import {Link} from '@mui/material';
import {useForgotPassword} from "../../../hooks";
import {useTextDomainContext, useUser} from "../../providers";
import {enqueueSnackbar} from "notistack";
import {useContext, useState} from "react";

export const ForgotPasswordLink = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const {user} = useUser();
  const {
    submit,
    isSubmitting,
    resetSuccess,
    useNotFound
  } = useForgotPassword(
    () => {
      enqueueSnackbar(gettext("A link has been sent to your email address to reset password"), {
        variant: "success"
      });
    },
    error => {
      enqueueSnackbar(error?.message, {
        variant: "error"
      });
    }
  );

  const [linkClicked, setLinkClicked] = useState(false);

  const linkOnClick = () => {
    if (!linkClicked) {
      if (user?.email) {
        submit({
          email: user.email
        }).then(() => {
          if (resetSuccess) {
            enqueueSnackbar(gettext("A link has been sent to your email address to reset password"), {
              variant: "success"
            });
          }
          if (useNotFound) {
            enqueueSnackbar(gettext("We are unable to find your email address"), {
              variant: "error"
            });
          }
          setLinkClicked(true);
          setTimeout(function () {
            setLinkClicked(false);
          }, 30000);
        });
      } else {
        enqueueSnackbar(gettext("The session has been expired."), {
          variant: "error"
        });
      }
    }
  };
  return (
    <>
      <Link onClick={linkOnClick} sx={{
        cursor: "pointer",
        textDecoration: "none",
        fontWeight: 500,
        fontSize: "0.9rem",
        px: 1
      }}>
        {isSubmitting && (
          <>
            {gettext("Please wait")}
          </>
        )}
        {linkClicked && !isSubmitting && (
          <>
            {gettext("The email has been sent")}
          </>
        )}
        {!linkClicked && !isSubmitting && (
          <>
            {gettext("Forgot your password?")}
          </>
        )}
      </Link>
    </>
  );
};