import {UsersList} from "../../components";

export const AllUsers = (props) => {
  return (
    <UsersList
      showAdd
      showEdit
      // showDeleteSingle
      showChangePassword
      showCompanies
      userFilter="all"
    />
  );
};
