import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { DELETE_MODULE } from "./commands";
import { enqueueSnackbar } from "notistack";

/**
 *
 * @param onCompleted
 * @param onError
 */
export function useDeleteModule(
  onCompleted = () => {
  },
  onError = () => {
  }) {
  const [deleteModule, { loading }] = useMutation(DELETE_MODULE, {
    onCompleted,
    onError: (err) => {
      onError(err);

      enqueueSnackbar(
        "Delete Module: " + err.message,
        {
          variant: "error"
        }
      );
    },
    errorPolicy: "all"
  });

  const deleteModuleRow = useCallback(
    variables => {
      deleteModule({
        variables,
        refetchQueries: ["getAllModules"]
      }).then();
    },
    [deleteModule]
  );

  return {
    deleteModule: deleteModuleRow,
    deleting: loading
  };
}
