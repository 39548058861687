import {useMutation} from "@apollo/client";
import {useCallback} from "react";
import {DELETE_DEAL} from "./commands";
import {enqueueSnackbar} from "notistack";

/**
 *
 * @param onCompleted
 * @param onError
 */
export function useDeleteDeal(onCompleted = () => {
}, onError = () => {
}) {
  const [deleteDeal, {loading}] = useMutation(DELETE_DEAL, {
    onCompleted,
    onError: (err) => {
      onError(err);

      enqueueSnackbar(
        err.message,
        {
          variant: "error"
        }
      );
    },
    refetchQueries: ["GetAllDeals"],
    errorPolicy: "all"
  });

  const deleteDealRow = useCallback(
    variables => {
      deleteDeal({
        variables
      }).then();
    },
    [deleteDeal]
  );

  return {
    delete: deleteDealRow,
    deleting: loading
  };
}
