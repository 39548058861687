import useTimeout from "../useTimeout";
import { useEffect, useState } from "react";

/**
 *
 * @param value
 * @param delay
 */
export default function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const { reset, clear } = useTimeout(() => setDebouncedValue(value), delay);
  useEffect(reset, [value, reset]);
  useEffect(clear, [clear]);

  return debouncedValue;
}
