// src/BarChart.js
import React, {useEffect, useRef} from 'react';
import * as d3 from 'd3';
import "./Styles/BarChart.css";

const BarChart = (props) => {
  const data = props.data.data.dataset;
  const series = props.data.data.series;
  const svgRef = useRef();
  // const [width, setWidth] = useState(0);
  const maxWidth = 20;
  const innerPadding = 10;

  // useEffect(() => {
  //   if (!svgRef.current) return;
  //   setWidth(svgRef.current.clientWidth);
  // }, [setWidth, svgRef]);

  useEffect(() => {
    if (!svgRef.current) return;
    const keyColors = {
    };
    const keyLabels = {
    };
    const keyUnits = {
    };

    series.map((obj) => {
      keyColors[obj.dataKey] = obj.color;
      keyLabels[obj.dataKey] = obj.label;
      keyUnits[obj.dataKey] = ` (${obj.unit})`;
      return null;
    });

    const colors = series.map((obj) => {
      return obj.color;
    });

    const fields = series.map((obj) => {
      return obj.dataKey;
    });

    d3.select(svgRef.current).selectAll('*').remove();

    // Create tooltip element
    const tooltip = d3.select('body')
      .append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0);

    // Set up chart dimensions
    const margin = {
      top: 20,
      right: 30,
      bottom: 40,
      left: 40
    };
    // const width = 800 - margin.left - margin.right;
    const width = svgRef.current.clientWidth - margin.left - margin.right + 2 * innerPadding;
    const height = 300 - margin.top - margin.bottom;

    // Create SVG element
    const svg = d3.select(svgRef.current)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // const fields = ['consumption', 'co2', 'water', 'gas', 'energy'];
    // const fields = ['consumption'];

    // Set up scales
    const x0 = d3.scaleBand()
      .domain(data.map(d => d.label))
      // .rangeRound([0, width])
      .rangeRound([innerPadding, width - innerPadding])
      .paddingInner(0.01);

    const x1 = d3.scaleBand()
      .domain(fields)
      // .rangeRound([0, x0.bandwidth()])
      .rangeRound([0, Math.min(x0.bandwidth(), maxWidth * fields.length)])
      .padding(0.05);

    const y = d3.scaleLinear()
      // .domain([0, d3.max(data, d => Math.max(d.consumption, d.co2))])
      .domain([0, d3.max(data, d => Math.max(...fields.map(field => d[field] || 0)))])
      .nice()
      .range([height, 0]);

    const color = d3.scaleOrdinal()
      .domain(['consumption', 'co2'])
      // .range(['#1f77b4', '#ff7f0e']);
      .range(colors);

    // Add horizontal grid lines
    svg.append('g')
      .attr('class', 'grid')
      .attr("color", "#e5e7eb")
      .call(d3.axisLeft(y)
        .tickSize(-width)
        .tickFormat(''));

    // Add axes
    svg.append('g')
      .selectAll('g')
      .data(data)
      .enter()
      .append('g')
      .attr('transform', d => `translate(${x0(d.label)},0)`)
      .selectAll('rect')
      // .data(d => ['consumption', 'co2'].map(key => ({
      //   key,
      //   value: d[key]
      // })))
      .data(d => fields.map(key => ({
        key,
        value: d[key] || 0,
        xLabel: d["label"],
        series: series
      })))
      .enter()
      .append('rect')
      // .attr('x', d => x1(d.key))
      .attr('x', d => {
        const bandwidth = Math.min(x1.bandwidth(), maxWidth);
        return (x0.bandwidth() - (bandwidth * fields.length)) / 2 + x1(d.key);
      })
      .attr('y', d => y(d.value))
      // .attr('width', x1.bandwidth())
      .attr('width', Math.min(x1.bandwidth(), maxWidth))
      .attr('height', d => height - y(d.value))
      .attr('fill', d => color(d.key))
      .on('mouseover', (event, d) => {
        tooltip.transition()
          .duration(200)
          .style('opacity', 1);

        tooltip.html(`
          <div style="padding: 0; margin: 0">
            <div style="text-align: left; font-weight: bold; padding: 10px;">
              ${d.xLabel}
            </div>
            <div style="border-bottom: 1px solid #ccc"></div>
            <div style="text-align: left; padding: 10px;">
              <div style="float: left; height: 12px; width: 12px; margin-right: 10px; background-color:${keyColors[d.key]}; border-radius: 100%;"></div>
              ${keyLabels[d.key]}: <span style="color:${keyColors[d.key]}">${d.value}${keyUnits[d.key]}</span>
            </div>
          </div>
        `)
          .style('left', `${Math.min(event.pageX + 5, window.innerWidth - 250)}px`)
          .style('top', `${Math.min(event.pageY - 28, window.innerHeight - 150)}px`);
      })
      .on('mouseout', () => {
        tooltip.transition()
          .duration(500)
          .style('opacity', 0);
      });

    svg.append('g')
      .attr('class', 'axis')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x0));

    svg.append('g')
      .attr('class', 'axis')
      .call(d3.axisLeft(y));

  }, [data, series]);

  return (
    <svg
      ref={svgRef}
      style={{
        width: '100%'
      }}
      // height={height}
    ></svg>
  );
};

export default BarChart;