import React from "react";
// import {sendSlackMessage} from "../common/Functions";
import {Error500} from "../pages";
import {SendSlackMessage} from "../../utils";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      info: null
    };
  }

  componentDidMount() {
    const {user} = this.props;
    const userId = user && user.id ? user.id : "N/A";
    const userEmail = user && user.email ? user.email : "N/A";
    const userName = user && user.name ? user.name : "N/A";
    const teamName = user && user.currentTeam ? user.currentTeam : "NA";

    // Override the default console.error behavior
    if (process.env.REACT_APP_NODE_ENV === "production") {
      const originalConsoleError = console.error;
      console.error = function (...args) {
        const message = args.join(' ').split('\n').join('\\n');

        const slackErrorData = {
          channel: "error",
          type: "error",
          heading: window.location.href,
          detail: message,
          fields: [
            {
              title: "User ID",
              value: userId
            },
            {
              title: "User Name",
              value: userName
            },
            {
              title: "User Email",
              value: userEmail
            },
            {
              title: "User Team",
              value: teamName
            }
          ]
        };
        SendSlackMessage(slackErrorData);

        originalConsoleError.apply(console, args);
      };
    }
  }

  componentDidCatch(error, info) {
    const {user} = this.props;
    const userId = user && user.id ? user.id : "N/A";
    const userEmail = user && user.email ? user.email : "N/A";
    const userName = user && user.name ? user.name : "N/A";
    const teamName = user && user.currentTeam ? user.currentTeam : "NA";

    this.setState({
      hasError: true
    });

    this.setState({
      error: error,
      info: info
    });

    if (process.env.REACT_APP_NODE_ENV === "production") {
      const slackErrorData = {
        channel: "error",
        type: "error",
        heading: window.location.href,
        detail: error.toString().split('\n').join('\\n'),
        fields: [
          {
            title: "User ID",
            value: userId
          },
          {
            title: "User Name",
            value: userName
          },
          {
            title: "User Email",
            value: userEmail
          },
          {
            title: "User Team",
            value: teamName
          }
        ]
      };
      SendSlackMessage(slackErrorData);
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      // return <h1>Something went wrong. {process.env.NODE_ENV}</h1>;
      if (process.env.REACT_APP_NODE_ENV !== "production") {
        return (
          <>
            <h1>Something went wrong. {process.env.REACT_APP_NODE_ENV}</h1>
            <p>
              <pre>{this.state.error.stack}</pre>
              <hr/>
              <h2>Full Stack</h2>
              <pre>{this.state.info.componentStack}</pre>
            </p>
          </>
        );
      } else {
        return <Error500/>;
      }
    }
    return this.props.children;
  }
}

// export default withUser(ErrorBoundary);
export default ErrorBoundary;
