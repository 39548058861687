import md5 from "md5";
import {getMonthsArray} from "../dates";

export const EnergyMonthlyRows = (data, gettext) => {
  const rows = [];

  let sumOfGradSum = 0;
  const sumOfMeterSum = [];
  const consumptionData = data?.consumptionEnergyData?.consumptionData;
  const monthsArray = getMonthsArray(gettext);

  // if (data?.consumptionEnergyData?.consumptionData[0]?.details?.data[0]?.meter) {
  if (Array.isArray(consumptionData) && consumptionData.length > 0 && consumptionData[0]?.details?.data) {
    data.consumptionEnergyData.consumptionData.map((objConsumptionData) => {
      const monthIndex = parseInt(objConsumptionData.month.slice(-2)) - 1;
      const selectedMonth = monthsArray[monthIndex].value;
      if (!rows["all"]) {
        rows["all"] = [];
      }

      if (!rows[selectedMonth]) {
        rows[selectedMonth] = [];
      }

      const grandSum = objConsumptionData.details.grandSum;
      sumOfGradSum += grandSum;
      objConsumptionData.details.data.map((data) => {
        const rowIndex = [];
        const id = "summary";
        const orderNo = data?.meter;
        rowIndex["all"] = rows["all"].findIndex(obj => obj.id === id);
        rowIndex[selectedMonth] = rows[selectedMonth].findIndex(obj => obj.id === id);
        if (typeof sumOfMeterSum[orderNo] === "undefined") {
          sumOfMeterSum[orderNo] = 0;
        }
        sumOfMeterSum[orderNo] += data.consumptionSum;
        if (rowIndex["all"] > -1) {
          rows["all"][rowIndex["all"]].sum = sumOfGradSum;
          rows["all"][rowIndex["all"]][orderNo] = sumOfMeterSum[orderNo];
        } else {
          const row = {
            id: id,
            ts: "summary",
            sum: sumOfGradSum,
          };
          row[orderNo] = data.consumptionSum;
          rows["all"].push(row);
        }

        if (rowIndex[selectedMonth] > -1) {
          rows[selectedMonth][rowIndex[selectedMonth]].sum = grandSum;
          rows[selectedMonth][rowIndex[selectedMonth]][orderNo] = sumOfMeterSum[orderNo];
        } else {
          const row = {
            id: id,
            ts: "summary",
            sum: grandSum,
          };
          row[orderNo] = data.consumptionSum;
          rows[selectedMonth].push(row);
        }

        data.data.map((hourlyData) => {
          const rowIndex = [];
          const id = md5(hourlyData.ts);
          rowIndex["all"] = rows["all"].findIndex(obj => obj.id === id);
          rowIndex[selectedMonth] = rows[selectedMonth].findIndex(obj => obj.id === id);
          if (rowIndex["all"] > -1) {
            rows["all"][rowIndex["all"]][orderNo] = hourlyData.consumptionV;
          } else {
            const row = {
              id: id,
              ts: hourlyData.ts,
            };
            row[orderNo] = hourlyData.consumptionV;
            rows["all"].push(row);
          }

          if (rowIndex[selectedMonth] > -1) {
            rows[selectedMonth][rowIndex[selectedMonth]][orderNo] = hourlyData.consumptionV;
          } else {
            const row = {
              id: id,
              ts: hourlyData.ts,
            };
            row[orderNo] = hourlyData.consumptionV;
            rows[selectedMonth].push(row);
          }
          return null;
        });
        return null;
      });

      objConsumptionData.details.hourlyAggregatedData.map((objHourlyAggregatedData) => {
        const rowIndex = [];
        const id = md5(objHourlyAggregatedData.ts);
        rowIndex["all"] = rows["all"].findIndex(obj => obj.id === id);
        rowIndex[selectedMonth] = rows[selectedMonth].findIndex(obj => obj.id === id);

        if (rowIndex["all"] > -1) {
          rows["all"][rowIndex["all"]].sum = objHourlyAggregatedData.consumptionV;
        } else {
          const row = {
            id: id,
            ts: objHourlyAggregatedData.ts,
            sum: objHourlyAggregatedData.consumptionV,
          };
          rows["all"].push(row);
        }

        if (rowIndex[selectedMonth] > -1) {
          rows[selectedMonth][rowIndex[selectedMonth]].sum = objHourlyAggregatedData.consumptionV;
        } else {
          const row = {
            id: id,
            ts: objHourlyAggregatedData.ts,
            sum: objHourlyAggregatedData.consumptionV,
          };
          rows[selectedMonth].push(row);
        }

        return null;
      });

      // row.id = md5(obj.)
      return null;
    });


    // columns.push(dateColumn(gettext));
    // columns.push(numberColumn("sum", gettext("Sum"), locale));
    // const dataForColumns = data.consumptionEnergyData.consumptionData[0].details.data;
    // dataForColumns.map((obj) => {
    //   columns.push(numberColumn(obj.meter, obj.meter, locale));
    //   return null;
    // });
  }

  return rows;
};