import {useUser} from "../../app/providers";
import {useEffect, useState} from "react";
import {isEqual} from "lodash";

export const HasPermission = (codes) => {
  const {user} = useUser();
  const [permissions, setPermissions] = useState([]);
  const [allow, setAllow] = useState(false);

  useEffect(() => {
    let p = [];
    p = permissions;
    if (user?.permissions?.length) {
      user?.permissions.forEach(role => {
        role?.modules.forEach(permission => {
          if (!p.includes(permission)) {
            p.push(permission);
          }
        });
      });
    }

    if (!isEqual(permissions, p)) {
      setPermissions(p);
    }
  }, [permissions, user?.permissions]);

  useEffect(() => {
    if (codes?.length) {
      setAllow(false);
      codes.forEach((moduleName) => {
        if (moduleName && permissions && permissions.length > 0) {
          const module = permissions.find(item => item === moduleName);
          if (module) {
            setAllow(true);
          } else {
            // setReturnElement(<Forbidden />);
          }
        } else {
          setAllow(false);
        }
      });
    }
  }, [codes, permissions]);

  return allow;
};