import {useQuery} from "@apollo/client";
import {GET_DEAL_BY_ID} from "./commands";
import {enqueueSnackbar} from "notistack";

/**
 *
 * @param id
 */
export function useDealById(id) {
  const {
    data,
    loading
  } = useQuery(GET_DEAL_BY_ID, {
    variables: {
      id: id
    },
    onError: (err) => {
      enqueueSnackbar(
        err.message,
        {
          variant: "error"
        }
      );
    },
    // fetchPolicy: "cache-and-network",
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
    errorPolicy: "all"
  });

  return {
    dealRow: data?.getDealById || [],
    loading
  };
}
