import React, {useContext, useEffect, useState} from "react";
import moment from "moment";
import Typography from "@mui/material/Typography";
import {DataGridComponent} from "app/components";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import {useTextDomainContext} from "app/providers";
import {Grid, IconButton, InputAdornment, OutlinedInput, Stack, Tooltip} from "@mui/material";
import {MdDelete} from "react-icons/md";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {DELETE_NOTIFICATION, GET_ALL_NOTIFICATIONS, READ_NOTIFICATION} from "app/components/AppBar/commands";
// import { Button, Modal, Spinner } from "react-bootstrap";
import {useMutation, useQuery} from "@apollo/client";

const Notification = () => {
  // const [delete_show, setdelete] = useState(false);
  // const [showNotification, setShowNotification] = useState(false);
  // const closeDeleteModal = () => setdelete(false);
  // const closeNotificationModal = () => setShowNotification(false);
  const {TextDomainContext} = useTextDomainContext();
  // const showDeleteModal = () => setdelete(true);
  // const [notificationMessage, setNotificationMessage] = useState("");
  const {gettext} = useContext(TextDomainContext);

  // const [notificationID, setNotificationID] = useState();

  const [notificationData, setNotificationData] = useState([]);

  const {
    data,
    loading
  } = useQuery(GET_ALL_NOTIFICATIONS);

  const [filteredList, setFilteredList] = useState([]);
  // const [deleteLoading, setDeleteLoading] = useState(false);

  const [notificationDelete] = useMutation(DELETE_NOTIFICATION, {
    onCompleted() {
      // setDeleteLoading(false);
      // setdelete(false);
    },
    onError() {
      // setDeleteLoading(false);
    },
    refetchQueries: ["comGetAllNotifications"]
  });

  const [readNotification] = useMutation(READ_NOTIFICATION, {
    onCompleted() {
    },
    onError() {
    },
    refetchQueries: ["comGetAllNotifications"]
  });

  // const onDeleteNotification = () => {
  //   setDeleteLoading(true);
  //   notificationDelete({
  //     variables: {
  //       notificationId: notificationID
  //     }
  //   });
  // };
  const handleDeleteNotification = notification => {
    // setNotificationID(notification);
    // showDeleteModal();
  };

  const handleShowNotificationModal = notification => {
    !notification?.row?.Read &&
    readNotification({
      variables: {
        notificationId: notification?.id
      }
    });

    // setNotificationMessage(notification?.row?.Message);
    // setShowNotification(true);
  };

  useEffect(() => {
    if (!loading) {
      setFilteredList(notificationData);
      setNotificationData(data ? data?.comGetAllNotifications : []);
    } else {
      setFilteredList("");
    }
  }, [data, notificationDelete, notificationData, loading]);

  const filterBySearch = event => {
    // Access input value
    const query = event.target.value;
    // Create copy of item list
    const updatedList = [...notificationData];
    // Include all elements which includes the search query
    const NewUpdatedList = updatedList.filter(item => {
      return item?.Message.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    // Trigger render with updated values
    setFilteredList(NewUpdatedList);
  };

  /**
   *
   * @param params
   */
  function columnFormater(params) {
    return (
      <div className="w-100 d-flex justify-content-center">
        <Tooltip title={gettext("Read Notification")} arrow>
          <IconButton onClick={() => handleShowNotificationModal(params)}>
            <VisibilityIcon size={16}/>
          </IconButton>
        </Tooltip>
        <Tooltip title={gettext("Delete")} arrow>
          <IconButton onClick={() => handleDeleteNotification(params?.id)}>
            <MdDelete size={16}/>
          </IconButton>
        </Tooltip>
      </div>
    );
  }

  /**
   *
   * @param params
   */
  function showMessage(params) {
    return (
      <div>
        {params?.row?.Read ? (
          <Typography fontSize="0.875rem" fontWeight={400}>
            {gettext(params?.row?.Message)}
          </Typography>
        ) : (
          <Typography fontSize="0.875rem" fontWeight={700}>
            {gettext(params?.row?.Message)}
          </Typography>
        )}
      </div>
    );
  }

  /**
   *
   * @param params
   */
  function showEmail(params) {
    return (
      <div>
        {params?.row?.Read ? (
          <Typography fontSize="0.875rem" fontWeight={400}>
            {gettext(params?.row?.email)}
          </Typography>
        ) : (
          <Typography fontSize="0.875rem" fontWeight={700}>
            {gettext(params?.row?.email)}
          </Typography>
        )}
      </div>
    );
  }

  /**
   *
   * @param params
   */
  function showDate(params) {
    return (
      <div>
        {params?.row?.Read ? (
          <Typography fontSize="0.875rem" fontWeight={400}>
            {moment(params?.row?.date).format("DD/MM/YYYY hh:mm A")}
          </Typography>
        ) : (
          <Typography fontSize="0.875rem" fontWeight={700}>
            {moment(params?.row?.date).format("DD/MM/YYYY hh:mm A")}
          </Typography>
        )}
      </div>
    );
  }

  const columns = [
    {
      field: "Message",
      headerName: gettext("Message"),
      flex: 3,
      headerAlign: "left",
      renderCell: params => showMessage(params)
    },
    {
      field: "email",
      headerName: gettext("Email"),
      flex: 1,
      headerAlign: "left",
      renderCell: params => showEmail(params)
    },
    {
      field: "date",
      headerName: gettext("Date"),
      flex: 1,
      headerAlign: "left",
      renderCell: params => showDate(params)
      // valueFormatter: (params) =>
      //   moment(params?.value).format("DD/MM/YYYY hh:mmA"),
    },
    {
      field: "actions",
      headerName: gettext("Actions"),
      flex: 1,
      minWidth: 200,
      maxWidth: 250,
      headerAlign: "center",
      renderCell: params => columnFormater(params)
    }
  ];
  return (
    <div>
      <>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <Typography fontSize={22} fontWeight={700}>
                  {gettext("Notifications")}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Box
                  sx={{
                    display: "flex"
                  }}
                >
                  <Box sx={{
                    flex: 1
                  }}/>

                  <Stack direction={"row"} spacing={2}>
                    <OutlinedInput
                      type={"text"}
                      size="small"
                      placeholder="Search"
                      onChange={filterBySearch}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            <SearchIcon/>
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <DataGridComponent
              rows={filteredList}
              columns={columns}
              loading={loading}
              disableSelectionOnClick={true}
              autoHeight
            />
          </Grid>
        </Grid>
      </>

      {/*<Modal*/}
      {/*  show={delete_show}*/}
      {/*  onHide={closeDeleteModal}*/}
      {/*  animation*/}
      {/*  size="lg"*/}
      {/*  aria-labelledby=""*/}
      {/*  centered*/}
      {/*  style={{*/}
      {/*    backgroundColor: "transparent"*/}
      {/*  }}*/}
      {/*>*/}
      {/*  /!*<Modal.Header closeButton>*!/*/}
      {/*  /!*  <Modal.Title>Delete Notification</Modal.Title>*!/*/}
      {/*  /!*</Modal.Header>*!/*/}
      {/*  /!*<Modal.Body>*!/*/}
      {/*  /!*  {gettext("Are you sure you want to delete this notification ? ")}*!/*/}
      {/*  /!*</Modal.Body>*!/*/}
      {/*  /!*<Modal.Footer>*!/*/}
      {/*  /!*  <Button variant="secondary" onClick={closeDeleteModal}>*!/*/}
      {/*  /!*    {gettext("Close")}*!/*/}
      {/*  /!*  </Button>*!/*/}
      {/*  /!*  {loading ? (*!/*/}
      {/*  /!*    <Spinner animation="border" variant="dark" />*!/*/}
      {/*  /!*  ) : (*!/*/}
      {/*  /!*    <Button variant="primary" onClick={onDeleteNotification}>*!/*/}
      {/*  /!*      {gettext("Delete")}*!/*/}
      {/*  /!*    </Button>*!/*/}
      {/*  /!*  )}*!/*/}
      {/*  /!*</Modal.Footer>*!/*/}
      {/*  {deleteLoading ? (*/}
      {/*    <div*/}
      {/*      className="position-absolute bg-dark opacity-50 w-100 h-100 d-flex justify-content-center align-items-center">*/}
      {/*      /!*<Spinner animation="border" variant="light" />*!/*/}
      {/*    </div>*/}
      {/*  ) : null}*/}
      {/*</Modal>*/}
      {/*<Modal*/}
      {/*  show={showNotification}*/}
      {/*  onHide={closeNotificationModal}*/}
      {/*  animation*/}
      {/*  size="lg"*/}
      {/*  aria-labelledby=""*/}
      {/*  centered*/}
      {/*  style={{*/}
      {/*    backgroundColor: "transparent"*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Modal.Header closeButton>*/}
      {/*    <Modal.Title>Notification</Modal.Title>*/}
      {/*  </Modal.Header>*/}
      {/*  <Modal.Body>{gettext(notificationMessage)}</Modal.Body>*/}
      {/*  <Modal.Footer>*/}
      {/*    <Button variant="secondary" onClick={closeNotificationModal}>*/}
      {/*      {gettext("Close")}*/}
      {/*    </Button>*/}
      {/*  </Modal.Footer>*/}
      {/*</Modal>*/}
    </div>
  );
};
export default Notification;
