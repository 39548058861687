export const AddIdToArrayOfObjects = (array) => {
  const existingIds = new Set();
  return array.map(obj => {
    if ('id' in obj) {
      return obj; // Return the object as it is without modifying it
    }

    const id = generateUniqueId(existingIds);
    existingIds.add(id); // Add the generated ID to the set
    return {
      ...obj,
      id
    }; // Return a new object with the added ID
  });
};

const generateUniqueId = (existingIds) => {
  let id;
  do {
    id = Math.floor(Math.random() * 1000000); // Generate a random number
  } while (existingIds.has(id)); // Check if the ID already exists
  return id;
};