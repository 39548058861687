import { UsersList } from "../../components";

export const CompanyUsers = (props) => {
  return (
    <UsersList
      // showAdd
      // showEdit
      // showDeleteSingle
      // showChangePassword
      // showCompanies
      userFilter="company"
    />
  );
};
