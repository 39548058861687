import {TableView1} from "../Table/TableView1";
import React, {useContext, useEffect, useState} from "react";
import {ConfirmNotification} from "../Dialogs";
// import {Spinner} from "react-bootstrap";
import {useDeleteModule, useModulesList, useRolesList, useUpdateRoleModules} from "../../../hooks";
import {useTextDomainContext} from "../../providers";
import {Button, IconButton, Tooltip} from "@mui/material";
import {FaUserEdit} from "react-icons/fa";
import {MdDelete} from "react-icons/md";
import {AddModule} from "./AddModule";
import {useParams} from "react-router";
import PropTypes from "prop-types";
import {enqueueSnackbar} from "notistack";

export const ModulesList = (props) => {
  const {key} = useParams();
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  // const deleting = false;
  const {
    modulesList,
    loading: loadingList
  } = useModulesList();
  const {
    deleteModule,
    deleting
  } = useDeleteModule();
  const {rolesList} = useRolesList({
    role: key
  });

  // const { updateRoleModules, loading: loadingUpdate } = useUpdateRoleModules();

  const {
    addOrUpdateRoleModule,
    loading: loadingUpdate
  } = useUpdateRoleModules(
    () => {
      // resetForm();
      // handleClose();
      enqueueSnackbar(gettext("Role updated Successfully"), {
        variant: "success"
      });
    },
    error => {
      if (error === "409") {
        // setEmailError(true);
      } else if (error === "410") {
        enqueueSnackbar(gettext("You are not authorized to perform this action"), {
          variant: "error"
        });
      } else {
        enqueueSnackbar(gettext("Error: Something went wrong!"), {
          variant: "error"
        });
      }
    }
  );

  //region React Hook (useState)
  const [showConfirm, setShowConfirm] = useState(false);
  const [dialogParameters, setDialogParameters] = useState({
  });
  const [addFormOpen, setAddFormOpen] = useState(false);
  const [editRow, setEditRow] = useState({
  });
  const [selectedIds, setSelectedIds] = useState(rolesList[0]?.modules || []);
  const [roleName, setRoleName] = useState("");
  // const [disabledSaveButton, setDisabledSaveButton] = useState(true);
  //endregion React Hook (useState)

  //region React Hook (useEffect)
  useEffect(() => {
    if (rolesList[0]?.modules && key) {
      setSelectedIds(rolesList[0]?.modules);
      setRoleName(rolesList[0]?.role);
    }
  }, [key, rolesList]);
  //endregion React Hook (useEffect)

  const initialState = {
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 50
      }
    },
    columns: {
      columnVisibilityModel: {
        id: false,
        name: true,
        code: !key,
        desc: true,
        createdAt: false,
        updatedAt: false,
        actions: !!props.showEdit || !!props.showDelete
      }
    }
  };
  const columns = [
    {
      field: "id",
      headerName: gettext("ID"),
      checkboxSelection: true
    },
    {
      field: "name",
      headerName: gettext("Module"),
      // flex: 1,
      minWidth: 250,
      headerAlign: "left",
      type: "string"
    },
    {
      field: "code",
      headerName: gettext("code"),
      // flex: 1,
      minWidth: 200,
      headerAlign: "left",
      type: "string"
    },
    {
      field: "desc",
      headerName: gettext("Description"),
      flex: 1,
      // minWidth: 150,
      headerAlign: "left",
      type: "string"
    },
    {
      field: "createdAt",
      headerName: gettext("Created At"),
      headerAlign: "left",
      type: "date",
      valueGetter: ({value}) => value && new Date(value)
    },
    {
      field: "updatedAt",
      headerName: gettext("Updated At"),
      headerAlign: "left",
      type: "date",
      valueGetter: ({value}) => value && new Date(value)
    },
    {
      field: "actions",
      headerName: gettext("Actions"),
      hideable: true,
      maxWidth: "100",
      // flex: 1,
      // minWidth: 80,
      // maxWidth: 80,
      // headerAlign: "center",
      // type: "actions",
      renderCell: params => columnFormatter(params)
    }
  ];

  const performAction = (row, action) => {
    switch (action) {
      case "edit":
        handleEdit(row);
        break;
      case "delete":
        handleOnClickDeleteSingle(row);
        break;
      default:
        break;
    }
  };

  const columnFormatter = (params) => {
    return (
      <div className="w-100 d-flex justify-content-center">
        {!!props.showEdit && (
          <Tooltip title={gettext("Edit")} arrow>
            <IconButton onClick={() => performAction(params.row, "edit")}>
              <FaUserEdit size={16}/>
            </IconButton>
          </Tooltip>
        )}

        {!!props.showDelete && (
          <Tooltip title={gettext("Delete")} arrow>
            <IconButton onClick={() => performAction(params.row, "delete")}>
              <MdDelete size={16}/>
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  };

  const handleEdit = (row) => {
    setEditRow(row);
    setAddFormOpen(true);
  };

  const handleOnClickDeleteSingle = (row) => {
    setDialogParameters({
      action: "delete-single",
      code: row.code
    });
    setShowConfirm(true);
  };

  const handleOnClickDeleteMultiple = (ids) => {
    setDialogParameters({
      action: "delete-multi",
      ids: ids
    });
    setShowConfirm(true);
  };

  const handleGetResult = (obj) => {
    if (obj.result === "yes") {
      deleteModule({
        code: obj?.parameters?.code
      });
    }
    setShowConfirm(false);
  };

  const SavePermissions = () => {
    const variables = {
      moduleCodes: selectedIds,
      roleKey: key
    };
    addOrUpdateRoleModule({
      variables: variables
      // refetchQueries: ["getAllRoles"]
    }).then();
  };

  const handleOnRowSelectionModelChange = (ids) => {
    setSelectedIds(ids.concat(ids, ["allow-to-all"]));
    // if (ids.length) {
    //   setDisabledSaveButton(false);
    // } else {
    //   setDisabledSaveButton(true);
    // }
  };

  const postToolbar = () => {
    return (
      <>
        {!key && (
          <Button variant="contained" onClick={() => {
            setAddFormOpen(true);
          }}>
            {gettext("Add permission")}
          </Button>
        )}
        {!!key && (
          <Button
            variant="contained"
            // disabled={disabledSaveButton}
            onClick={() => {
              SavePermissions();
            }}>
            {gettext("Save")}
          </Button>
        )}
      </>
    );
  };

  const handleCloseAddForm = (event) => {
    setAddFormOpen(event);
    setEditRow({
    });
  };

  return (
    <>
      <TableView1
        title={roleName ? gettext("Permissions for ") + roleName : gettext("Permissions")}
        rows={modulesList}
        // rows={[]}
        columns={columns}
        loading={loadingList || loadingUpdate}
        checkboxSelection={!!key}
        initialState={initialState}
        // preToolbar={preToolbar()}
        postToolbar={postToolbar()}
        // headerRight={headerRight()}
        showGridToolbar={true}
        showGridToolbarDeleteButton={false}
        showGridToolbarDensitySelector={false}
        showGridToolbarColumnsButton={false}
        showGridToolbarQuickFilter={false}
        showGridToolbarFilterButton={false}
        showGridToolbarExport={false}
        onRowSelectionModelChange={handleOnRowSelectionModelChange}
        onClickDeleteMultiple={handleOnClickDeleteMultiple}
        selectedIds={selectedIds}
      />
      <AddModule open={addFormOpen} handleClose={handleCloseAddForm} row={editRow}/>

      <ConfirmNotification
        isOpene={showConfirm}
        parameters={dialogParameters}
        getResult={handleGetResult}
      />
      {deleting ? (
        <div
          className="position-absolute bg-dark opacity-50 w-100 h-100 d-flex justify-content-center align-items-center">
          {/*<Spinner animation="border" variant="light"/>*/}
        </div>
      ) : null}
    </>
  );
};

ModulesList.propTypes = {
  showEdit: PropTypes.bool,
  showDelete: PropTypes.bool
};
