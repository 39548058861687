import React, {useContext, useEffect, useMemo, useState} from "react";
import {Autocomplete, Button, FormControl, FormLabel, Grid, TextField, Typography} from "@mui/material";
import {useTextDomainContext, useUser} from "app/providers";
import {useCountries, useTimezones, useUpdateProfile} from "hooks";
import {ChangePasswordModal, PictureUploadBox} from "app/components";
import {Languages} from "../../../utils";
import {Box} from "@mui/system";
import {isEqual} from "lodash";
import {enqueueSnackbar} from "notistack";

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig";

/**
 *
 * @param props
 */
export const PersonaInformation = (props) => {
  //region Data
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const allLanguages = Languages(gettext);

  const {
    countries,
    loading: countriesLoading
  } = useCountries();

  const {
    timezones,
    loading: timezoneLoading
  } = useTimezones();

  const {user} = useUser();

  const {updateProfile} = useUpdateProfile(
    () => {
      enqueueSnackbar(gettext("Your profile has been updated"), {
        variant: "success"
      });
    },
    () => {
      enqueueSnackbar(gettext("An unexpected error occurred. It has been logged, and we are working on a resolution."), {
        variant: "error"
      });
    }
  );
  //endregion Data

  //region React Hooks (useState)
  const initialValue = useMemo(() => {
    return {
      userId: user?.id || "",
      timezonesList: [],
      languages: [],
      inputName: user?.name,
      inputEmail: user?.email,
      inputLang: null,
      inputCountry: countries[0] || null,
      inputTimezone: timezones[0] || null
    };
  }, [countries, timezones, user]);

  const [timezonesList, setTimezonesList] = useState(initialValue.timezonesList);
  const [languages, setLanguages] = useState(initialValue.languages);

  const [inputName, setInputName] = useState(initialValue.inputName);
  const [inputEmail, setInputEmail] = useState(initialValue.inputEmail);
  const [inputLang, setInputLang] = useState(initialValue.inputLang);
  const [inputCountry, setInputCountry] = useState(initialValue.inputCountry);
  const [inputTimezone, setInputTimezone] = useState(initialValue.inputTimezone);

  const [emailError, setEmailError] = useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [dataUri, setDataUri] = useState(undefined);
  //endregion React Hooks (useState)

  //region React Hooks (useEffect)
  useEffect(() => {
    if (!isEqual(allLanguages, languages)) {
      setLanguages(allLanguages);
    }
  }, [allLanguages, languages]);

  useEffect(() => {
    if (inputCountry && timezones) {
      const filteredTimezones = timezones.filter(
        timezone => timezone.countryCode === inputCountry.code
      );
      setTimezonesList(filteredTimezones);
      setInputTimezone(filteredTimezones[0]);
    } else {
      setTimezonesList([]);
    }
  }, [inputCountry, timezones]);

  useEffect(() => {
    if (user && languages?.length) {
      setInputLang(
        languages?.find(language => {
          return user.lang === language.lang;
        })
      );
    }
  }, [languages, user]);

  useEffect(() => {
    if (user && countries?.length) {
      setInputCountry(
        countries?.find(country => {
          return user.countryCode === country.code;
        })
      );
    }
  }, [countries, user]);

  useEffect(() => {
    if (user && timezones?.length) {
      setInputTimezone(
        timezones?.find(timezone => {
          return user.timezone === timezone.zoneName;
        })
      );
    }
  }, [timezones, user]);
  //endregion React Hooks (useEffect)

  //region Handlers
  const handleChangePasswordClose = () => {
    setOpenChangePasswordModal(false);
  };

  const handleNameChange = value => {
    setInputName(value);
  };

  const handleChangePasswordClick = () => {
    setOpenChangePasswordModal(true);
  };

  const handlePictureChange = (dataUri) => {
    setDataUri(dataUri);
  };

  const handleSaveProfile = () => {
    updateProfile({
      file:
        dataUri !== ""
          ? dataUri?.replace(/^data:image\/(png|jpg|jpeg);base64,/, "")
          : "",
      name: inputName,
      lang: inputLang?.lang || "en",
      countryCode: inputCountry?.code,
      timezone: inputTimezone?.zoneName
    });

    localStorage.setItem(
      I18N_CONFIG_KEY,
      JSON.stringify({
        selectedLang: inputLang?.lang || "en"
      })
    );
  };
  //endregion Handlers

  return (
    <form
      className="card card-custom card-stretch"
      encType="multipart/form-data"
    >
      <Grid
        container
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12} md={6}>
          <Typography variant="h4">
            {gettext("My Profile")}
          </Typography>
          <Typography variant="body">
            {gettext("Update your Image and other data here.")}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="flex-end">
            <Button variant="outlined" type="reset" color="gray" sx={{
              m: 1
            }}>
              {gettext("Cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveProfile}
              disabled={countriesLoading || timezoneLoading}
              sx={{
                m: 1
              }}
            >
              {gettext("Save Settings")}
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{
            borderBottom: 1,
            borderColor: "gray.200"
          }}></Box>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box maxWidth="md">
          {/* region Your Picture */}
          <Grid container sx={{
            py: 3
          }}>
            <Typography variant="h5">
              {gettext("Your Picture")}
            </Typography>
          </Grid>
          <PictureUploadBox onChange={handlePictureChange}/>
          {/* endregion Your Picture */}

          {/* region Personal Information */}
          <Grid container>
            <Grid container sx={{
              py: 3
            }}>
              <Typography variant="h5">
                {gettext("Personal Information")}
              </Typography>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <FormLabel htmlFor="name">Name</FormLabel>
                  <TextField
                    type="text"
                    id="name"
                    // label={gettext("Name")}
                    placeholder={gettext("Full Name")}
                    size="small"
                    required
                    fullWidth
                    value={inputName}
                    onChange={e => handleNameChange(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <TextField
                    type="email"
                    id="email"
                    // label={gettext("Email")}
                    placeholder={gettext("Enter email")}
                    size="small"
                    required
                    fullWidth
                    autoComplete="off"
                    value={inputEmail}
                    onChange={e => {
                      setInputEmail(e.target.value);
                      setEmailError(false);
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    error={emailError}
                    helperText={emailError && gettext("Email already exists!")}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <FormLabel htmlFor="country">Country</FormLabel>
                  <Autocomplete
                    disablePortal
                    options={countries}
                    getOptionLabel={option => option.name || ""}
                    getOptionKey={option => option.code || ""}
                    value={inputCountry}
                    onChange={(e, newValue) => {
                      setInputCountry(newValue);
                    }}
                    size="small"
                    fullWidth
                    isOptionEqualToValue={(option, value) => {
                      return option?.code?.toLowerCase() === value?.code?.toLowerCase();
                    }
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        id="country"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <FormLabel htmlFor="language">Language</FormLabel>
                  <Autocomplete
                    disablePortal
                    options={languages}
                    getOptionLabel={option => option.name || ""}
                    getOptionKey={option => option.lang || ""}
                    value={inputLang}
                    onChange={(e, newValue) => setInputLang(newValue)}
                    size="small"
                    fullWidth
                    isOptionEqualToValue={(option, value) => {
                      return option?.lang?.toLowerCase() === value?.lang?.toLowerCase();
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        id="language"
                        // label={gettext("Language")}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <FormLabel htmlFor="timezone">Timezone</FormLabel>
                  <Autocomplete
                    disablePortal
                    options={timezonesList}
                    getOptionLabel={option => option.zoneName || ""}
                    getOptionKey={option => option.zoneName || ""}
                    value={inputTimezone}
                    onChange={(e, newValue) => setInputTimezone(newValue)}
                    size="small"
                    fullWidth
                    isOptionEqualToValue={(option, value) => option?.value?.toLowerCase() === value?.value?.toLowerCase()}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        id="timezone"
                        // label={gettext("Timezone")}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {/* endregion Personal Information */}

          {/* region Change Password */}
          <Grid container sx={{
            py: 3,
          }}>
            <Grid item xs={12}>
              <Typography variant="h5">
                {gettext("Change Password")}
              </Typography>
              <Typography variant="body">
                {gettext("Setup a unique password to protect your account.")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleChangePasswordClick}
              >
                {gettext("Change Password")}
              </Button>
            </Grid>
          </Grid>
          {/* endregion Change */}
        </Box>
      </Grid>
      <ChangePasswordModal open={openChangePasswordModal} handleClose={handleChangePasswordClose}/>
    </form>
  );
};

