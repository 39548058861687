import {useQuery} from "@apollo/client";
import {CONSUMPTION_ENERGY_DATA} from "./commands";
import {enqueueSnackbar} from "notistack";
import "../../app/types/ConsumptionEnergyData";

/**
 *
 * @param variables
 * @param onCompleted
 * @param onError
 */
export function useConsumptionEnergyData(
  variables,
  onCompleted = () => {
  },
  onError = () => {
  }
) {
  const {
    /** @type {ConsumptionEnergyDataResponse || null} */
    data,
    loading
  } = useQuery(CONSUMPTION_ENERGY_DATA, {
    variables,
    onCompleted: (res) => {
      onCompleted(data);
    },
    onError: (err) => {
      onError();

      enqueueSnackbar(
        err.message,
        {
          variant: "error"
        }
      );
    },
    // fetchPolicy: "cache-and-network",
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
    errorPolicy: "all"
  });

  return {
    data,
    loading
  };
}
