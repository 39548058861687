import {useUser} from "../../providers";
import React, {useState} from "react";
// import {useTheme} from "@mui/material";
// import {Segments} from "../../../utils";
// import {useMetersData} from "../../../hooks";
import {FilterBar1} from "../../components";
import {EnergyOverallStatement} from "./EnergyOverallStatement";
import {EnergyMonthlyStatement} from "./EnergyMonthlyStatement";
import {useConsumptionEnergyData} from "../../../hooks";

// const metersData = [];
// const loading = false;
// metersData = AddIdToArrayOfObjects(metersData);

export const EnergyStatement = (props) => {
  // console.error("Test");
  // console.log(AddIdToArrayOfObjects(metersData));
  // const {TextDomainContext} = useTextDomainContext();
  // const {gettext} = useContext(TextDomainContext);
  const {user} = useUser();
  // const segments = Segments();
  let {currentTeam: customer} = user || {
  };
  // const theme = useTheme();
  if (!customer) {
    customer = "all";
  }

  //region React Hooks (useState)
  const [energyType, setEnergyType] = useState("electricity");
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState('jan');
  const [selectedEnergyStatement, setSelectedEnergyStatement] = useState("overall-statement"); // overall-statement, monthly-statement

  const filters = {
    meterType: energyType,
    customer: customer,
    year: year,
  };

  const {
    data,
    loading
  } = useConsumptionEnergyData(filters);

  // const [building, setBuilding] = useState("all");
  // const [meter, setMeter] = useState("all");
  // const [fromDate, setFromDate] = useState();
  // const [toDate, setToDate] = useState();
  // const [segment, setSegment] = useState("year");
  // const [shortSegment, setShortSegment] = useState("m");
  // const [chartData, setChartData] = useState([]);
  // const [fileNameForExport, setFileNameForExport] = useState("carbon-emissions-export");
  //endregion  React Hooks (useState)

  //region Data
  // const filters = {
  //   customer,
  //   address: building,
  //   meteringPointId: meter,
  //   meterType: energyType,
  //   fromDate: FormatDateForAPI(fromDate),
  //   toDate: FormatDateForAPI(toDate),
  //   resolution: shortSegment,
  //   fileType: "csv"
  // };
  // const {
  //   data: metersData,
  //   loading
  // } = useMetersData(filters);
  //endregion Data

  //region React Hooks (useEffect)
  // useEffect(() => {
  //   setFileNameForExport(UrlFriendlyString("carbon-emissions-" + building + "-" + meter + "-" + energyType + "-" + segment + "-" + fromDate + "-" + toDate));
  // }, [building, energyType, fromDate, meter, segment, toDate]);
  //
  // useEffect(() => {
  //   if (metersData.length) {
  //     const chartDateTemp = MeterApiDataToBarChartData(metersData, shortSegment);
  //     if (!isEqual(chartDateTemp, chartData)) {
  //       setChartData(MeterApiDataToBarChartData(metersData, shortSegment));
  //     }
  //   }
  // }, [chartData, shortSegment]);
  //endregion React Hooks (useEffect)

  //region For Table
  // const columns = [
  //   // {
  //   //   field: "ts",
  //   //   headerName: gettext("Date"),
  //   //   flex: 1,
  //   //   type: "date",
  //   //   valueGetter: (value) => {
  //   //     let returnValue = "";
  //   //     if (value) {
  //   //       if (value === "Summary") {
  //   //         returnValue = value;
  //   //       } else {
  //   //         returnValue = new Date(value);
  //   //       }
  //   //     }
  //   //     return returnValue;
  //   //   },
  //   //   valueFormatter: (value) => {
  //   //     let returnValue = "";
  //   //     if (value) {
  //   //       if (value === "Summary") {
  //   //         returnValue = value;
  //   //       } else {
  //   //         returnValue = moment(value).format(segments[segment].dateFormat);
  //   //       }
  //   //     }
  //   //     return returnValue;
  //   //   }
  //   // },
  //   {
  //     field: "installation",
  //     headerName: gettext("Installation"),
  //     // flex: 1,
  //     width: 200,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "measure",
  //     headerName: gettext("measure"),
  //     // flex: 1,
  //     width: 100,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "company",
  //     headerName: gettext("company"),
  //     // flex: 1,
  //     width: 200,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "network_company",
  //     headerName: gettext("network_company"),
  //     // flex: 1,
  //     width: 200,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "Installation_address",
  //     headerName: gettext("Installation_address"),
  //     // flex: 1,
  //     width: 300,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "house",
  //     headerName: gettext("house"),
  //     // flex: 1,
  //     width: 100,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "post_code",
  //     headerName: gettext("post_code"),
  //     // flex: 1,
  //     width: 100,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "by",
  //     headerName: gettext("by"),
  //     // flex: 1,
  //     width: 100,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "comment",
  //     headerName: gettext("comment"),
  //     // flex: 1,
  //     width: 100,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "net_tarif",
  //     headerName: gettext("net_tarif"),
  //     // flex: 1,
  //     width: 100,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "cvr",
  //     headerName: gettext("cvr"),
  //     // flex: 1,
  //     width: 100,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "annual_consumption",
  //     headerName: gettext("annual_consumption"),
  //     // flex: 1,
  //     width: 100,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "sum",
  //     headerName: gettext("sum"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "percent",
  //     headerName: gettext("percent"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "jan",
  //     headerName: gettext("Jan 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "feb",
  //     headerName: gettext("Feb 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "mar",
  //     headerName: gettext("Mar 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "apr",
  //     headerName: gettext("Apr 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "may",
  //     headerName: gettext("May 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "jun",
  //     headerName: gettext("Jun 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "jul",
  //     headerName: gettext("Jul 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "aug",
  //     headerName: gettext("Aug 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "sep",
  //     headerName: gettext("Sep 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "oct",
  //     headerName: gettext("Oct 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "nov",
  //     headerName: gettext("Nov 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "dec",
  //     headerName: gettext("Dec 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   // {
  //   //   field: "consumptionConversionRate",
  //   //   headerName: gettext("Conversion Rate (g CO₂/kWh)"),
  //   //   flex: 1,
  //   //   type: "number",
  //   //   valueFormatter: (value) => value && value.toFixed(2)
  //   // },
  //   // {
  //   //   field: "consumptionCo2",
  //   //   headerName: gettext("CO₂ (kilogram)"),
  //   //   flex: 1,
  //   //   type: "number",
  //   //   valueFormatter: (value) => value && value.toFixed(0)
  //   // }
  //   // {
  //   //   field: "consumptionCost",
  //   //   headerName: gettext("Cost (DKK)"),
  //   //   flex: 1,
  //   //   type: "number"
  //   // }
  // ];

  // const initialState = {
  //   pagination: {
  //     paginationModel: {
  //       page: 0,
  //       pageSize: 50
  //     }
  //   }
  //   // columns: {
  //   //   columnVisibilityModel: {
  //   //     id: false,
  //   //   }
  //   // }
  // };
  //endregion For Table

  const handleGetSelectedValues = (selectedValues) => {
    if (selectedValues?.energyType?.value) {
      setEnergyType(selectedValues.energyType.value);
    }

    if (selectedValues?.selectedYearDate) {
      setYear(selectedValues.selectedYearDate.getFullYear());
    }

    if (selectedValues?.selectedMonth?.value) {
      setMonth(selectedValues.selectedMonth.value);
    }

    //
    // if (selectedValues?.building?.value) {
    //   setBuilding(selectedValues.building.value);
    // }
    //
    // if (selectedValues?.meter?.value) {
    //   setMeter(selectedValues.meter.value);
    // }

    // if (selectedValues?.selectedYearDate) {
    //   setSelectedYearDate(selectedValues.selectedYearDate);
    // }
  };

  const handleChangeEnergyStatement = (obj) => {
    setSelectedEnergyStatement(obj.value);
  };

  return (
    <>
      <FilterBar1
        getSelectedValues={handleGetSelectedValues}
        showEnergyType
        showYear
        showMonth={selectedEnergyStatement === "monthly-statement"}
      />
      <br/>

      {selectedEnergyStatement === "overall-statement" && (
        <EnergyOverallStatement
          onChangeEnergyStatement={handleChangeEnergyStatement}
          meterType={energyType}
          year={year}
          data={data}
          loading={loading}
        />
      )}

      {selectedEnergyStatement === "monthly-statement" && (
        <EnergyMonthlyStatement
          onChangeEnergyStatement={handleChangeEnergyStatement}
          meterType={energyType}
          year={year}
          month={month}
          data={data}
          loading={loading}
        />
      )}

      {/*<BarChartBox*/}
      {/*  loading={loading}*/}
      {/*  title={gettext("Consumption measured in kWh")}*/}
      {/*  chartId={"consumption"}*/}
      {/*  data={{*/}
      {/*    dataset: chartData,*/}
      {/*    series: [{*/}
      {/*      dataKey: "consumptionV",*/}
      {/*      label: gettext("Consumption"),*/}
      {/*      color: theme.palette.chart.electricity,*/}
      {/*      unit: gettext("kWh"),*/}
      {/*    }]*/}
      {/*  }}*/}
      {/*/>*/}
      {/*<br/>*/}

      {/*<TableView1*/}
      {/*  title={gettext("Electricity consumption")}*/}
      {/*  rows={metersData}*/}
      {/*  // rows={[]}*/}
      {/*  columns={columns}*/}
      {/*  loading={loading}*/}
      {/*  checkboxSelection={false}*/}
      {/*  initialState={initialState}*/}
      {/*  showGridToolbarDeleteButton={false}*/}
      {/*  showGridToolbarDensitySelector={false}*/}
      {/*  showGridToolbarFilterButton={false}*/}
      {/*  showGridToolbarQuickFilter={true}*/}
      {/*  showGridToolbarColumnsButton={false}*/}
      {/*  fileNameForExport={fileNameForExport}*/}
      {/*/>*/}
    </>
  );
};