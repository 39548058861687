import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import { isLocationActive } from "app/common/Methods";
import { ListItemIcon, ListItemText } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { usePagePrivileges } from "../../../../../../../hooks/privileges";
import PropTypes from "prop-types";

/**
 *
 * @param props
 * @return {Element}
 * @constructor
 */
const Level2MenuHead = (props) => {
  const {
    link,
    label,
    icon,
    children,
    codes
  } = props;
  const location = useLocation();
  const allow = usePagePrivileges(codes);

  const [activeColor, setActiveColor] = useState("");
  const [open, setOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const active = isLocationActive(location, link);

    setIsActive(active);

    setOpen(active);
  }, [location, link]);

  useEffect(() => {
    setActiveColor(isActive ? "primary.50" : "primary.100");
  }, [isActive]);

  const handleClick = () => {
    setOpen(prevOpen => !prevOpen);
  };

  return (
    <>
      {allow && (
        <>
          <ListItem disablePadding sx={{
            color: activeColor
          }}>
            <ListItemButton
              onClick={handleClick}
              disableGutters
              sx={{
                p: 1,
                borderRadius: "8px",
                backgroundColor: isActive
                  ? alpha("#abb8c3", 0.28)
                  : "transparent"
              }}
            >
              <ListItemIcon sx={{
                minWidth: "25px",
                color: activeColor
              }}>
                {icon}
              </ListItemIcon>
              <ListItemText primary={label} />
              {/* <Typography variant="h6" sx={{ */}
              {/*   color: activeColor */}
              {/* }}> */}
              {/*   {label} */}
              {/* </Typography> */}
            </ListItemButton>
          </ListItem>

          <Collapse in={open} timeout="auto">
            <List component="div">{children}</List>
          </Collapse>
        </>
      )}
    </>
  );
};

Level2MenuHead.propTypes = {
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  codes: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node
};

export default Level2MenuHead;
