import React, {useContext, useEffect, useState} from "react";
import {FabekeDropdown} from "./FabekeDropdown";
import {useTextDomainContext, useUser} from "../../providers";
import PropTypes from "prop-types";
import {useBuildingFilter} from "../../../hooks";
import {SimpleDropdown} from "./SimpleDropdown";

/**
 * Meter dropdown component
 * @param {object} props props
 * @returns {React.JSX.Element} React Component
 * @class
 */
export const MeterDropdownCommon = (props) => {
  const {user} = useUser();
  const {currentTeam: currentTeamDefault} = user || {
  };
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);

  const propsDefault = {
    defaultValue: "all",
    buildingDefaultValue: "all",
    defaultLabel: gettext("All"),
    showAll: true,
    componentLabel: gettext("Meter"),
    currentTeam: currentTeamDefault,
    selectedBuilding: "all",
    meters: [{
      label: gettext("All"),
      value: "all"
    }]
  };

  //region UseState [React Hooks]
  const [buildingValue, setBuildingValue] = useState(props.buildingDefaultValue ? props.buildingDefaultValue : propsDefault.buildingDefaultValue);
  const [meterValue, setMeterValue] = useState(props.defaultValue ? props.defaultValue : propsDefault.defaultValue);
  const [showAll, setShowAll] = useState(props.showAll ? props.showAll : propsDefault.showAll);
  const [componentLabel, setComponentLabel] = useState(props.componentLabel ? props.componentLabel : propsDefault.componentLabel);
  const [currentTeam, setCurrentTeam] = useState(props.currentTeam ? props.currentTeam : propsDefault.currentTeam);
  const [meters, setMeters] = useState(propsDefault.meters);
  const [selectedBuilding, setSelectedBuilding] = useState(props.building ? props.building : propsDefault.selectedBuilding);
  //endregion UseState

  const {buildings: buildingData} = useBuildingFilter(currentTeam);

  //region UseEffect [React Hooks]
  useEffect(() => {
    if (typeof props.defaultValue === "undefined") {
      setMeterValue(propsDefault.defaultValue);
    } else {
      setMeterValue(props.defaultValue);
    }
  }, [props.defaultValue, propsDefault.defaultValue]);

  useEffect(() => {
    if (typeof props.building === "undefined") {
      setSelectedBuilding(propsDefault.selectedBuilding);
    } else {
      setSelectedBuilding(props.building);
    }
  }, [props.building, props.showAll, propsDefault.selectedBuilding]);

  useEffect(() => {
    if (typeof props.showAll === "undefined") {
      setShowAll(propsDefault.showAll);
    } else {
      setShowAll(props.showAll);
    }
  }, [props.showAll, propsDefault.showAll]);

  useEffect(() => {
    if (typeof props.componentLabel === "undefined") {
      setComponentLabel(propsDefault.componentLabel);
    } else {
      setComponentLabel(props.componentLabel);
    }
  }, [props.componentLabel, propsDefault.componentLabel]);

  useEffect(() => {
    if (typeof props.currentTeam === "undefined") {
      setCurrentTeam(propsDefault.currentTeam);
    } else {
      setCurrentTeam(props.currentTeam);
    }
  }, [props.currentTeam, propsDefault.currentTeam]);

  useEffect(() => {
    if (buildingData && buildingData.length > 0) {
      const buildingsArray = [];
      const metersArray = [];
      let meters = [];
      if (showAll) {
        metersArray.push({
          label: gettext("All"),
          value: "all"
        });
      }

      const firstBuildingValue = buildingData.find(o => o.value === buildingValue);
      if (!firstBuildingValue) {
        // Building data changed
        if (showAll && buildingValue === propsDefault.buildingDefaultValue) {
          //
        } else if (showAll) {
          setBuildingValue(propsDefault.buildingDefaultValue);
        } else if (buildingData[0].value) {
          setBuildingValue(buildingData[0].value);
        }
      }

      buildingData.map(building => {
        buildingsArray.push(building);
        return true;
      });

      if (selectedBuilding === "all") {
        meters = buildingsArray.map(building => {
          if (building[props.energyType]) {
            return building[props.energyType];
          } else {
            return null;
          }
        });
      } else if (selectedBuilding) {
        meters = buildingsArray.map(building => {
          if (building.value === selectedBuilding && building[props.energyType]) {
            return building[props.energyType];
          } else {
            return null;
          }
        });
      }

      meters.forEach(obj => {
        if (obj) {
          obj.forEach(obj2 => {
            const meterObj = {
              label: obj2.label,
              value: obj2.meterNo
            };
            metersArray.push(meterObj);
          });
        }
      });

      const firstMeterValue = metersArray.find(o => o.value === meterValue);
      if (!firstMeterValue) {
        // Building data changed
        if (showAll && meterValue === propsDefault.defaultValue) {
          //
        } else if (showAll) {
          setMeterValue(propsDefault.defaultValue);
        } else if (metersArray[0].value) {
          setMeterValue(metersArray[0].value);
        }
      }

      setMeters(metersArray);
      // setBuildings(buildingsArray);
    } else {
      if (showAll) {
        setMeters([{
          label: gettext("All"),
          value: "all"
        }]);
        setMeterValue(propsDefault.defaultValue);
      }
    }
  }, [buildingData, buildingValue, gettext, meterValue, props.energyType, propsDefault.buildingDefaultValue, propsDefault.defaultLabel, propsDefault.defaultValue, selectedBuilding, showAll]);
  //endregion useEffect

  //region Handlers
  const handleChange = event => {
    props.onChange(event);
    // if (typeof props.onChange === "function") {
    //   if (props.visualStyle === "simple") {
    //     props.onChange(event.target);
    //   } else if (props.visualStyle === "old") {
    //     props.onChange(event);
    //   } else {
    //     props.onChange(event);
    //   }
    // }
  };
  //endregion Handlers

  return (
    <>
      {props.visualStyle === "simple" && (
        <SimpleDropdown
          options={meters}
          componentLabel={componentLabel}
          defaultValue={meterValue}
          onChange={handleChange}
        />
      )}

      {props.visualStyle === "old" && (
        <FabekeDropdown
          options={meters}
          componentLabel={componentLabel}
          defaultValue={meterValue}
          onChange={handleChange}
        />
      )}
    </>
  );

};

MeterDropdownCommon.propTypes = {
  componentLabel: PropTypes.string,
  building: PropTypes.string,
  energyType: PropTypes.string,
  defaultValue: PropTypes.string,
  currentTeam: PropTypes.string,
  showAll: PropTypes.bool,
  onChange: PropTypes.func,
  visualStyle: PropTypes.oneOf(["simple", "old"]).isRequired
};
