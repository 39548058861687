import { useMutation } from "@apollo/client";
// import { useAuth } from "app/providers";
import { useCallback, useState } from "react";
// import ReactGA from "react-ga4";
import { FORGOT_PASSWORD } from "./commands";

/**
 *
 * @param onCompleted
 * @param onError
 */
export function useForgotPassword(
  onCompleted = () => null,
  onError = () => null) {
  // const { setToken } = useAuth();
  const [resetSuccess, setResetSuccess] = useState(null);
  const [useNotFound, setUseNotFound] = useState(null);

  const [comForgetPassword, { loading }] = useMutation(FORGOT_PASSWORD, {
    onCompleted: ({ comForgetPassword }) => {
      if (comForgetPassword.success === false) {
        setResetSuccess(false);
        setUseNotFound(comForgetPassword.userNotFound);
        throw Error(comForgetPassword.message);
      }
      setUseNotFound(false);
      setResetSuccess(true);
      localStorage.clear();
    },
    onError: error => {
      setResetSuccess(false);
      onError(error);
    }
  });

  const submit = useCallback(
    async credentials => {
      try {
        await comForgetPassword({
          variables: credentials
        });

        // ReactGA.event({
        //   category: "Authentication",
        //   action: "PasswordReset",
        //   label: credentials?.email.replace("@", "[at]")
        // });
      } catch (error) { /* empty */
      }
    },
    [comForgetPassword]
  );

  return {
    submit,
    isSubmitting: loading,
    resetSuccess,
    useNotFound
  };
}
