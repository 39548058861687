import {Button, Typography} from "@mui/material";
import {Box} from "@mui/system";
import {MultipleFilesUploadModal, TableBox} from "../../components";
import React, {useState} from "react";

export const DeveloperModals = (props) => {
  const [openMultipleFilesUploadModal, setOpenMultipleFilesUploadModal] = useState(false);

  const multipleFilesUploadClick = (event) => {
    setOpenMultipleFilesUploadModal(true);
  };

  const handleMultipleFilesUploadClose = (event) => {
    setOpenMultipleFilesUploadModal(false);
  };

  return (
    <>
      <Typography variant="h2">
        Modals under development
      </Typography>
      <TableBox>
        <Box>
          <Button variant="contained" color="primary" onClick={multipleFilesUploadClick}>Multiple files upload</Button>
        </Box>
      </TableBox>
      <MultipleFilesUploadModal open={openMultipleFilesUploadModal} handleClose={handleMultipleFilesUploadClose}/>
    </>
  );
};