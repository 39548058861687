export const ShortSegmentDateFormat = (shortSegment) => {
  let dateFormat = "MMM YYYY";
  switch (shortSegment) {
    case "h":
      dateFormat = "DD MMM YYYY, HH:mm";
      break;
    case "d":
      dateFormat = "DD MMM YYYY";
      break;
    case "m":
    default:
      dateFormat = "MMM YYYY";
      break;
    // default:
    //   dateFormat = "YYYY";
    //   break;
  }

  return dateFormat;
};