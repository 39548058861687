import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { ADD_USER_BY_ADMIN } from "../GraphQL/Mutations";
import { GET_ALL_COUNTRIES, GET_ALL_ROLES, GET_ALL_TIME_ZONES } from "../GraphQL/Queries";

////////// Get TimeZone List
export const useTimeZone = () => {
  const { data, loading } = useQuery(GET_ALL_TIME_ZONES, {
    variables: {
    }
  });
  const [timeZoneData, setTimeZoneData] = useState([]);
  // const { data: eee } = useGetTimeZone();
  useEffect(() => {
    if (!loading) {
      setTimeZoneData(data.mongoTimezones);
    }
  }, [data, loading]);
  return {
    data: timeZoneData, loading
  };
};

////////// Get Countries List
export const useCountries = () => {
  const { data, loading } = useQuery(GET_ALL_COUNTRIES, {
    variables: {
    }
  });
  const [countriesData, setCountriesData] = useState([]);
  // const { data: wwww } = useGetCountries();
  useEffect(() => {
    if (!loading) {
      setCountriesData(data.mongoCountries);
    }
  }, [data, loading]);
  return {
    data: countriesData, loading
  };
};

////////// Get All Roles List
export const useAllRoles = () => {
  const { data, loading } = useQuery(GET_ALL_ROLES, {
    variables: {
    }
  });
  const [AllRoles, setAllRoles] = useState([]);
  // const { data: WWW } = useGetRoles();
  useEffect(() => {
    if (!loading) {
      setAllRoles(data.roles);
    }
  }, [data, loading]);
  return {
    data: AllRoles, updating: loading
  };
};

export const useAddUserByAdmin = (
  onCompleted = () => {
  },
  onError = () => {
  }
) => {
  const [success, setSuccess] = useState(false);
  const [resetForm, setResetForm] = useState(false);

  const [addUserByAdmin, { loading, data }] = useMutation(ADD_USER_BY_ADMIN, {
    onCompleted: response => {
      if (response.comCreateUserAdmin?.success) {
        setSuccess(true);
        setResetForm(true);
        setTimeout(() => {
          setSuccess(false);
          setResetForm(false);
        }, 5000);
        onCompleted();
      } else {
        setSuccess(false);
      }
    },
    onError: ({ message }) => {
      setSuccess(false);
      onError(message);
    }
  });
  return {
    addUserByAdmin, loading, success, data, resetForm
  };
};
