import {useMutation} from "@apollo/client";
import {useCallback} from "react";
import {ADD_CUSTOMER_USER} from "./commands";

export function useAddUserCompany(onCompleted = () => {
}, onError = () => {
}) {
  const [comAddCustomerUser, {loading}] = useMutation(ADD_CUSTOMER_USER, {
    onCompleted: onCompleted,
    onError: onError,
    refetchQueries: ["getUserCustomers"]
  });

  const addCustomer = useCallback(
    variables => {
      comAddCustomerUser({
        variables
      });
    },
    [comAddCustomerUser]
  );

  return {
    addCustomer,
    loading
  };
}
