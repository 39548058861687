import {useTextDomainContext} from "../../providers";
import React, {useContext, useEffect, useState} from "react";
import {ModalBox} from "./ModalBox";
import {Box} from "@mui/system";
import PropTypes from "prop-types";
import {Container, Grid} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import {PartnerImagePath} from "../../../hooks";
import {GetPartnerKey} from "../../../utils";
import {UploadModalCarouselItems} from "./assets";
import {UploadModalCarousel} from "./UploadModalCarousel";
import {Dropzone2} from "../FileManager";
import {BlackButton, OutlinedButton} from "../Buttons";
import {isEqual} from "lodash";

export const MultipleFilesUploadModal = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  // const partnerName = GetPartnerName();
  const partnerKey = GetPartnerKey();
  const items = UploadModalCarouselItems();

  const [loginImage1, setLoginImage1] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progress, setProgress] = useState([]);
  const [startDummy, setStartDummy] = useState(false);
  const [loop, setLoop] = useState(false);
  
  useEffect(() => {
    const progressArray = progress;
    selectedFiles.map((file, index) => {
      if (!progressArray[index]) {
        progressArray[index] = 0;
      }
      return null;
    });

    if (!isEqual(progress, progressArray)) {
      setProgress(progressArray);
    }
  }, [progress, selectedFiles]);

  useEffect(() => {

    if (startDummy) {

      let thisChanged = false;
      const selectedProgressId = Math.floor(Math.random() * progress.length);
      if (progress[selectedProgressId] > -1 && progress[selectedProgressId] < 101) {
        thisChanged = true;
        const newProgress = progress;
        newProgress[selectedProgressId]++;
        if (newProgress[selectedProgressId] > 100) {
          newProgress[selectedProgressId] = 100;
        }
        setProgress(newProgress);
      }

      let allDummyDone = true;
      progress.map((obj) => {
        if (obj < 100) {
          allDummyDone = false;
        }
        return null;
      });

      if (allDummyDone) {
        setStartDummy(false);
      } else if (!thisChanged) {
        setLoop(!loop);
      } else {
        setLoop(!loop);
      }
    }
  }, [loop, progress, startDummy]);

  PartnerImagePath(partnerKey, "login/login-1.png").then(imageUrl => {
    setLoginImage1(imageUrl);
  });

  const uploadClickHandler = (event) => {
    setStartDummy(true);
  };

  const handleGetSelected = (files) => {

    setSelectedFiles(files);
  };

  return (
    <ModalBox
      open={props.open}
      handleClose={props.handleClose}
      crossColor="common.white"
      sx={{
        maxWidth: 1000,
        maxHeight: 600,
        width: "80vw",
        height: "80vh",
      }}>
      <Box sx={{
        height: "100%",
      }}>
        <Grid container sx={{
          height: "100%",
        }}>
          <Grid item xs={5} sx={{
            height: "100%",
          }}>
            <Box height="100%"
              sx={{
                height: "100%",
              }}
            >
              <Container sx={{
                textAlign: "center",
                height: "calc(100% - 70px)",
                p: 2,
              }}>
                <Dropzone2
                  getSelected={handleGetSelected}
                  progress={progress}
                />
              </Container>

              <Container sx={{
                textAlign: "center",
                height: "70px",
                p: 2,
              }}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <OutlinedButton
                      fullWidth
                      onClick={props.handleClose}
                    >
                      {gettext("Cancel")}
                    </OutlinedButton>
                  </Grid>
                  <Grid item xs={6}>
                    <BlackButton
                      fullWidth
                      onClick={uploadClickHandler}
                    >
                      {gettext("Upload")}
                    </BlackButton>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Grid>

          <Grid item xs={7} display={{
            xs: "none",
            md: "block"
          }}
          sx={{
            backgroundImage: 'url("' + loginImage1 + '")',
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
          }}
          >
            <Grid item md={12} sx={{
              backgroundColor: "secondary.700",
              opacity: "0.5",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              pointerEvents: "none",
            }}>
            </Grid>
            <Carousel indicatorContainerProps={{
              style: {
                marginLeft: "25px",
                textAlign: "left",
              }

            }} sx={{
              opacity: "1",
              height: "100%",
            }}>
              {
                items.map((item, i) => <UploadModalCarousel key={i} item={item}/>)
              }
            </Carousel>

          </Grid>
        </Grid>
      </Box>
    </ModalBox>
  );
};

MultipleFilesUploadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};