import React, {useContext, useEffect} from "react";
import {useTextDomainContext} from "app/providers";
import {useFormik} from "formik";
import {useLocation} from "react-router";
import {Link} from "react-router-dom";
import * as Yup from "yup";
import ReactGA from "react-ga4";
import {Alert,
  Button,
  Collapse,
  Container,
  FormHelperText,
  Grid,
  OutlinedInput,
  Stack,
  Typography} from "@mui/material";
import {Box} from "@mui/system";
import {useForgotPassword} from "../../../hooks";

// import { injectIntl } from "react-intl";

const initialValues = {
  email: ""
};

/**
 *
 * @param props
 */
export const ForgotPasswordForm = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname
    });
  }, [props, location.pathname]);

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(gettext("Wrong email format"))
      .min(3, gettext("Minimum 3 symbols"))
      .max(50, gettext("Maximum 50 symbols"))
      .required(gettext("Required field"))
  });

  const {
    submit,
    isSubmitting,
    resetSuccess,
    useNotFound
  } = useForgotPassword(
    () => {
      // setResetSuccess(true);
    },
    error => {
      if (error) {
        formik.setErrors({
          submit: error.toString()
        });
      } else {
        formik.setErrors({
          submit: gettext("Unknown Error Occurred !")
        });
      }
    }
  );

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, {
      setStatus,
      setSubmitting
    }) => {
      // submit the form here
      submit({
        email: values.email
      }).then();
    }
  });

  return (
    <Container maxWidth="xs">
      <Grid container spacing={1} marginY={18}>
        <Grid item xs={12}>
          {!resetSuccess ? (
            <Typography variant="h2" color={"gray.900"}>
              {gettext("Forgot Password")}
            </Typography>
          ) : (
            <>
              <Typography fontSize={36} fontWeight={700} color="#015731">
                {gettext("Check Your Email")}
              </Typography>

              <Typography
                fontSize={18}
                fontWeight={500}
                color="#52525B"
                sx={{
                  pt: 1
                }}
              >
                {gettext(
                  "A link has been sent to your email address to reset password"
                )}
              </Typography>
            </>
          )}
        </Grid>

        <Grid item xs={12}>
          <Stack
            direction="row"
            alignItems="baseline"
            sx={{
              mb: {
                xs: -0.5,
                sm: 0.5
              }
            }}
          ></Stack>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{
            marginY: 1
          }}>
            {!resetSuccess ? (
              <>
                <Collapse
                  in={Boolean(formik.errors.submit) || resetSuccess}
                >
                  <Alert
                    variant="outlined"
                    severity={resetSuccess ? "success" : "error"}
                  >
                    {resetSuccess
                      ? gettext("Rest Password Lint Sent to your Email !")
                      : gettext(
                        useNotFound
                          ? `${formik.errors.submit} or `
                          : formik.errors.submit
                      ) + " "}
                    {useNotFound ? (
                      <Typography
                        component={Link}
                        to="/auth/registration"
                        fontSize={12}
                        letterSpacing={1}
                        fontWeight={400}
                        sx={{
                          textDecoration: "none"
                        }}
                        color="#27272A"
                      >
                        {gettext("sign up.")}
                      </Typography>
                    ) : null}
                  </Alert>
                </Collapse>
                <form noValidate onSubmit={formik.handleSubmit}>
                  <Grid container spacing={2} sx={{
                    mt: 1
                  }}>
                    <Grid item xs={12}>
                      <Stack>
                        <OutlinedInput
                          id="email-login"
                          type="email"
                          name="email"
                          fullWidth
                          size="large"
                          placeholder={gettext("Email address")}
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={Boolean(
                            formik.touched.email && formik.errors.email
                          )}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-email-login"
                          >
                            {formik.errors.email}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        disableElevation
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        {gettext("Send Link to Email")}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </>
            ) : (
              <Grid container spacing={2} sx={{
                mt: 1
              }}>
                <Grid item xs={12}>
                  <a href="/auth/login">
                    <Button
                      disableElevation
                      fullWidth
                      size="large"
                      variant="contained"
                      color="primary"
                    >
                      {gettext("Sign In")}
                    </Button>
                  </a>
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

// export default injectIntl(ForgotPassword);
