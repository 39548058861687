/**
 * Entry application component used to compose providers and render Routes.
 */

import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider as NotificationProvider } from "notistack";
import {AuthProvider,
  ConfirmationProvider,
  GraphQLProvider,
  I18nProvider,
  LocaleProvider,
  MaterialThemeProvider,
  UserProvider} from "./providers";
import ErrorBoundary from "./components/ErrorBoundary";
// import {LayoutSplashScreen,
//   MetronicLayoutProvider,
//   MetronicSplashScreenProvider,
// MetronicSubheaderProvider} from "_metronic/layout";
import AppRoutes from "./Routes";
import Hotjar from "@hotjar/browser";
import CssBaseline from "@mui/material/CssBaseline";
import VersionControl from "./components/VersionControl";

const { PUBLIC_URL, REACT_APP_NODE_ENV } = process.env;
let siteId = 3692176;
const hotjarVersion = 6;

switch (REACT_APP_NODE_ENV) {
  case "production":
    siteId = 3692176;
    break;
  case "staging":
    siteId = 3692279;
    break;
  default:
    siteId = 3692282;
    break;
}

/**
 *
 */
export default function App() {
  useEffect(() => {
    Hotjar.init(siteId, hotjarVersion);
  }, []);

  const notificationPosition = {
    vertical: "bottom",
    horizontal: "left"
  };
  return (
    <React.StrictMode>
      <CssBaseline />
      <BrowserRouter basename={PUBLIC_URL}>
        <NotificationProvider
          anchorOrigin={notificationPosition}
          autoHideDuration={6000}
          preventDuplicate={true}
        >
          <AuthProvider>
            <UserProvider>
              <LocaleProvider>
                <GraphQLProvider>
                  <ErrorBoundary>
                    {/* <MetronicLayoutProvider> */}
                    {/*   <MetronicSubheaderProvider> */}
                    {/*     <MetronicSplashScreenProvider> */}
                    {/* <SplashScreenProvider> */}
                    {/* <React.Suspense fallback={<LayoutSplashScreen />}> */}
                    {/* <React.Suspense fallback={<SplashScreen />}> */}
                    <VersionControl>
                      {/* <CustomerPermissionProvider> */}
                      <MaterialThemeProvider>
                        <I18nProvider>
                          <ConfirmationProvider>
                            {/* <SplashScreen /> */}
                            <AppRoutes />
                          </ConfirmationProvider>
                        </I18nProvider>
                      </MaterialThemeProvider>
                      {/* </CustomerPermissionProvider> */}
                    </VersionControl>
                    {/* </React.Suspense> */}
                    {/* </SplashScreenProvider> */}
                    {/* </MetronicSplashScreenProvider> */}
                    {/* </MetronicSubheaderProvider> */}
                    {/* </MetronicLayoutProvider> */}
                  </ErrorBoundary>
                </GraphQLProvider>
              </LocaleProvider>
            </UserProvider>
          </AuthProvider>
        </NotificationProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}
