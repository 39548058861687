import {Box} from "@mui/material";
import {useTextDomainContext, useUser} from "app/providers";
import React, {useEffect, useState} from "react";
import {CustomerDropdownAutoSave} from "../../../../../../components";
import {useCompanies, useGetUserCompanies} from "../../../../../../../hooks";
import {HasPermission} from "../../../../../../../utils";

const codesAllCompanies = ["all-companies-full", "all-Companies-readonly", "everything-full", "everything-readonly", "developer"];

/**
 *
 */
export const CustomerSelector = () => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = React.useContext(TextDomainContext);
  const {user} = useUser();
  const {currentTeam} = user || {
  };
  const {companiesList} = useGetUserCompanies();
  const {customers} = useCompanies();
  const allowAllCompanies = HasPermission(codesAllCompanies);

  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    if (allowAllCompanies) {
      setCompanies(customers);
    } else {
      setCompanies(companiesList);
    }

  }, [allowAllCompanies, companiesList, customers]);

  return (
    <Box sx={{
      ml: 2
    }}>
      {companies?.length > 1 &&
        <CustomerDropdownAutoSave/>
      }
      {(companies?.length < 2 || !companies) && <>
        {!currentTeam || currentTeam === "null"
          ? gettext("Select Company")
          : currentTeam}
      </>
      }
    </Box>
  );
};
