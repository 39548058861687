import React from "react";
import detectBrowserLanguage from "detect-browser-language";
import client from "services/apiClient";

const {
  REACT_APP_API_URL,
  REACT_APP_CLIENT_ID_GOOGLE
} = process.env;

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig";
const ls = localStorage.getItem(I18N_CONFIG_KEY);
const browserLanguage = detectBrowserLanguage();
let langShort = "en";
if (browserLanguage) {
  const languageParts = browserLanguage.split("-");
  langShort = languageParts[0];
}

let SELECTED_LANG = langShort;
if (JSON.parse(ls)) {
  SELECTED_LANG = JSON.parse(ls).selectedLang;
}

// else {
//     const SELECTED_LANG = 'en';
// }
// const API_URL = REACT_APP_API_URL + "/api/" + SELECTED_LANG + "/";
const API_URL = REACT_APP_API_URL;

export const LOGIN_URL = API_URL + "user/login";
export const LOGIN_GOOGLE_URL = "https://accounts.google.com/o/oauth2/v2/auth";
export const LOGIN_GOOGLE_REDIRECT_URL = API_URL + "v2/social/google";
export const LOGIN_MICROSOFT_URL = API_URL + "login_microsoft";
export const LOGIN_FACEBOOK_URL = API_URL + "login_facebook";
export const REGISTER_URL = API_URL + "user/register";
export const UPDATE_USER_URL = API_URL + "user/update";
export const REQUEST_PASSWORD_URL = API_URL + "user/forget_password";
export const RESET_PASSWORD_URL = API_URL + "user/update_password";
export const LOGIN_WITH_SOCIAL = API_URL + "v1/users/social";

// const COUNTRY_ALL_URL = REACT_APP_API_URL + "/api/countries/all/dropdown";

// export const ME_URL = "api/me";
export const ME_URL = API_URL + "user/me";

/**
 *
 * @param email
 * @param password
 */
export function login(email, password) {
  return client.post(LOGIN_URL, {
    email: email,
    password: password
  });
}

/**
 *
 * @param params
 */
export function loginWithSocialMedia(params) {
  return client.post(LOGIN_WITH_SOCIAL, params);
}

/**
 *
 * @param root0
 * @param root0.response_type
 * @param root0.prompt
 * @param root0.access_type
 * @param root0.scope
 */
export function loginGoogle({
  response_type,
  prompt,
  access_type,
  scope
}) {
  return client.post(LOGIN_GOOGLE_URL, {
    response_type: response_type,
    client_id: REACT_APP_CLIENT_ID_GOOGLE,
    redirect_uri: LOGIN_GOOGLE_REDIRECT_URL,
    prompt: prompt,
    access_type: access_type,
    scope: scope
  });
}

/**
 *
 * @param email
 * @param name
 * @param access_token
 */
export function loginMicrosoft(email, name, access_token) {
  return client.post(LOGIN_MICROSOFT_URL, {
    email: email,
    name: name,
    access_token: access_token
  });
}

/**
 *
 * @param email
 * @param name
 * @param access_token
 */
export function loginFacehook(email, name, access_token) {
  return client.post(LOGIN_FACEBOOK_URL, {
    email: email,
    name: name,
    access_token: access_token
  });
}

/**
 *
 * @param email
 * @param name
 * @param password
 * @param password_confirmation
 */
export function register(email, name, password, password_confirmation) {
  return client
    .post(REGISTER_URL, {
      email: email,
      name: name,
      password: password,
      password_confirmation: password_confirmation,
      language: SELECTED_LANG
    })
    .catch(err => {
      if (err.response) {
        throw err.response;
      } else if (err.request) {
        throw err.request;
      } else {
        throw err;
      }
    });
}

/**
 *
 * @param email
 * @param url
 */
export function requestPassword(email, url) {
  return client.post(
    REQUEST_PASSWORD_URL,
    {
      email,
      url
    }
    // {
    //   headers: {
    //     "Access-Control-Allow-Origin": "*",
    //     'Content-Type': 'application/x-www-form-urlencoded'
    //   }
    // }
  );
}

/**
 *
 * @param email
 * @param password
 * @param password_confirmation
 * @param token
 */
export function resetPassword(email, password, password_confirmation, token) {
  return client
    .post(RESET_PASSWORD_URL, {
      email: email,
      password: password,
      password_confirmation: password_confirmation,
      token: token
    })
    .catch(err => {
      // throw err;
      if (err.response) {
        throw err.response;
      } else if (err.request) {
        throw err.request;
      } else {
        throw err;
      }
    });
}

/**
 *
 * @param obj
 */
export function convertErrorObject(obj) {
  if (obj && Array.isArray(Object.values(obj))) {
    return Object.values(obj).map(key => {
      if (Array.isArray(key)) {
        return key.map(err => <div key={err}>{err}</div>);
      } else {
        return <div key={key}>{key}</div>;
      }
    });
  } else {
    return "Unknown Error";
  }
}
