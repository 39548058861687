import { RolesList } from "../../components";

export const DeveloperRoles = (props) => {

  return (
    <RolesList
      showAdd
      showEdit
      showDelete
    />
  );
};
