import React from "react";
import { useAuth, useUser } from "app/providers";
import { useMe } from "hooks";
import { Navigate, Outlet } from "react-router";

// import { LayoutSplashScreen } from "_metronic/layout";

/**
 *
 */
export function AcquireLogin() {
  useMe();
  const { isAuthenticated } = useAuth();

  // const navigate = useNavigate();
  // const { pathname } = useLocation();
  const { user } = useUser();
  
  // const { isRegistered, teams } = user || {
  // };

  // useEffect(() => {
  //   if (isRegistered !== undefined) {
  //     if (!isRegistered || !teams.length) {
  //       navigate("/registration-flow");
  //     } else navigate(pathname);
  //   }
  // }, [isRegistered, navigate, pathname, teams]);

  return isAuthenticated ? (
    user ? (
      <Outlet />
    ) : (<>
      {/* <LayoutSplashScreen /> */}
    </>
    )
  ) : (
    <Navigate to="/auth/login" />
  );
}
