import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useTextDomainContext} from "app/providers";
import {useUpdateUser} from "hooks";
import {Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useSnackbar} from "notistack";
import PropTypes from "prop-types";

export const ChangePassword = (props) => {
  //region Loading data
  const {enqueueSnackbar} = useSnackbar();
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);

  const initialValue = useMemo(() => {
    return {
      userId: "",
      passwordShown: false,
      confirmPasswordShown: false,
      inputName: "",
      inputEmail: "",
      inputPassword: "",
      inputConfirmPassword: ""
    };
  }, []);
  //endregion Loading data

  //region React Hook (useState)
  // const [userId, setUserId] = useState(initialValue.userId);
  const [passwordShown, setPasswordShown] = useState(initialValue.passwordShown);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(initialValue.confirmPasswordShown);
  const [inputName, setInputName] = useState(initialValue.inputName);
  const [inputEmail, setInputEmail] = useState(initialValue.inputEmail);
  const [inputPassword, setInputPassword] = useState(initialValue.inputPassword);
  const [inputConfirmPassword, setInputConfirmPassword] = useState(initialValue.inputConfirmPassword);
  //endregion React Hook (useState)

  //region React Hook (useEffect)

  useEffect(() => {
    if (props?.row?.id) {
      // setUserId(props.row.id);
      setInputName(props.row.name);
      setInputEmail(props.row.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.row]);

  //endregion React Hook (useEffect)

  //region Handlers
  const resetForm = useCallback(() => {
    // setUserId(initialValue.userId);
    // setInputName(initialValue.inputName);
    // setInputEmail(initialValue.inputEmail);
    setInputPassword(initialValue.inputPassword);
    setInputConfirmPassword(initialValue.inputConfirmPassword);
  }, [initialValue]);

  const [emailError, setEmailError] = useState(false);

  const {
    updateUser,
    loading: updating
  } = useUpdateUser(
    () => {
      resetForm();
      handleClose();
      enqueueSnackbar(gettext("Password Changed Successfully"), {
        variant: "success"
      });
    },
    error => {
      if (error === "409") {
        setEmailError(true);
      } else if (error === "410") {
        enqueueSnackbar(gettext("You are not authorized to perform this action"), {
          variant: "error"
        });
      } else {
        enqueueSnackbar(`Backend Error: ${error}`, {
          variant: "error"
        });
      }
    }
  );

  const submitHandler = e => {
    e.preventDefault();

    if (props.row.id) {
      const variables = {
        userId: props.row.id,
        password: inputPassword,
        confirmPassword: inputConfirmPassword,
      };

      updateUser({
        variables: variables,
        refetchQueries: ["AllUsers", "SystemUsers", "PartnerUsers", "CompanyUsers"]
      }).then();
    }
  };
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPassword = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const handleNameChange = value => {
    setInputName(value);
  };

  const handleClose = () => {
    resetForm();
    props.handleClose();
  };
  //endregion Handlers

  return (
    <Drawer
      anchor={"right"}
      open={props.open}
      onClose={handleClose}
      sx={{
        zIndex: theme => theme.zIndex.appBar + 1
      }}
      PaperProps={{
        sx: {
          padding: 4,
          maxWidth: 350
        }
      }}
    >
      <Box sx={{
        width: "auto"
      }}>
        <form onSubmit={submitHandler}>
          <Stack spacing={4} sx={{
            height: "100%"
          }}>
            <Typography fontSize={22} fontWeight={700}>
              {!props.row.id && gettext("User ID is missing")}
              {props.row.id && gettext("Change Password")}
            </Typography>

            <Box sx={{
              height: "100%"
            }}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    label={gettext("Name")}
                    placeholder={gettext("Enter name")}
                    size="small"
                    required
                    fullWidth
                    disabled
                    value={inputName}
                    onChange={e => handleNameChange(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    type="email"
                    label={gettext("Email")}
                    placeholder={gettext("Enter email")}
                    size="small"
                    required
                    fullWidth
                    disabled
                    autoComplete="off"
                    value={inputEmail}
                    onChange={e => {
                      setInputEmail(e.target.value);
                      setEmailError(false);
                    }}
                    error={emailError}
                    helperText={emailError && gettext("Email already exists!")}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    type={passwordShown ? "text" : "password"}
                    label={gettext("Password")}
                    placeholder={gettext("Enter password")}
                    size="small"
                    required
                    fullWidth
                    autoComplete="new-password"
                    value={inputPassword}
                    onChange={e => setInputPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={togglePassword}
                            edge="end"
                          >
                            {passwordShown ? <VisibilityOff/> : <Visibility/>}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type={confirmPasswordShown ? "text" : "password"}
                    label={gettext("Confirm Password")}
                    placeholder={gettext("Enter password again")}
                    size="small"
                    required
                    fullWidth
                    autoComplete="new-password"
                    value={inputConfirmPassword}
                    onChange={e => setInputConfirmPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={toggleConfirmPassword}
                            edge="end"
                          >
                            {confirmPasswordShown ? <VisibilityOff/> : <Visibility/>}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Paper elevation={0}>
              <Grid container spacing={4}>
                <Grid item xs={6} textAlign="center">
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => handleClose()}
                  >
                    {gettext("Cancel")}
                  </Button>
                </Grid>

                <Grid item xs={6} textAlign="center">
                  <LoadingButton
                    variant="contained"
                    loading={updating}
                    fullWidth
                    type="submit"
                  >
                    {gettext("Save")}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Paper>
          </Stack>
        </form>
      </Box>
    </Drawer>
  );
};

ChangePassword.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  row: PropTypes.object
};
