import { TextField, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import { useTextDomainContext } from "../../providers";

export const KeyTextField = (props) => {
  const allowedChars = /^[0-9a-z-]+$/;
  const { TextDomainContext } = useTextDomainContext();
  const { gettext } = useContext(TextDomainContext);
  const theme = useTheme();

  const [value, setValue] = useState(props.value);

  const error = !allowedChars.test(value);

  const handlePaste = (event) => {
    const pastedText = (event.clipboardData || window.clipboardData).getData("text");
    // Validate the pasted text using the regular expression
    if (allowedChars.test(pastedText)) {
      setValue(value + pastedText);
      event.preventDefault(); // Prevent default pasting behavior
    }

    if (typeof props.onPaste === "function") {
      props.onPaste(event);
    }
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    if (newValue === "" || allowedChars.test(newValue)) {
      setValue(newValue);
    }
    if (typeof props.onChange === "function") {
      props.onChange(event);
    }
  };

  return (
    <TextField
      {...props}
      value={value}
      onChange={handleChange}
      onPaste={handlePaste}
      error={error}
      helperText={error ? gettext("Only lowercase alphanumeric characters and dashes are allowed") : ""}
      sx={{
        inputProps: {
          style: {
            backgroundColor: theme.palette.error.light, ...error
          }
        }
      }}
    />
  );
};


