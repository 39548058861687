import {toAbsoluteUrl} from "../url";
// import { useTextDomainContext } from "../../app/providers";
// import { useContext } from "react";

export const Languages = (gettext) => {
  // const { TextDomainContext } = useTextDomainContext();
  // const { gettext } = useContext(TextDomainContext);

  const languages = [
    {
      lang: "da",
      name: gettext("Danish"),
      flag: toAbsoluteUrl("/media/svg/flags/174-denmark.svg")
    },
    {
      lang: "de",
      name: gettext("German"),
      flag: toAbsoluteUrl("/media/svg/flags/162-germany.svg")
    },
    {
      lang: "en",
      name: gettext("English"),
      flag: toAbsoluteUrl("/media/svg/flags/012-uk.svg")
    },
    {
      lang: "nb",
      name: gettext("Norwegian"),
      flag: toAbsoluteUrl("/media/svg/flags/143-norway.svg")
    },
    {
      lang: "sv",
      name: gettext("Swedish"),
      flag: toAbsoluteUrl("/media/svg/flags/184-sweden.svg")
    },
    {
      lang: "nl",
      name: gettext("Dutch"),
      flag: toAbsoluteUrl("/media/svg/flags/237-netherlands.svg")
    },
    {
      lang: "fr",
      name: gettext("French"),
      flag: toAbsoluteUrl("/media/svg/flags/195-france.svg")
    },
    // {
    //   lang: "zh",
    //   name: "Mandarin",
    //   flag: toAbsoluteUrl("/media/svg/flags/015-china.svg")
    // },
    // {
    //   lang: "es",
    //   name: "Spanish",
    //   flag: toAbsoluteUrl("/media/svg/flags/128-spain.svg")
    // },
    // {
    //   lang: "ja",
    //   name: "Japanese",
    //   flag: toAbsoluteUrl("/media/svg/flags/063-japan.svg")
    // },
    // {
    //   lang: "es",
    //   name: gettext("Spanish"),
    //   flag: toAbsoluteUrl("/media/svg/flags/016-spain.svg")
    // },
  ];

  return languages.sort((a, b) => a.lang.localeCompare(b.lang));
};
