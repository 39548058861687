import {useCallback} from "react";
import {useUser} from "../../app/providers";
import {SendSlackMessage} from "../../utils";

/**
 *
 */
export async function useSendLoginSlackMessage() {
  const {user} = useUser();

  const sendLoginMessage = useCallback(
    authToken => {
      const userId = user?.id || "N/A";
      const userName = user?.name || "N/A";
      const userEmail = user?.email || "N/A";
      const teamName = user?.currentTeam || "NA";

      if (process.env.REACT_APP_NODE_ENV === "production") {
        const fields = [
          {
            title: "User Email",
            value: userEmail
          },
          {
            title: "User Name",
            value: userName
          },
          {
            title: "User ID",
            value: userId
          },
          {
            title: "User Team",
            value: teamName
          },
          {
            title: "Token",
            value: `Bearer: ${authToken}`
          }
        ];

        const slackErrorData = {
          channel: "fabeke-login",
          type: "success",
          heading: `Login activity: After login, user landed on ${window.location.href}`,
          detail: `${userName} (${userEmail}) has logged in on the ${process.env.REACT_APP_NODE_ENV} environment`,
          fields: fields
        };
        SendSlackMessage(slackErrorData);
      }
    }, [user]);

  return sendLoginMessage;
}
