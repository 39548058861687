import React, { createContext, useContext, useState } from "react";
import { TextDomainContext } from "@absolutesight/react-gettext";

const UserContext = createContext({
  user: null,
  setUser: () => null,
  colors: [],
  setColors: () => null
});

export const withUser = Component => {
  return props => <Component {...props} {...useContext(UserContext)} />;
};

/**
 *
 */
export function useUser() {
  const { user, setUser, colors, setColors } = useContext(UserContext);
  return {
    user,
    setUser,
    colors,
    setColors
  };
}

/**
 *
 */
export function useTextDomainContext() {
  return {
    TextDomainContext 
  };
}

/**
 *
 * @param root0
 * @param root0.children
 */
export default function UserProvider({ children }) {
  const [user, setUser] = useState(undefined);
  const [colors, setColors] = useState([]);

  const value = {
    user,
    setUser,
    colors,
    setColors
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
