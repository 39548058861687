import React, {useContext} from "react";
import List from "@mui/material/List";
import Level1MenuItem from "./Level1MenuItem";
import Level2MenuItem from "./Level2MenuItem";
import Level2MenuHead from "./Level2MenuHead";
import {RiTranslate} from "react-icons/ri";
import {GiCheckMark, GiMoneyStack} from "react-icons/gi";
import {MdCurrencyExchange,
  MdDeveloperMode,
  MdElectricBolt,
  MdOutlineAccountCircle,
  MdOutlineEnergySavingsLeaf} from "react-icons/md";
import Logout from "@mui/icons-material/Logout";
import {useTextDomainContext, useUser} from "app/providers";
import {RoleType} from "../../../../../../utils";
import {IoSettingsOutline} from "react-icons/io5";
import {LiaUserFriendsSolid} from "react-icons/lia";
import {IoMdKey} from "react-icons/io";
import {PiBuildingOfficeLight} from "react-icons/pi";
import {TbSum} from "react-icons/tb";

// import {
//     AiOutlineThunderbolt
// } from "react-icons/ai";
// import { MdOutlineViewStream, MdOutlineAccountCircle } from "react-icons/md";
// import { BsDatabase } from "react-icons/bs";
// import TimelineIcon from "@mui/icons-material/Timeline";

/**
 *
 */
export function Menu() {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);

  const {user} = useUser();
  const roleType = RoleType(user);

  // useEffect(() => {
  //     const { email, isSuperAdmin } = user;
  //     if (email && !isSuperAdmin && email.split("@")[1] !== "fabeke.com") {
  //         setSupportAccess(false);
  //     }
  // }, [user]);
  // const { canRead, canAccessPage } = useCustomerPermission();
  // const { canRead } = useCustomerPermission();

  return (
    <List sx={{
      paddingX: 2,
    }}>
      {/* region Procurement */}
      <Level1MenuItem
        link={"/procurement"}
        label={gettext("Procurement")}
        icon={<GiMoneyStack size={18} className="mx-3"/>}
        codes={["procurement", "everything-full", "everything-readonly"]}
      />
      {/* endregion Procurement */}

      {/* region Sustainability */}
      <Level1MenuItem
        link={"/climate"}
        label={gettext("Climate")}
        icon={<MdOutlineEnergySavingsLeaf size={18} className="mx-3"/>}
        codes={["sustainability", "everything-full", "everything-readonly"]}
      />
      {/* endregion Sustainability */}

      {/* region Consumption */}
      <Level1MenuItem
        link={"/energy"}
        label={gettext("Energy")}
        icon={<MdElectricBolt size={18} className="mx-3"/>}
        codes={["consumption", "everything-full", "everything-readonly"]}
      />

      {/* endregion Consumption */}

      {/* region Energy Contracts */}
      <Level1MenuItem
        link={"/energy-statement"}
        label={gettext("Energy Statement")}
        icon={<TbSum size={18} className="mx-3"/>}
        codes={["allow-to-all", "everything-full", "everything-readonly"]}
      />
      {/* endregion Energy Contracts */}

      {/* region Energy Contracts */}
      <Level1MenuItem
        link={"/data/deals"}
        label={gettext("Energy Contracts")}
        icon={<MdCurrencyExchange size={18} className="mx-3"/>}
        codes={["energy-contracts-full", "energy-contracts-readonly", "everything-full", "everything-readonly"]}
      />
      {/* endregion Energy Contracts */}

      {/* region Data Quality */}
      <Level2MenuHead
        link={"/data-quality"}
        label={gettext("Data Quality")}
        codes={["under-development"]}
        // display={CanDisplay("Data Quality")}
        icon={<GiCheckMark size={18} className="mx-3"/>}
      >
        <Level2MenuItem
          link={"/data-quality/consumption"}
          label={gettext("Consumption")}
          codes={["under-development"]}
        />
      </Level2MenuHead>
      {/* endregion Data Quality */}

      {/* region Customer Admin */}
      <Level2MenuHead
        link={"/company-admin"}
        label={roleType.includes("company") ? gettext("Settings") : gettext("Company")}
        codes={["company-users-readonly", "company-users-full", "everything-full", "everything-readonly"]}
        icon={
          <>
            {roleType.includes("company") && <IoSettingsOutline size={18} className="mx-3"/>}
            {!roleType.includes("company") && <PiBuildingOfficeLight size={18} className="mx-3"/>}
          </>
        }
      >
        <Level2MenuItem
          link={"/company-admin/company-users"}
          label={gettext("Users")}
          codes={["company-users-readonly", "company-users-full", "everything-full", "everything-readonly"]}
        />
      </Level2MenuHead>
      {/* endregion Customer Admin */}

      {/* region Partner Admin */}
      <Level2MenuHead
        link={"/partner-admin"}
        label={roleType.includes("partner") ? gettext("Settings") : gettext("Partner")}
        codes={["partner-users-readonly", "partner-users-full", "everything-full", "everything-readonly"]}
        // display={CanDisplay("Partner")}
        icon={
          <>
            {roleType.includes("partner") && <IoSettingsOutline size={18} className="mx-3"/>}
            {!roleType.includes("partner") && <LiaUserFriendsSolid size={18} className="mx-3"/>}
          </>
        }
      >
        <Level2MenuItem
          link={"/partner-admin/partner-users"}
          label={gettext("Users")}
          codes={["partner-users-readonly", "partner-users-full", "everything-full", "everything-readonly"]}
        />
      </Level2MenuHead>
      {/* endregion Partner Admin */}

      {/* region System Admin */}
      <Level2MenuHead
        link={"/system-admin"}
        label={roleType.includes("system") ? gettext("Settings") : gettext("System")}
        codes={["system-users-readonly", "system-users-full", "all-users-readonly", "all-users-full", "everything-full", "everything-readonly"]}
        // display={CanDisplay("Admin")}
        icon={
          <>
            {roleType.includes("system") && <IoSettingsOutline size={18} className="mx-3"/>}
            {!roleType.includes("system") && <IoMdKey size={18} className="mx-3"/>}
          </>
        }
      >
        <Level2MenuItem
          link={"/system-admin/all-users"}
          label={gettext("All Users")}
          codes={["all-users-readonly", "all-users-full", "everything-full", "everything-readonly"]}
        />

        <Level2MenuItem
          link={"/system-admin/system-users"}
          label={gettext("System Users")}
          codes={["system-users-readonly", "system-users-full", "everything-full", "everything-readonly"]}
        />

        <Level2MenuItem
          link={"/system-admin/roles"}
          label={gettext("Roles")}
          codes={["role-permissions", "everything-full", "everything-readonly"]}
        />
      </Level2MenuHead>
      {/* endregion System Admin */}

      {/* region Support */}
      <Level2MenuHead
        link={"/support"}
        label={gettext("Support")}
        codes={["under-development"]}
        // display={CanDisplay("support")}
        icon={<RiTranslate size={18} className="mx-3"/>}
      >
        <Level2MenuItem
          link={"/support/datahub"}
          label={gettext("DataHub")}
          codes={["under-development"]}
        />
        <Level2MenuItem
          link={"/support/translation"}
          label={gettext("Translation")}
          codes={["under-development"]}
        />
        <Level2MenuItem
          link={"/support/active-users"}
          label={gettext("Active Users")}
          codes={["under-development"]}
        />
      </Level2MenuHead>
      {/* endregion Support */}

      {/* region Dev Admin */}
      <Level2MenuHead
        link={"/developer"}
        label={gettext("Developer")}
        codes={["developer"]}
        // display={CanDisplay("Developer")}
        icon={<MdDeveloperMode size={18} className="mx-3"/>}
      >
        <Level2MenuItem
          link={"/developer/users"}
          label={gettext("Users List")}
          codes={["developer"]}
        />
        <Level2MenuItem
          link={"/developer/version"}
          label={gettext("Version")}
          codes={["developer"]}
        />
        <Level2MenuItem
          link={"/developer/roles"}
          label={gettext("Roles")}
          codes={["developer"]}
        />
        <Level2MenuItem
          link={"/developer/permissions"}
          label={gettext("Permissions")}
          codes={["developer"]}
        />
        <Level2MenuItem
          link={"/developer/generate-email"}
          label={gettext("Generate Email")}
          codes={["developer"]}
        />
        <Level2MenuItem
          link={"/developer/energy"}
          label={gettext("Energy")}
          codes={["developer"]}
        />
        <Level2MenuItem
          link={"/developer/invoice"}
          label={gettext("Invoice")}
          codes={["developer"]}
        />
        <Level2MenuItem
          link={"/developer/modals"}
          label={gettext("Modals")}
          codes={["developer"]}
        />

        <Level2MenuItem
          link={"/developer/excel"}
          label={gettext("Excel Update")}
          codes={["developer"]}
        />

        <Level2MenuItem
          link={"/developer/aggregation"}
          label={gettext("Aggregation")}
          codes={["developer"]}
        />
      </Level2MenuHead>
      {/* endregion Dev Admin */}

      {/* region My Account */}
      <Level2MenuHead
        link={"/user-profile"}
        label={gettext("My Account")}
        codes={["allow-to-all", "everything-full", "everything-readonly"]}
        // display={CanDisplay("Admin")}
        icon={<MdOutlineAccountCircle size={18} className="mx-3"/>}
      >
        <Level2MenuItem
          link={"/user-profile/personal-information"}
          label={gettext("My Profile")}
          codes={["allow-to-all", "everything-full", "everything-readonly"]}
        />
        {/*<Level2MenuItem*/}
        {/*  link={"/user-profile/change-password"}*/}
        {/*  label={gettext("Change Password")}*/}
        {/*  codes={["under-development"]}*/}
        {/*/>*/}
        {/*<Level2MenuItem*/}
        {/*  link={"/user-profile/customers"}*/}
        {/*  label={gettext("My Companies")}*/}
        {/*  codes={["under-development"]}*/}
        {/*/>*/}
        {/*<Level2MenuItem*/}
        {/*  link={"/Notifications"}*/}
        {/*  label={gettext("Notifications")}*/}
        {/*  codes={["under-development"]}*/}
        {/*  icon={*/}
        {/*    <NotificationsIcon*/}
        {/*      className="mx-3"*/}
        {/*      sx={{*/}
        {/*        fontSize: 18,*/}
        {/*        cursor: "pointer"*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  }*/}
        {/*/>*/}
        {/* <Level2MenuItem */}
        {/*   link={"/user-profile/settings"} */}
        {/*   label={gettext("Settings")} */}
        {/*   code={"my-account-settings"} */}
        {/* /> */}
      </Level2MenuHead>
      {/* endregion My Account */}

      {/* region Logout */}
      <Level1MenuItem
        link={"/logout"}
        label={gettext("Sign Out")}
        icon={<Logout className="mx-3"/>}
        codes={["allow-to-all", "everything-full", "everything-readonly"]}
      />
      {/* endregion Logout */}

      {/* region Old Code */}
      {/*<Level1MenuItem*/}
      {/*  link={"/dashboard"}*/}
      {/*  label={gettext("Dashboard")}*/}
      {/*  icon={<RiHomeLine size={18} className="mx-3" />}*/}
      {/*  code="dashboard"*/}
      {/*/>*/}
      {/* <Level2MenuHead */}
      {/*  label={gettext("Carbon Emissions")} */}
      {/*  // code="building-energy" */}
      {/*  display={CanDisplay("Buildings")} */}
      {/*  icon={<MdOutlineViewStream size={18} className="mx-3" />} */}
      {/*  link={"/buildings"} */}
      {/* > */}
      {/*  <Level2MenuItem*/}
      {/*    link={"/buildings/energy"}*/}
      {/*    label={gettext("Energy")}*/}
      {/*    code="building-energy"*/}
      {/*  />*/}
      {/*  <Level2MenuItem */}
      {/*    link={"/buildings/electricity"} */}
      {/*    label={gettext("Electricity")} */}
      {/*    code="building-electricty" */}
      {/*  /> */}
      {/*  <Level2MenuItem*/}
      {/*    link={"/buildings/heating"}*/}
      {/*    label={gettext("District Heating")}*/}
      {/*    // label={gettext("Heating")}*/}
      {/*    code="building-heating"*/}
      {/*  />*/}
      {/*  <Level2MenuItem*/}
      {/*    link={"/buildings/gas"}*/}
      {/*    label={gettext("Natural Gas")}*/}
      {/*    code="building-gas"*/}
      {/*  />*/}
      {/*  <Level2MenuItem*/}
      {/*    link={"/buildings/water"}*/}
      {/*    label={gettext("Water")}*/}
      {/*    code="building-water"*/}
      {/*  />*/}
      {/*  <Level2MenuItem*/}
      {/*    link={"/buildings/epc"}*/}
      {/*    label={gettext("EPC")}*/}
      {/*    code="building-epc"*/}
      {/*  />*/}
      {/*  <Level2MenuItem*/}
      {/*    link={"/buildings/co2-report"}*/}
      {/*    label={gettext("CO₂ Report")}*/}
      {/*    code="building-co2-report"*/}
      {/*  />*/}
      {/*  /!* <Level2MenuItem*/}
      {/*    link={"/buildings/new-co2-report"}*/}
      {/*    label={gettext('CO₂ Report')}*/}
      {/*    code="building-co2-report"*/}
      {/*  /> *!/*/}
      {/*  <Level2MenuItem*/}
      {/*    link={"/buildings/benchmark"}*/}
      {/*    label={gettext("Benchmark")}*/}
      {/*    code="building-benchmark"*/}
      {/*  />*/}
      {/*  {canRead("administration") && (*/}
      {/*    <Level2MenuItem*/}
      {/*      link={"/buildings/administration"}*/}
      {/*      label={gettext("Administration")}*/}
      {/*      code="building-administration"*/}
      {/*    />*/}
      {/*  )}*/}
      {/* </Level2MenuHead> */}
      {/*<Level2MenuHead*/}
      {/*  link={"/company"}*/}
      {/*  label={gettext("Company")}*/}
      {/*  // code="company-report"*/}
      {/*  display={CanDisplay("Company")}*/}
      {/*  icon={<RiBuilding2Line size={18} className="mx-3" />}*/}
      {/*>*/}
      {/*  /!* <Level2MenuItem*/}
      {/*    link={"/company/scope-1"}*/}
      {/*    label={*/}
      {/*      <>*/}
      {/*        gettext('Scope') 1*/}
      {/*      </>*/}
      {/*    }*/}
      {/*    code="company-scope1"*/}
      {/*  />*/}
      {/*  <Level2MenuItem*/}
      {/*    link={"/company/scope-2"}*/}
      {/*    label={*/}
      {/*      <>*/}
      {/*        gettext('Scope') 2*/}
      {/*      </>*/}
      {/*    }*/}
      {/*    code="company-scope2"*/}
      {/*  />*/}
      {/*  <Level2MenuItem*/}
      {/*    link={"/company/scope-3"}*/}
      {/*    label={*/}
      {/*      <>*/}
      {/*        gettext('Scope') 3*/}
      {/*      </>*/}
      {/*    }*/}
      {/*    code="company-scope1"*/}
      {/*  /> *!/*/}
      {/*  <Level2MenuItem*/}
      {/*    link={"/company/account-link"}*/}
      {/*    label={gettext("Account link")}*/}
      {/*    code="company-account-link"*/}
      {/*  />*/}
      {/*  <Level2MenuItem*/}
      {/*    link={"/company/data"}*/}
      {/*    label={gettext("Data")}*/}
      {/*    code="company-data"*/}
      {/*  />*/}
      {/*  <Level2MenuItem*/}
      {/*    link={"/company/data-link"}*/}
      {/*    label={gettext("Data Link")}*/}
      {/*    code="company-data"*/}
      {/*  />*/}
      {/*  <Level2MenuItem*/}
      {/*    link={"/company/ghg-result"}*/}
      {/*    label={gettext("GHG Result")}*/}
      {/*    code="company-report"*/}
      {/*  />*/}
      {/*  /!* <Level2MenuItem*/}
      {/*    link={"/company/comparison"}*/}
      {/*    label={gettext('Comparison')}*/}
      {/*    code="company-comparision"*/}
      {/*  /> *!/*/}
      {/*  /!* <Level2MenuItem*/}
      {/*    link={"/company/settings"}*/}
      {/*    label={gettext('Settings')}*/}
      {/*    // code='my-settings'*/}
      {/*  /> *!/*/}
      {/*  /!* <Level2MenuItem*/}
      {/*    link={"/company/customers"}*/}
      {/*    label={gettext('Companies')}*/}
      {/*    // code='my-customers'*/}
      {/*  /> *!/*/}
      {/*  /!* <Level2MenuItem*/}
      {/*    link={"/company/all"}*/}
      {/*    label={gettext('All')}*/}
      {/*    // code='building-water'*/}
      {/*  /> *!/*/}
      {/*</Level2MenuHead>*/}
      {/* <Level2MenuHead */}
      {/*   link={"/procurement"} */}
      {/*   label={gettext("Procurement")} */}
      {/*   display={CanDisplay("Procurement")} */}
      {/*   icon={<GiMoneyStack size={18} className="mx-3" />} */}
      {/* > */}
      {/*   <Level2MenuItem */}
      {/*     link={"/procurement/electricity"} */}
      {/*     label={gettext("Electricity")} */}
      {/*     code="procurement-electricty" */}
      {/*   /> */}
      {/* </Level2MenuHead> */}
      {/* <Level2MenuHead */}
      {/*   link={"/data"} */}
      {/*   label={gettext("Data Control")} */}
      {/*   display={CanDisplay("Data Control")} */}
      {/*   icon={<BsDatabase size={18} className="mx-3" />} */}
      {/* > */}
      {/*   <Level2MenuItem */}
      {/*     link={"/data/deals"} */}
      {/*     label={gettext("Deals")} */}
      {/*     code="data-deals" */}
      {/*   /> */}
      {/* </Level2MenuHead> */}
      {/*{canRead("map") && (*/}
      {/*  <Level1MenuItem*/}
      {/*    link={"/report/map"}*/}
      {/*    label={gettext("Map")}*/}
      {/*    code="map"*/}
      {/*    //svg={toAbsoluteUrl("/media/gg/808080/svg-256/map.svg")}*/}
      {/*    icon={<RiMapPin2Line size={18} className="mx-3" />}*/}
      {/*  />*/}
      {/*)}*/}
      {/* <Level2MenuHead
        link={"/partner"}
        label={gettext("Network")}
        // code="admin-buildings"
        display={CanDisplay("Partner")}
        icon={<FaRegHandshake size={18} className="mx-3" />}
      >
        <Level2MenuItem
          link={"/partner/members-list"}
          label={gettext("Members List")}
          code="partner-users"
        />
      </Level2MenuHead> */}

      {/* <Level2MenuItem
          link={"/system-admin/partners-list"}
          label={gettext("Network List")}
          code="admin-users"
        /> */}
      {/*<Level2MenuItem*/}
      {/*  link={"/system-admin/scrapper-log"}*/}
      {/*  label={gettext("Scraper Log")}*/}
      {/*  code="scrapper-log"*/}
      {/*/>*/}
      {/*<Level2MenuItem*/}
      {/*  link={"/system-admin/scrapper-list"}*/}
      {/*  label={gettext("Scraper List")}*/}
      {/*  // code="scrapper-log"*/}
      {/*/>*/}
      {/*<Level2MenuItem*/}
      {/*  link={"/system-admin/admin-energy-supplier"}*/}
      {/*  label={gettext("Admin Energy Supplier")}*/}
      {/*  code="admin-energy-supplier"*/}
      {/*/>*/}
      {/*<Level2MenuItem*/}
      {/*  link={"/system-admin/account-groups"}*/}
      {/*  label={gettext("Account Group")}*/}
      {/*  // code="admin-energy-supplier"*/}
      {/*/>*/}
      {/*<Level2MenuItem*/}
      {/*  link={"/system-admin/account-charts"}*/}
      {/*  label={gettext("Account Charts")}*/}
      {/*  // code="admin-energy-supplier"*/}
      {/*/>*/}
      {/*<Level2MenuItem*/}
      {/*  link={"/system-admin/emission-factors"}*/}
      {/*  label={gettext("Emission Factors")}*/}
      {/*  // code="admin-energy-supplier"*/}
      {/*/>*/}
      {/*<Level2MenuItem*/}
      {/*  link={"/system-admin/default-settings"}*/}
      {/*  label={gettext("Default Settings")}*/}
      {/*/>*/}
      {/*<Level2MenuItem*/}
      {/*  link={"/system-admin/service-providers"}*/}
      {/*  label={gettext("Service Providers")}*/}
      {/*/>*/}
      {/*{canAccessPage("prediction") && (*/}
      {/*  <Level2MenuHead*/}
      {/*    label={gettext("Prediction")}*/}
      {/*    // code="building-energy"*/}
      {/*    display={CanDisplay("prediction")}*/}
      {/*    icon={<TimelineIcon size={18} className="mx-3" />}*/}
      {/*    link={"/prediction"}*/}
      {/*  >*/}
      {/*    <Level2MenuItem*/}
      {/*      link={"/prediction/electricity"}*/}
      {/*      label={gettext("Electricity")}*/}
      {/*      code="prediction"*/}
      {/*    />*/}
      {/*  </Level2MenuHead>*/}
      {/*)}*/}
      {/* <Level2MenuItem */}
      {/*   link={"/user-profile/partner-web"} */}
      {/*   label={gettext("Access from partner website")} */}
      {/*   code={"partner-users"} */}
      {/* /> */}
      {/* {canRead("customer-roles") && ( */}
      {/*   <Level2MenuItem */}
      {/*     link={"/user-profile/customer-roles"} */}
      {/*     label={gettext("Company Roles")} */}
      {/*     code={"my-account-customer-roles"} */}
      {/*   /> */}
      {/* )} */}
      {/* {canRead("api-key") && ( */}
      {/*   <Level2MenuItem */}
      {/*     link={"/user-profile/api-key"} */}
      {/*     label={gettext("API KEY")} */}
      {/*     code={"my-account-api-key"} */}
      {/*   /> */}
      {/* )} */}
      {/* <Level2MenuItem */}
      {/*   link={"/user-profile/api-docs"} */}
      {/*   label={gettext("API Docs")} */}
      {/*   code={undefined} */}
      {/* /> */}
      {/* endregion Old Code */}

    </List>
  );
}


