import React from "react";
import PropTypes from "prop-types";
import {Box} from "@mui/system";

export const TableBox = (props) => {
  const sx = {
    p: 1,
    backgroundColor: "tertiary.0",
    border: "1px solid",
    minHeight: "52px",
    borderColor: "gray.200",
    borderRadius: "8px",
    ...props.sx
  };
  // props = {
  //   ...props,
  //   sx: {
  //     p: 1,
  //     backgroundColor: "tertiary.0",
  //     border: "1px solid",
  //     minHeight: "52px",
  //     borderColor: "tertiary.100",
  //     borderRadius: "8px"
  //   }
  //
  // };

  return (
    <Box
      sx={sx}
    >
      {props.children}
    </Box>
  );
};

TableBox.propTypes = {
  children: PropTypes.node.isRequired
};
