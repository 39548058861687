import { UserCustomers } from "../../components";

export const SystemAllUserCustomers = (props) => {

  return (
    <UserCustomers
      showAdd
      showDelete
    />
  );
};
