import { useQuery } from "@apollo/client";
import { GET_USER_CUSTOMERS } from "./commands";

/**
 *
 * @param userId
 */
export function useUserCompanies(userId) {
  const { data, loading } = useQuery(GET_USER_CUSTOMERS, {
    variables: {
      userId
    }
  });

  return {
    customers: data?.comGetUser?.teams || [],
    userName: data?.comGetUser?.name || "",
    loading
  };
}
