export const DBConfig = {
  name: "fabeke",
  version: 1.0,
  objectStoresMeta: [
    {
      store: "cache",
      // storeConfigstoreConfig: { keyPath: 'key', autoIncrement: false, key: 'key'},
      storeConfigstoreConfig: {
        autoIncrement: false
      },
      storeSchema: [
        {
          name: "key",
          keyPath: "key",
          options: {
            unique: true
          }
        },
        {
          name: "value",
          keyPath: "value",
          options: {
            unique: false
          }
        },
        {
          name: "expiry",
          keyPath: "expiry",
          options: {
            unique: false
          }
        }
      ]
    },
    {
      store: "persistent-data",
      // storeConfigstoreConfig: { keyPath: 'key', autoIncrement: false, key: 'key'},
      storeConfigstoreConfig: {
        autoIncrement: false
      },
      storeSchema: [
        {
          name: "key",
          keyPath: "key",
          options: {
            unique: true
          }
        },
        {
          name: "value",
          keyPath: "value",
          options: {
            unique: false
          }
        }
      ]
    },
    {
      store: "apollo-persist-cache",
      // storeConfigstoreConfig: { keyPath: 'key', autoIncrement: false, key: 'key'},
      storeConfigstoreConfig: {
        autoIncrement: false
      },
      storeSchema: [
        {
          name: "key",
          keyPath: "key",
          options: {
            unique: true
          }
        },
        {
          name: "value",
          keyPath: "value",
          options: {
            unique: false
          }
        }
      ]
    }
  ]
};
