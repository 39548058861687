import React, {useContext} from "react";
import {GetPartnerName} from "../../../../../utils";
import {useTextDomainContext} from "../../../../providers";

export const UploadModalCarouselItems = () => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);

  const partnerName = GetPartnerName();
  return [
    {
      name: gettext("Do you know?"),
      heading: gettext("Partnerships Team"),
      description: <>
        {gettext("Ready to enhance your offering? Talk to someone from our partnerships team today & start using ") + partnerName}
      </>
    },
    {
      name: gettext("Yes I know that."),
      heading: gettext("Partnerships Team"),
      description: <>
        {gettext("Ready to enhance your offering? Talk to someone from our partnerships team today & start using ") + partnerName}
      </>
    },
    {
      name: gettext("What do you know?"),
      heading: gettext("Partnerships Team"),
      description: <>
        {gettext("Ready to enhance your offering? Talk to someone from our partnerships team today & start using ") + partnerName}
      </>
    },
    {
      name: gettext("That is great that you know that."),
      heading: gettext("Partnerships Team"),
      description: <>
        {gettext("Ready to enhance your offering? Talk to someone from our partnerships team today & start using ") + partnerName}
      </>
    },
    {
      name: gettext("But you still need to know more."),
      heading: gettext("Partnerships Team"),
      description: <>
        {gettext("Ready to enhance your offering? Talk to someone from our partnerships team today & start using ") + partnerName}
      </>
    },
  ];
};
