import {gql} from "@apollo/client";

export const CONSUMPTION_ENERGY_DATA = gql`
    query ConsumptionEnergyData (
        $meterType:  String!,
        $customer:  String!,
        $year:  String!,
    ) {
        consumptionEnergyData(
            meterType: $meterType
            customer: $customer
            year: $year
        ) {
            aggregatedData {
                meter
                jan
                feb
                mar
                apr
                may
                jun
                jul
                aug
                sep
                oct
                nov
                dec
                total
                customer
                consumerCVR
                meterNumber
                streetName
                buildingNumber
                postcode
                cityName
                email
                address
            }
            consumptionData {
                month
                details {
                    grandSum
                    data {
                        meter
                        consumptionSum
                        data {
                            ts
                            consumptionV
                        }
                    }
                    hourlyAggregatedData {
                        ts
                        consumptionV
                    }
                }
            }
        }
    }
`;