import React, {useContext, useEffect, useState} from "react";
import {useLang, useTextDomainContext, useUser} from "app/providers";
import {useMetersData} from "../../../hooks";
import {TableView1} from "../../components/Table/TableView1";
import {FilterBar1, LineChartBox} from "../../components";
import moment from "moment";
import {FormatDateForAPI,
  MeterApiDataToBarChartData,
  Segments,
  ShortSegmentDateFormat,
  ToLocaleNumber,
  UrlFriendlyString} from "../../../utils";
import {useTheme} from "@mui/material";
import {isEqual} from "lodash";

/**
 *
 * @param props
 */
export function Procurement(props) {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const {user} = useUser();
  const segments = Segments();
  const theme = useTheme();
  const locale = useLang();
  let {currentTeam: customer} = user || {
  };
  if (!customer) {
    customer = "all";
  }

  //region React Hooks (useState)
  const [building, setBuilding] = useState("all");
  const [meter, setMeter] = useState("all");
  const [energyType, setEnergyType] = useState("all");
  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);
  const [segment, setSegment] = useState("year");
  const [shortSegment, setShortSegment] = useState("m");
  const [fileNameForExport, setFileNameForExport] = useState("procurement-export");
  const [chartData, setChartData] = useState([]);

  //endregion  React Hooks (useState)

  //region Data
  const filters = {
    customer,
    address: building,
    meteringPointId: meter,
    meterType: energyType,
    fromDate: FormatDateForAPI(fromDate),
    toDate: FormatDateForAPI(toDate),
    resolution: shortSegment,
    fileType: "csv"
  };
  const {
    data: metersData,
    loading
  } = useMetersData(filters);
  //endregion Data

  useEffect(() => {
    setFileNameForExport(UrlFriendlyString("procurement-" + building + "-" + meter + "-" + energyType + "-" + segment + "-" + fromDate + "-" + toDate));
  }, [building, energyType, fromDate, meter, segment, toDate]);

  useEffect(() => {
    if (metersData.length) {
      const chartDateTemp = MeterApiDataToBarChartData(metersData, shortSegment);
      if (!isEqual(chartDateTemp, chartData)) {
        setChartData(MeterApiDataToBarChartData(metersData, shortSegment));
      }
    }
  }, [chartData, metersData, shortSegment]);

  //region For Table
  const columns = [
    {
      field: "ts",
      headerName: gettext("Date"),
      flex: 1,
      type: "date",
      valueGetter: (value) => {
        let returnValue = "";
        if (value) {
          if (value === "Summary") {
            returnValue = value;
          } else {
            returnValue = new Date(value);
          }
        }
        return returnValue;
      },
      valueFormatter: (value) => {
        let returnValue = "";
        if (value) {
          if (value === "Summary") {
            returnValue = value;
          } else {
            // returnValue = moment(value).format(segments[segment].dateFormat);
            returnValue = moment(value).format(ShortSegmentDateFormat(shortSegment));
          }
        }
        return returnValue;
      }
    },
    {
      field: "consumptionV",
      headerName: gettext("Consumption (kWh)"),
      flex: 1,
      type: "number",
      valueFormatter: (value) => ToLocaleNumber(locale, value, 2)
    },
    {
      field: "consumptionPrice",
      headerName: gettext("Price (Øre/kWh)"),
      flex: 1,
      type: "number",
      valueFormatter: (value) => ToLocaleNumber(locale, value, 2)
    },
    {
      field: "consumptionAggregatedTax",
      headerName: gettext("Taxes (Øre/kWh)"),
      flex: 1,
      type: "number",
      valueFormatter: (value) => ToLocaleNumber(locale, value, 2)
    },
    {
      field: "consumptionCost",
      headerName: gettext("Cost (DKK)"),
      flex: 1,
      type: "number",
      valueFormatter: (value) => ToLocaleNumber(locale, value, 2)
    }
  ];

  const initialState = {
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 50
      }
    }
    // columns: {
    //   columnVisibilityModel: {
    //     id: false,
    //   }
    // }
  };
  //endregion For Table
  const handleGetSelectedValues = (selectedValues) => {

    if (selectedValues?.building?.value) {
      setBuilding(selectedValues.building.value);
    }

    if (selectedValues?.meter?.value) {
      setMeter(selectedValues.meter.value);
    }

    if (selectedValues?.energyType?.value) {
      setEnergyType(selectedValues.energyType.value);
    }

    if (selectedValues?.date?.startDate) {
      setFromDate(selectedValues.date.startDate.toISOString());
    }

    if (selectedValues?.date?.endDate) {
      setToDate(selectedValues.date.endDate.toISOString());
    }

    if (selectedValues?.segment) {
      setSegment(selectedValues.segment);
      setShortSegment(segments[selectedValues.segment].shortSegment);
      if (selectedValues.segment === "day" && moment(selectedValues?.date?.startDate).format("YYYY-MM-DD") !== moment(selectedValues?.date?.endDate).format("YYYY-MM-DD")) {
        setSegment("month");
        setShortSegment(segments["month"].shortSegment);
      }
    }

    if (selectedValues?.shortSegment?.value) {
      setShortSegment(selectedValues.shortSegment.value);
    }
  };

  return (
    <>
      <FilterBar1
        getSelectedValues={handleGetSelectedValues}
        showBuilding={true}
        showMeter={true}
        showEnergyType={true}
        showDate={true}
        showBuildingAll={true}
        showMeterAll={true}
        showEnergyTypeAll={false}
        showShortSegment={false}
      />
      <br/>

      {/*<LineChartBox*/}
      {/*  loading={loading}*/}
      {/*  title={gettext("Electricity measured in kWh")}*/}
      {/*  chartId={"price"}*/}
      {/*  data={{*/}
      {/*    dataset: chartData,*/}
      {/*    series: [*/}
      {/*      {*/}
      {/*        dataKey: "consumptionV",*/}
      {/*        label: gettext("Consumption"),*/}
      {/*        color: theme.palette.chart.electricity,*/}
      {/*        unit: gettext("kWh"),*/}
      {/*      },*/}
      {/*      {*/}
      {/*        dataKey: "consumptionPrice",*/}
      {/*        label: gettext("Price"),*/}
      {/*        color: theme.palette.chart.electricity,*/}
      {/*        unit: gettext("(Øre/kWh)")*/}
      {/*      },*/}
      {/*      {*/}

      {/*        dataKey: "consumptionAggregatedTax",*/}
      {/*        label: gettext("Tax"),*/}
      {/*        color: theme.palette.chart.electricity,*/}
      {/*        unit: gettext("(Øre/kWh)")*/}
      {/*      },*/}
      {/*      {*/}

      {/*        dataKey: "consumptionCost",*/}
      {/*        label: gettext("Cost"),*/}
      {/*        color: theme.palette.chart.electricity,*/}
      {/*        unit: gettext("DKK")*/}
      {/*      },*/}
      {/*    ]*/}
      {/*  }}*/}
      {/*/>*/}
      {/*<br/>*/}

      <LineChartBox
        loading={loading}
        title={gettext("Price in (Øre/kWh)")}
        chartId={"price"}
        data={{
          dataset: chartData,
          series: [{
            dataKey: "consumptionPrice",
            label: gettext("Price"),
            color: theme.palette.chart.electricity,
            unit: gettext("(Øre/kWh)")
          }]
        }}
      />
      <br/>

      <LineChartBox
        loading={loading}
        title={gettext("Taxes in (Øre/kWh)")}
        chartId={"tax"}
        data={{
          dataset: chartData,
          series: [{
            dataKey: "consumptionAggregatedTax",
            label: gettext("Tax"),
            color: theme.palette.chart.electricity,
            unit: gettext("(Øre/kWh)")
          }]
        }}
      />
      <br/>

      <LineChartBox
        loading={loading}
        title={gettext("Cost in DKK")}
        chartId={"cost"}
        data={{
          dataset: chartData,
          series: [{
            dataKey: "consumptionCost",
            label: gettext("Cost"),
            color: theme.palette.chart.electricity,
            unit: gettext("DKK")
          }]
        }}
      />
      <br/>

      <LineChartBox
        loading={loading}
        title={gettext("Consumption measured in kWh")}
        chartId={"consumption"}
        data={{
          dataset: chartData,
          series: [{
            dataKey: "consumptionV",
            label: gettext("Consumption"),
            color: theme.palette.chart.electricity,
            unit: gettext("kWh"),
          }]
        }}
      />
      <br/>

      <TableView1
        title={gettext("Electricity prices and cost")}
        rows={metersData}
        columns={columns}
        loading={loading}
        initialState={initialState}
        showGridToolbar
        showGridToolbarExport
        fileNameForExport={fileNameForExport}
      />
    </>
  );
}
