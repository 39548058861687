import { Box, Typography } from "@mui/material";
import { useTextDomainContext } from "../../../../providers";
import { useContext } from "react";

const NoResults = () => {
  const { TextDomainContext } = useTextDomainContext();
  const { gettext } = useContext(TextDomainContext);

  return (
    <Box
      rowGap={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minHeight: "420px",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <img src="/media/NoResult.png" alt="No Data Found" />
      <Typography
        sx={{
          fontWeight: "600",
          fontSize: "20px",
          lineHeight: "20px",
          color: "#015731"
        }}
      >
        {gettext("No Results Found!")}
      </Typography>
      <Typography
        sx={{
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "16px",
          color: "#52525B"
        }}
      >
        {gettext("We couldn't find what you searched for")}
      </Typography>
    </Box>
  );
};
export default NoResults;
