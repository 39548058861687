import React, {useContext} from "react";
import Logout from "@mui/icons-material/Logout";
import {useTextDomainContext} from "app/providers";
import {ProfileMenuItem} from "./components";

/**
 *
 */
export default function ProfileMenu() {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);

  return (
    <>
      {/* <ProfileMenuItem */}
      {/*   link={"/user-profile/personal-information"} */}
      {/*   icon={<PersonIcon />} */}
      {/*   label={gettext("Profile")} */}
      {/*   desc={gettext("My information")} */}
      {/*   code="my-profile" */}
      {/* /> */}

      {/* <ProfileMenuItem */}
      {/*   link={"/user-profile/change-password"} */}
      {/*   icon={<LockResetIcon />} */}
      {/*   label={gettext("Change Password")} */}
      {/*   desc={gettext("You can change your password here")} */}
      {/*   code="my-password" */}
      {/* /> */}

      {/* <ProfileMenuItem */}
      {/*   link={"/user-profile/customers"} */}
      {/*   icon={<PeopleAltIcon />} */}
      {/*   label={gettext("Companies")} */}
      {/*   desc={gettext("Companies")} */}
      {/*   code="my-customers" */}
      {/* /> */}

      {/* {CanDisplay("TEAM_SETTING") && ( */}
      {/*   <ProfileMenuItem */}
      {/*     link={"/user-profile/customer-roles"} */}
      {/*     icon={<SettingsAccessibilityIcon />} */}
      {/*     label={gettext("Company Roles")} */}
      {/*     desc={gettext( */}
      {/*       "Add, edit, delete users from the current organization" */}
      {/*     )} */}
      {/*   /> */}
      {/* )} */}

      {/* <ProfileMenuItem */}
      {/*   link={"/user-profile/settings"} */}
      {/*   icon={<Settings />} */}
      {/*   label={gettext("Settings")} */}
      {/*   desc={"You can change user color settings"} */}
      {/* /> */}

      <ProfileMenuItem
        link={"/logout"}
        icon={<Logout/>}
        label={gettext("Sign Out")}
        codes={["allow-to-all", "everything-full", "everything-readonly"]}
        desc={null}
      />
    </>
  );
}
