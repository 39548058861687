import {useMutation} from "@apollo/client";
import {useCallback} from "react";
import {UPDATE_SETTINGS} from "./commands";
import {enqueueSnackbar} from "notistack";

/**
 *
 * @param onCompleted
 * @param onError
 */
export function useUpdateSettings(
  onCompleted = () => null,
  onError = () => null) {
  const [updateSettings, {loading}] = useMutation(UPDATE_SETTINGS, {
    onCompleted: () => {
      onCompleted();
    },
    onError: (err) => {
      onError(err);

      enqueueSnackbar(
        err.message,
        {
          variant: "error"
        }
      );
    },
    refetchQueries: ["settings"],
    errorPolicy: "all"
  });

  const saveSettings = useCallback(
    async variables => {
      updateSettings({
        variables,
        refetchQueries: ["settings"]
      }).then();
    },
    [updateSettings]
  );

  return {
    saveSettings,
    loading
  };
}
