import {useEffect, useState} from "react";
import PropTypes from "prop-types";

const iconBaseUrl = "/media/file-icons/";
export const FileIcon = (props) => {
  const [type, setType] = useState("unknown");
  const [src, setSrc] = useState("");

  useEffect(() => {
    if (typeof props.type !== "undefined") {
      setType(props.type);
    }
  }, [props.type]);
  
  useEffect(() => {
    switch (type) {
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        setSrc(iconBaseUrl + "excel.svg");
        break;
      case "image/png":
        setSrc(iconBaseUrl + "png.svg");
        break;
      case "image/jpeg":
        setSrc(iconBaseUrl + "jpg.svg");
        break;
      case "text/csv":
        setSrc(iconBaseUrl + "csv.svg");
        break;
      case "unknown":
      default:
        setSrc(iconBaseUrl + "unknown.svg");
        break;
    }
  }, [type]);

  return (
    <img src={src} alt={type} width={5000} height={5000} style={{
      maxWidth: "100%",
      height: "auto",
    }}
    />
  );
};

FileIcon.propTypes = {
  type: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
};