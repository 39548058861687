import { UsersList } from "../../components";

export const DeveloperUsers = (props) => {
  return (
    <UsersList
      showLoginAs
      userFilter="all"
    />
  );
};
