import React, { useMemo, useRef, useState } from "react";
import Map, { FullscreenControl, GeolocateControl, Marker, NavigationControl, Popup, ScaleControl } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import { bbox, lineString } from "@turf/turf";
import { useCoordinates } from "./hooks";
import "mapbox-gl/dist/mapbox-gl.css";
import "./styles.css";

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const { REACT_APP_APP_MAPBOX_TOKEN } = process.env;

/**
 *
 */
export function MapBox() {
  const mapRef = useRef();
  const [showMarker, setShowMarker] = useState(false);

  const { coordiantesList } = useCoordinates(
    buildings => {
      const line = lineString(
        buildings.map(building => {
          return [building.coOrdinatesEpc.long, building.coOrdinatesEpc.lat];
        })
      );

      const [minLng, minLat, maxLng, maxLat] = bbox(line);

      setTimeout(() => {
        mapRef.current.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat]
          ],
          {
            padding: 100
          }
        );
        setShowMarker(true);
      }, 1500);
    },
    () => {
    }
  );

  const pins = useMemo(
    () =>
      coordiantesList.map((building, index) => (
        <div key={`marker-${building.building}-${index}`}>
          <Marker
            color="red"
            longitude={building.coOrdinatesEpc.long}
            latitude={building.coOrdinatesEpc.lat}
            anchor="bottom"
          />
          <Popup
            longitude={building.coOrdinatesEpc.long}
            latitude={building.coOrdinatesEpc.lat}
            style={{
              ".mapboxgl-popup-content": {
                backgroundColor: "red"
              }
            }}
            anchor="bottom"
            closeOnClick={false}
            closeButton={false}
            offset={60}
          >
            <div
              className="mapboxgl-custom-popup-content"
              style={{
                backgroundColor: building.coOrdinatesEpc.color || "white",
                color: building.coOrdinatesEpc.color ? "white" : "black"
              }}
            >
              {building.building}
              {building.coOrdinatesEpc.name && (
                <div className="epc-details">
                  <span>{building.coOrdinatesEpc.name}</span>
                  <span>
                    {Number(building.coOrdinatesEpc.value).toFixed(2)}
                  </span>
                </div>
              )}
              <div
                className="custom-tooltip"
                style={{
                  borderColor: `${building.coOrdinatesEpc.color ||
                  "white"} transparent transparent transparent`
                }}
              />
            </div>
          </Popup>
        </div>
      )),
    [coordiantesList]
  );

  return (
    <Map
      ref={mapRef}
      initialViewState={{
        longitude: -100,
        latitude: 40,
        zoom: 0,
        pitch: 60,
        bearing: -60
      }}
      style={{
        width: "100%", height: "80vh"
      }}
      mapboxAccessToken={REACT_APP_APP_MAPBOX_TOKEN}
      mapStyle="mapbox://styles/mapbox/streets-v11"
      projection="globe"
      fog={{
      }}
    >
      <GeolocateControl position="top-left" />
      <FullscreenControl position="top-left" />
      <NavigationControl position="top-left" />
      <ScaleControl />

      {showMarker && coordiantesList.length > 0 && pins}
    </Map>
  );
}
