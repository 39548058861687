import React, {useEffect} from 'react';
import {Box, IconButton, Modal} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from "prop-types";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  // p: 4,
};

export const ModalBox = (props) => {
  const [crossColor, setCrossColor] = React.useState("#000000");

  useEffect(() => {
    if (typeof props.crossColor !== "undefined") {
      setCrossColor(props.crossColor);
    }
  }, [props.crossColor]);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
      >
        <Box sx={{
          ...style,
          ...props.sx
        }}>
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            sx={{
              position: 'absolute',
              zIndex: 2,
              right: 5,
              top: 5,
              color: (theme) => crossColor,
            }}
          >
            <CloseIcon/>
          </IconButton>
          {props.children}
        </Box>
      </Modal>
    </div>
  );
};

ModalBox.propTypes = {
  sx: PropTypes.object,
  crossColor: PropTypes.string,
};