import {useQuery} from "@apollo/client";
import {GET_USER_COMPANIES} from "./commands";

export function useGetUserCompanies() {
  const {
    data,
    loading
  } = useQuery(GET_USER_COMPANIES, {
    variables: {
    },
    // fetchPolicy: "network-only",
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
  });

  return {
    companiesList: data?.getUserCompanies || [],
    loading
  };
}
