import {useMutation} from "@apollo/client";
import {useCallback, useState} from "react";
import {RESET_PASSWORD} from "./commands";

// import ReactGA from "react-ga4";
// import { useAuth } from "app/providers";

/**
 *
 * @param onCompleted
 * @param onError
 */
export function useResetPassword(onCompleted = () => null, onError = () => null) {
  // const { setToken } = useAuth();
  const [resetSuccess, setResetSuccess] = useState(null);
  const [comResetPassword, {loading}] = useMutation(RESET_PASSWORD, {
    onCompleted: ({comResetPassword}) => {
      if (comResetPassword.success === false) {
        setResetSuccess(false);
        throw Error(comResetPassword.message);
      }
      setResetSuccess(true);
      localStorage.clear();
    },
    onError: error => {
      setResetSuccess(false);
      onError(error);
    }
  });

  const submit = useCallback(
    async credentials => {
      try {
        await comResetPassword({
          variables: credentials
        });

        // ReactGA.event({
        //   category: "Authentication",
        //   action: "PasswordReset",
        //   label: credentials?.email.replace("@", "[at]")
        // });
      } catch (error) { /* empty */
      }
    },
    [comResetPassword]
  );

  return {
    submit,
    isSubmitting: loading,
    resetSuccess
  };
}
