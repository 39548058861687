import {publicIpv4} from "public-ip";
import axios from "axios";
import "../../app/types/GeoInfo";

/**
 * Gets the geographical information based on the public IP address.
 * @returns {Promise<GeoInfo|null>} The geographical information or null in case of an error.
 */
export const getGeoInfo = () => {
  return publicIpv4().then((ip) => {
    const ipApiKey = `ipApiKey-${ip}`;

    if (localStorage.getItem(ipApiKey) === null) {
      return axios
        .get(`https://ipapi.co/${ip}/json`)
        .then((res) => {
          /** @type {GeoInfo} */
          const data = res.data;
          localStorage.setItem(ipApiKey, JSON.stringify(data));
          return data;
        })
        .catch(() => {
          // TODO: Handle error
          return null;
        });
    } else {
      return JSON.parse(localStorage.getItem(ipApiKey));
    }
  });
};
