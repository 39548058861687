import { gql } from "@apollo/client";
import { SHADE_TYPE } from "../../GraphQL/Types";

export const ME_QUERY = gql`
  query ComMe {
    comMe {
        id
        name
        email
        customerKey
        lang
        profilePhotoPath
        profilePhotoUrl
        countryCode
        timezone
        isSuperAdmin
        roles
        isVerified
        isActive
        currentTeam
        permissions {
            role
            modules
        }
        partner {
            id
            key
            name
            companyUrl
            appUrl
            createdBy
            updatedBy
            createdAt
            updatedAt
        }
        currentTeamId
    }
}
`;

export const USE_GRAPH_COLOR = gql`
  query userGraphColor{
    userGraphColor{
      electricity{
        ${SHADE_TYPE}
      }
      heating{
        ${SHADE_TYPE}
      }
      water{
        ${SHADE_TYPE}
      }
      gas{
        ${SHADE_TYPE}
      }
      energy{
        ${SHADE_TYPE}
      }
      compensation{
        ${SHADE_TYPE}
      }
      production{
        ${SHADE_TYPE}
      }
      greenElectricity{
        ${SHADE_TYPE}
      }
      consumption{
        ${SHADE_TYPE}
      }
      co2 {
        ${SHADE_TYPE}
    }
    }
  }
`;
