import React, {useEffect, useState} from "react";
import {useUser} from "../../providers";
import PropTypes from "prop-types";
import {CustomerDropdown} from "./";
import {useUpdateProfile} from "../../../hooks";

/**
 * Customer dropdown component
 * @param {object} props props
 * @returns {React.JSX.Element} React Component
 * @class
 */
export const CustomerDropdownAutoSave = (props) => {
  const {updateProfile} = useUpdateProfile();
  const {user} = useUser();
  const {currentTeamId} = user || {
  };

  const [componentLabel, setComponentLabel] = useState(props.componentLabel ? props.componentLabel : "");

  useEffect(() => {
    if (typeof props.componentLabel === "undefined") {
      setComponentLabel("");
    } else {
      setComponentLabel(props.componentLabel);
    }
  }, [props.componentLabel]);

  const handleChange = (event) => {
    if (event.value !== currentTeamId) {
      updateProfile({
        currentTeam: event.label,
        currentTeamId: event.value
      });
    }
  };

  return (
    <CustomerDropdown
      onChange={handleChange}
      componentLabel={componentLabel}
    />
  );

};

CustomerDropdownAutoSave.propTypes = {
  componentLabel: PropTypes.string
};
