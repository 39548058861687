import {FilterBar1, TableView2, TableView3} from "../../components";
import {Box} from "@mui/system";
import {useEffect, useState} from "react";

const electricalTrade = [
  {
    id: 1,
    desc: "Spot (60%)",
    quantity: 423.6723434232,
    price: 51.517229,
    from: "2024-05-01T00:00:00",
    to: "2024-05-31T00:00:00",
    cost: 545.89,
    vat: 25
  },
  {
    id: 2,
    desc: "Spot supplement (60%)",
    quantity: 423.6723434232,
    price: 51.517229,
    from: "2024-05-01T00:00:00",
    to: "2024-05-31T00:00:00",
    cost: 545.89,
    vat: 25
  },
  {
    id: 3,
    desc: "Fixed price (40%)",
    quantity: 423.6723434232,
    price: 51.517229,
    from: "2024-05-01T00:00:00",
    to: "2024-05-31T00:00:00",
    cost: 545.89,
    vat: 25
  },
  {
    id: 4,
    desc: "Utility Regulator Fee",
    quantity: 423.6723434232,
    price: 51.517229,
    from: "2024-05-01T00:00:00",
    to: "2024-05-31T00:00:00",
    cost: 545.89,
    vat: 25
  },
];
const gridCost = [
  {
    id: 1,
    desc: "Discount on net tariff N1 A/S",
    quantity: 423.6723434232,
    price: 51.517229,
    from: "2024-05-01T00:00:00",
    to: "2024-05-31T00:00:00",
    cost: 545.89,
    vat: 25
  },
  {
    id: 2,
    desc: "Subscription to local grid supplier",
    quantity: 423.6723434232,
    price: 51.517229,
    from: "2024-05-01T00:00:00",
    to: "2024-05-31T00:00:00",
    cost: 545.89,
    vat: 25
  },
  {
    id: 3,
    desc: "Discount on subscription N1 A/S",
    quantity: 423.6723434232,
    price: 51.517229,
    from: "2024-05-01T00:00:00",
    to: "2024-05-31T00:00:00",
    cost: 545.89,
    vat: 25
  },
  {
    id: 4,
    desc: "Net tarif to local grid company",
    quantity: 423.6723434232,
    price: 51.517229,
    from: "2024-05-01T00:00:00",
    to: "2024-05-31T00:00:00",
    cost: 545.89,
    vat: 25
  },
];
const nationalTransmission = [
  {
    id: 1,
    desc: "Transmissions net tariff",
    quantity: 423.6723434232,
    price: 51.517229,
    from: "2024-05-01T00:00:00",
    to: "2024-05-31T00:00:00",
    cost: 545.89,
    vat: 25
  },
  {
    id: 2,
    desc: "System tariff",
    quantity: 423.6723434232,
    price: 51.517229,
    from: "2024-05-01T00:00:00",
    to: "2024-05-31T00:00:00",
    cost: 545.89,
    vat: 25
  },
  {
    id: 3,
    desc: "TSO - system subscription",
    quantity: 423.6723434232,
    price: 51.517229,
    from: "2024-05-01T00:00:00",
    to: "2024-05-31T00:00:00",
    cost: 545.89,
    vat: 25
  },
];
const electricityFee = [
  {
    id: 1,
    desc: " Electricity Fee",
    quantity: 423.6723434232,
    price: 51.517229,
    from: "2024-05-01T00:00:00",
    to: "2024-05-31T00:00:00",
    cost: 545.89,
    vat: 25
  },
];
const baseFee = [
  {
    id: 1,
    desc: "Base Fee",
    quantity: 423.6723434232,
    price: 51.517229,
    from: "2024-05-01T00:00:00",
    to: "2024-05-31T00:00:00",
    cost: 545.89,
    vat: 25
  },
];

const electricalTradeColumns = [
  {
    field: "desc",
    headerName: "Electrical Trade",
    flex: 1,
  },
  {
    field: "quantity",
    headerName: "Quantity (kWh)",
    type: "number",
    width: 150,
    valueFormatter: (value) => value && value.toFixed(2)
  },
  {
    field: "price",
    headerName: "Net Price (øre/kWh)",
    type: "number",
    summable: true,
    width: 150,
    valueFormatter: (value) => value && value.toFixed(2)
  },
  {
    field: "from",
    headerName: "From",
    // sortable: false,
    width: 150,
    type: "date",
    valueGetter: (value) => {
      let returnValue = "";
      if (value) {
        returnValue = new Date(value);
      }
      return returnValue;
    },
    // valueGetter: (params) =>
    //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
  },
  {
    field: "to",
    headerName: "To",
    // sortable: false,
    width: 150,
    type: "date",
    valueGetter: (value) => {
      let returnValue = "";
      if (value) {
        returnValue = new Date(value);
      }
      return returnValue;
    },
    // valueGetter: (params) =>
    //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
  },
  {
    field: "cost",
    headerName: "Net Cost (DKK)",
    // sortable: false,
    type: "number",
    width: 150,
    summable: true,
    valueFormatter: (value) => value && value.toFixed(2)
    // valueGetter: (params) =>
    //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
  },
  {
    field: "vat",
    headerName: "VAT",
    // sortable: false,
    type: "number",
    width: 100,
    valueFormatter: (value) => value && value.toFixed(2) + "%"
    // valueGetter: (params) =>
    //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
  }
];
const gridCostColumns = [
  {
    field: "desc",
    headerName: "Grid Cost",
    flex: 1,
  },
  {
    field: "quantity",
    headerName: "Quantity (kWh)",
    type: "number",
    width: 150,
    valueFormatter: (value) => value && value.toFixed(2)
  },
  {
    field: "price",
    headerName: "Net Price (øre/kWh)",
    type: "number",
    width: 150,
    valueFormatter: (value) => value && value.toFixed(2)
  },
  {
    field: "from",
    headerName: "From",
    // sortable: false,
    width: 150,
    type: "date",
    valueGetter: (value) => {
      let returnValue = "";
      if (value) {
        returnValue = new Date(value);
      }
      return returnValue;
    },
    // valueGetter: (params) =>
    //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
  },
  {
    field: "to",
    headerName: "To",
    // sortable: false,
    width: 150,
    type: "date",
    valueGetter: (value) => {
      let returnValue = "";
      if (value) {
        returnValue = new Date(value);
      }
      return returnValue;
    },
    // valueGetter: (params) =>
    //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
  },
  {
    field: "cost",
    headerName: "Net Cost (DKK)",
    // sortable: false,
    type: "number",
    width: 150,
    summable: true,
    valueFormatter: (value) => value && value.toFixed(2)
    // valueGetter: (params) =>
    //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
  },
  {
    field: "vat",
    headerName: "VAT",
    // sortable: false,
    type: "number",
    width: 100,
    valueFormatter: (value) => value && value.toFixed(2) + "%"
    // valueGetter: (params) =>
    //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
  }
];
const nationalTransmissionColumns = [
  {
    field: "desc",
    headerName: " National transmission system",
    flex: 1,
  },
  {
    field: "quantity",
    headerName: "Quantity (kWh)",
    type: "number",
    width: 150,
    valueFormatter: (value) => value && value.toFixed(2)
  },
  {
    field: "price",
    headerName: "Net Price (øre/kWh)",
    type: "number",
    width: 150,
    valueFormatter: (value) => value && value.toFixed(2)
  },
  {
    field: "from",
    headerName: "From",
    // sortable: false,
    width: 150,
    type: "date",
    valueGetter: (value) => {
      let returnValue = "";
      if (value) {
        returnValue = new Date(value);
      }
      return returnValue;
    },
    // valueGetter: (params) =>
    //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
  },
  {
    field: "to",
    headerName: "To",
    // sortable: false,
    width: 150,
    type: "date",
    valueGetter: (value) => {
      let returnValue = "";
      if (value) {
        returnValue = new Date(value);
      }
      return returnValue;
    },
    // valueGetter: (params) =>
    //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
  },
  {
    field: "cost",
    headerName: "Net Cost (DKK)",
    // sortable: false,
    type: "number",
    width: 150,
    summable: true,
    valueFormatter: (value) => value && value.toFixed(2)
    // valueGetter: (params) =>
    //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
  },
  {
    field: "vat",
    headerName: "VAT",
    // sortable: false,
    type: "number",
    width: 100,
    valueFormatter: (value) => value && value.toFixed(2) + "%"
    // valueGetter: (params) =>
    //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
  }
];
const electricityFeeColumns = [
  {
    field: "desc",
    headerName: "Electricity Fee (Potential compensation)",
    flex: 1,
  },
  {
    field: "quantity",
    headerName: "Quantity (kWh)",
    type: "number",
    width: 150,
    valueFormatter: (value) => value && value.toFixed(2)
  },
  {
    field: "price",
    headerName: "Net Price (øre/kWh)",
    type: "number",
    width: 150,
    valueFormatter: (value) => value && value.toFixed(2)
  },
  {
    field: "from",
    headerName: "From",
    // sortable: false,
    width: 150,
    type: "date",
    valueGetter: (value) => {
      let returnValue = "";
      if (value) {
        returnValue = new Date(value);
      }
      return returnValue;
    },
    // valueGetter: (params) =>
    //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
  },
  {
    field: "to",
    headerName: "To",
    // sortable: false,
    width: 150,
    type: "date",
    valueGetter: (value) => {
      let returnValue = "";
      if (value) {
        returnValue = new Date(value);
      }
      return returnValue;
    },
    // valueGetter: (params) =>
    //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
  },
  {
    field: "cost",
    headerName: "Net Cost (DKK)",
    // sortable: false,
    type: "number",
    width: 150,
    summable: true,
    valueFormatter: (value) => value && value.toFixed(2)
    // valueGetter: (params) =>
    //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
  },
  {
    field: "vat",
    headerName: "VAT",
    // sortable: false,
    type: "number",
    width: 100,
    valueFormatter: (value) => value && value.toFixed(2) + "%"
    // valueGetter: (params) =>
    //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
  }
];
const baseFeeColumns = [
  {
    field: "desc",
    headerName: "Base Fee",
    flex: 1,
  },
  {
    field: "quantity",
    headerName: "Quantity (kWh)",
    type: "number",
    width: 150,
    valueFormatter: (value) => value && value.toFixed(2)
  },
  {
    field: "price",
    headerName: "Net Price (øre/kWh)",
    type: "number",
    width: 150,
    valueFormatter: (value) => value && value.toFixed(2)
  },
  {
    field: "from",
    headerName: "From",
    // sortable: false,
    width: 150,
    type: "date",
    valueGetter: (value) => {
      let returnValue = "";
      if (value) {
        returnValue = new Date(value);
      }
      return returnValue;
    },
    // valueGetter: (params) =>
    //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
  },
  {
    field: "to",
    headerName: "To",
    // sortable: false,
    width: 150,
    type: "date",
    valueGetter: (value) => {
      let returnValue = "";
      if (value) {
        returnValue = new Date(value);
      }
      return returnValue;
    },
    // valueGetter: (params) =>
    //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
  },
  {
    field: "cost",
    headerName: "Net Cost (DKK)",
    // sortable: false,
    type: "number",
    width: 150,
    summable: true,
    valueFormatter: (value) => value && value.toFixed(2)
    // valueGetter: (params) =>
    //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
  },
  {
    field: "vat",
    headerName: "VAT",
    // sortable: false,
    type: "number",
    width: 100,
    valueFormatter: (value) => value && value.toFixed(2) + "%"
    // valueGetter: (params) =>
    //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
  }
];
const totalColumns = [
  {
    field: "desc",
    headerName: "Total",
    flex: 1,
  },
  {
    field: "cost",
    headerName: "Net Cost (DKK)",
    type: "number",
    width: 150,
    valueFormatter: (value) => value && value.toFixed(2)
  },
  {
    field: "vat",
    headerName: "VAT",
    type: "number",
    width: 100,
    valueFormatter: (value) => value && value.toFixed(2) + "%"
  }
];

export const Invoice = (props) => {

  const [totalRow, setTotalRow] = useState([]);

  useEffect(() => {
    let costTotal = 0;
    electricalTrade.map((obj) => {
      costTotal += obj.cost;
      return null;
    });

    gridCost.map((obj) => {
      costTotal += obj.cost;
      return null;
    });

    nationalTransmission.map((obj) => {
      costTotal += obj.cost;
      return null;
    });

    electricityFee.map((obj) => {
      costTotal += obj.cost;
      return null;
    });

    baseFee.map((obj) => {
      costTotal += obj.cost;
      return null;
    });
    
    setTotalRow([
      {
        id: 1,
        desc: "Total amount subject to VAT",
        quantity: "",
        price: "",
        from: "",
        to: "",
        cost: costTotal,
        vat: ""
      },
    ]);
  }, []);

  //#region Test region
  const handleSelectedValues = (selectedValues) => {
    // console.log(selectedValues);
  };

  //#endregion

  return (
    <>
      <FilterBar1 getSelectedValues={handleSelectedValues}/>
      <br/>
      <Box sx={{
        border: 1,
        borderColor: "gray.300",
        marginX: "9px"
      }}>
        <TableView2
          rows={electricalTrade}
          columns={electricalTradeColumns}
        />
        <br/>
        <br/>
        <TableView2
          rows={gridCost}
          columns={gridCostColumns}
        />
        <br/>
        <br/>
        <TableView2
          rows={nationalTransmission}
          columns={nationalTransmissionColumns}
        />
        <br/>
        <br/>
        <TableView2
          rows={electricityFee}
          columns={electricityFeeColumns}
        />
        <br/>
        <br/>
        <TableView2
          rows={baseFee}
          columns={baseFeeColumns}
        />
        <br/>
        <br/>
        <TableView3
          rows={totalRow}
          columns={totalColumns}
        />
      </Box>
    </>
  );
};