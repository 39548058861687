import {Button} from "@mui/material";

export const BlueButtonStyleHeading = (props) => {
  return (
    <Button sx={{
      backgroundColor: (theme) => theme.palette.primary[50],
      fontWeight: '700',
      borderRadius: 50,
      paddingX: "15px"
    }}>
      {props.children}
    </Button>
  );
};