import {useQuery} from "@apollo/client";
import {GET_ALL_PARTNERS} from "./commands";

/**
 *
 */
export function usePartnersList() {
  const {
    data,
    loading
  } = useQuery(GET_ALL_PARTNERS);
  
  return {
    partnerList: data?.getPartners.filter(item => item.key === "energiteam") || [],
    // partnerList: data?.getPartners || [],
    loading
  };
}
