import { RolesList } from "../../components";

export const SystemRoles = (props) => {

  return (
    <RolesList
      showPermissions
    />
  );
};
