import {Box} from "@mui/system";
import Tabs from "@mui/material/Tabs";
import {useContext, useState} from "react";
import {Tab} from "@mui/material";
import {useTextDomainContext} from "../../providers";
import {useLocation, useNavigate} from "react-router";
import {EnergyConsumption} from "./EnergyConsumption";

export const EnergyConsumptionFuture = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const navigate = useNavigate();

  const {hash} = useLocation();
  const hashValue = hash.substring(1);

  const [value, setValue] = useState(isNaN(Number(hashValue)) ? 0 : Number(hashValue));

  const CustomTabPanel = (props) => {
    const {
      children,
      value,
      index,
      ...other
    } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`consumption-tab-panel-${index}`}
        aria-labelledby={`consumption-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{
          p: 3
        }}>{children}</Box>}
      </div>
    );
  };

  const a11yProps = (index) => {
    return {
      id: `consumption-tab-${index}`,
      'aria-controls': `consumption-tab-panel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`#${newValue}`);
  };

  return (
    <>
      <Box sx={{
        borderBottom: 1,
        borderColor: 'divider'
      }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label={gettext("Simple")} {...a11yProps(0)} />
          <Tab label={gettext("Year")} {...a11yProps(1)} />
          <Tab label={gettext("Month")} {...a11yProps(2)} />
          <Tab label={gettext("Day")} {...a11yProps(3)} />
          <Tab label={gettext("Hour")} {...a11yProps(4)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <EnergyConsumption/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {gettext("Year Report - Under development")}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        {gettext("Month Report - Under development")}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        {gettext("Day Report - Under development")}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        {gettext("Hour Report - Under development")}
      </CustomTabPanel>
    </>
  );
};