import React, {useContext, useEffect, useState} from "react";
import {LinearProgress, styled} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import PropTypes from "prop-types";
import {Box} from "@mui/system";
import "./Styles/TableView1.css";
import {useTextDomainContext} from "../../providers";
import {TotalRow} from "../../../utils";

const exampleColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 70
  },
  {
    field: "firstName",
    headerName: "First name",
    width: 130
  },
  {
    field: "lastName",
    headerName: "Last name",
    width: 130
  },
  {
    field: "age",
    headerName: "Age",
    type: "number",
    width: 90
  },
  {
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.row.firstName || ""} ${params.row.lastName || ""}`
  }
];

export const TableView2 = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);

  const propsDefault = {
    columns: exampleColumns,
    rows: [],
    selectedIds: [],
    loading: false,
    checkboxSelection: false,
    pageSizeOptions: [5, 10, 50, 100, 500],
    preToolbar: "",
    postToolbar: "",
    beforeTable: <></>,
    afterTable: <></>,
    headerRight: <></>,
    showGridToolbar: false,
    showGridToolbarColumnsButton: false,
    showGridToolbarFilterButton: false,
    showGridToolbarDensitySelector: false,
    showGridToolbarExport: false,
    showGridToolbarQuickFilter: false,
    showGridToolbarDeleteButton: false,
    initialState: {
      pagination: "disabled"
    }
  };

  //region useState [React Hooks] (useState)
  const [columns, setColumns] = useState(props.columns ? props.columns : propsDefault.columns);
  const [rows, setRows] = useState(props.rows ? props.rows : propsDefault.rows);
  const [selectedIds, setSelectedIds] = useState(props.selectedIds ? props.selectedIds : propsDefault.selectedIds);
  const [isTotal, setIsTotal] = useState(false);
  const [totalRow, setTotalRow] = useState([]);
  //endregion useState

  //region useEffect [React Hooks] (useEffect)
  useEffect(() => {
    if (typeof props.columns !== "undefined") {
      setColumns(props.columns);
    }
  }, [props.columns]);

  useEffect(() => {
    if (typeof props.rows !== "undefined") {
      if (isTotal && totalRow.length) {
        setRows([
          ...props.rows,
          ...totalRow
        ]);
      } else {
        setRows(props.rows);
      }

    }
  }, [isTotal, props.rows, totalRow]);

  useEffect(() => {
    if (typeof props.selectedIds === "object") {
      setSelectedIds(props.selectedIds);
    }
  }, [props.selectedIds]);

  useEffect(() => {
    if (columns && columns.length) {

      columns.map((obj, index) => {
        if (obj.summable) {
          setIsTotal(true);
        }
        return null;
      });
    }
  }, [columns]);

  useEffect(() => {
    if (props?.rows?.length && isTotal) {
      setTotalRow(TotalRow(props?.rows, props.columns));
    }
  }, [isTotal, props.columns, props.rows]);

  //endregion useEffect

  const handleOnRowSelectionModelChange = (ids) => {
    if (props.checkboxSelection) {
      setSelectedIds(ids);
      if (typeof props.onRowSelectionModelChange === "function") {
        props.onRowSelectionModelChange(ids);
      }
    }
  };

  // const CustomToolbar = () => {
  //   return (
  //     showGridToolbar && <GridToolbarContainer>
  //       <Grid
  //         container
  //         alignItems="center"
  //         paddingTop={3}
  //         paddingBottom={3}
  //       >
  //         {/* Left Column */}
  //         <Grid item xs={6}>
  //           <Grid style={{
  //             textAlign: "left",
  //             paddingLeft: "10px"
  //           }}>
  //             {title && <Typography variant={"h4"}>
  //               {title}
  //             </Typography>}
  //           </Grid>
  //         </Grid>
  //
  //         {/* Right Column */}
  //         <Grid item xs={6}>
  //           <Grid container spacing={5} style={{
  //             textAlign: "right", paddingRight: "10px"
  //           }}>
  //             {preToolbar}
  //             {showGridToolbarQuickFilter && <GridToolbarQuickFilter />}
  //             {showGridToolbarColumnsButton && <GridToolbarColumnsButton />}
  //             {showGridToolbarFilterButton && <GridToolbarFilterButton />}
  //             {showGridToolbarDensitySelector && <GridToolbarDensitySelector />}
  //             {showGridToolbarExport && <GridToolbarExport />}
  //             {showGridToolbarDeleteButton && <Button
  //               startIcon={<DeleteIcon />}
  //               onClick={() => {
  //                 if (typeof props.onClickDeleteMultiple === "function") {
  //                   props.onClickDeleteMultiple(selectedIds);
  //                 }
  //               }}
  //               disabled={disabledDeleteButton}
  //               sx={{
  //                 color: "error.main"
  //               }}
  //             >
  //               {gettext("Delete")}
  //             </Button>}
  //             {postToolbar}
  //             {headerRight}
  //           </Grid>
  //         </Grid>
  //       </Grid>
  //     </GridToolbarContainer>
  //   );
  // };

  const StyledGridOverlay = styled("div")(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626"
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959"
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343"
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c"
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff"
    }
  }));

  const CustomNoRowsOverlay = () => {
    return (
      <StyledGridOverlay>
        {/* <svg */}
        {/*   style={{ flexShrink: 0 }} */}
        {/*   width="240" */}
        {/*   height="200" */}
        {/*   viewBox="0 0 184 152" */}
        {/*   aria-hidden */}
        {/*   focusable="false" */}
        {/* > */}
        {/*   <g fill="none" fillRule="evenodd"> */}
        {/*     <g transform="translate(24 31.67)"> */}
        {/*       <ellipse */}
        {/*         className="ant-empty-img-5" */}
        {/*         cx="67.797" */}
        {/*         cy="106.89" */}
        {/*         rx="67.797" */}
        {/*         ry="12.668" */}
        {/*       /> */}
        {/*       <path */}
        {/*         className="ant-empty-img-1" */}
        {/*         d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z" */}
        {/*       /> */}
        {/*       <path */}
        {/*         className="ant-empty-img-2" */}
        {/*         d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z" */}
        {/*       /> */}
        {/*       <path */}
        {/*         className="ant-empty-img-3" */}
        {/*         d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z" */}
        {/*       /> */}
        {/*     </g> */}
        {/*     <path */}
        {/*       className="ant-empty-img-3" */}
        {/*       d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z" */}
        {/*     /> */}
        {/*     <g className="ant-empty-img-4" transform="translate(149.65 15.383)"> */}
        {/*       <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" /> */}
        {/*       <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" /> */}
        {/*     </g> */}
        {/*   </g> */}
        {/* </svg> */}
        <Box sx={{
          mt: 1
        }}>{gettext("No Rows")}</Box>
      </StyledGridOverlay>
    );
  };

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={props.initialState || propsDefault.initialState}
        autoHeight
        loading={props.loading || propsDefault.loading}
        sx={{
          fontSize: "0.875rem",
          "--DataGrid-overlayHeight": "300px",
          "&, [class^=MuiDataGrid-main]": {
            // border: "1px solid"
          },
          "&, [class^=MuiDataGrid-root]": {
            // border: "none"
          },
          ".MuiDataGrid-columnSeparator": {
            display: "none"
          },
          ".MuiDataGrid-columnHeader": {
            backgroundColor: "grey.50",
            color: "grey.500"
          },
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold"
            // overflow: "visible"
          },
          '&>.MuiDataGrid-main': {
            '&>.MuiDataGrid-columnHeaders': {
              borderBottom: 'none',
              borderTop: 'none',
            },

            '& div div div div:not(:last-child) >.MuiDataGrid-cell': {
              ...(isTotal && {
                borderBottom: 'none',
                borderTop: 'none',
              })
            },

            '& div div div div >.MuiDataGrid-cell': {
              ...(!isTotal && {
                borderBottom: 'none',
                borderTop: 'none',
              })
            },

            '& div div div div:last-child >.MuiDataGrid-cell': {
              ...(isTotal && {
                fontWeight: 'bold',
              })
            },

          }
        }}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          loadingOverlay: LinearProgress
        }}
        rowSelectionModel={selectedIds}
        onRowSelectionModelChange={handleOnRowSelectionModelChange}
        hideFooter
        disableColumnMenu
        disableColumnSorting
      />
    </>
  );
};

TableView2.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array,
  checkboxSelection: PropTypes.bool,
  pageSizeOptions: PropTypes.array,
  initialState: PropTypes.object,
  loading: PropTypes.bool,
  preToolbar: PropTypes.element,
  postToolbar: PropTypes.element,
  beforeTable: PropTypes.element,
  afterTable: PropTypes.element,
  headerRight: PropTypes.element,
  selectedIds: PropTypes.array,
  onRowSelectionModelChange: PropTypes.func,
  onClickDeleteMultiple: PropTypes.func,
  showGridToolbar: PropTypes.bool,
  showGridToolbarColumnsButton: PropTypes.bool,
  showGridToolbarFilterButton: PropTypes.bool,
  showGridToolbarDensitySelector: PropTypes.bool,
  showGridToolbarExport: PropTypes.bool,
  showGridToolbarQuickFilter: PropTypes.bool,
  showGridToolbarDeleteButton: PropTypes.bool,
  fileNameForExport: PropTypes.string
};
