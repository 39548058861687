import { gql } from "@apollo/client";

export const CUSTOMERS = gql`
  query ComCustomers ($customerId: String) {
    comCustomers(customerId: $customerId) {
        address
        allowedBuildings
        autoId0
        city
        country
        customerKey
        cvr
        deleted
        id
        label
        name
        personalTeam
        phone
        phone2
        pnumber
        sef
        type
        userId
        users
        zip
    }
}

`;

