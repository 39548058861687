import { useMutation } from "@apollo/client";
import { VERIFY_USER_TOKEN } from "../commands";
import { useCallback, useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";

// export function useVerifyUserByToken(
//   onCompleted = () => {},
//   onError = () => {}
// ) {
//   const [comVerifyUserByToken, { loading }] = useMutation(VERIFY_USER_TOKEN, {
//     onCompleted: () => {
//       onCompleted();
//     },
//     onError: () => {
//       onError();
//     }
//   });
//   const verify = useCallback(
//     ({ token }) => {
//       comVerifyUserByToken({
//         variables: {
//           token: token?.replaceAll(" ", "+")
//           // token: token?.replaceAll(" ", "+")
//         }
//       });
//     },
//     [comVerifyUserByToken]
//   );

//   return {
//     verify,
//     loading
//   };
// }
/**
 *
 * @param onCompleted
 * @param onError
 */
export function useVerifyUserByToken(
  onCompleted = () => null,
  onError = () => null
) {
  const [isConfirmed, setIsConfirmed] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const [comVerifyUserByToken, { loading }] = useMutation(VERIFY_USER_TOKEN, {
    onCompleted: ({ comVerifyUserByToken }) => {
      if (comVerifyUserByToken.success === true) {
        setIsConfirmed(true);
        setEmail(comVerifyUserByToken.email);
      } else if (comVerifyUserByToken.success === false) {
        setIsConfirmed(false);
        enqueueSnackbar(comVerifyUserByToken.message, {
          variant: "error"
        });
        navigate("/auth/login");
      }
    },
    onError: error => {
      setIsConfirmed(false);
      onError(error);
    }
  });

  const verify = useCallback(
    token => {
      comVerifyUserByToken({
        variables: {
          token: token?.replaceAll(" ", "+")
          // token: token?.replaceAll(" ", "+")
        }
      });
    },
    [comVerifyUserByToken]
  );

  return {
    verify,
    loading,
    isConfirmed,
    email
  };
}
