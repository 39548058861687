import React from "react";
// // import { Button, Modal, Spinner } from "react-bootstrap";
// import {Button, Modal} from "@mui/material";
// import {useTextDomainContext} from "app/providers";

/**
 *
 * @param root0
 * @param root0.show
 * @param root0.onHide
 * @param root0.loading
 * @param root0.confirmationText
 * @param root0.onConfirmed
 */
export function DeleteConfirmation({
  show,
  onHide,
  loading,
  confirmationText,
  onConfirmed
}) {
  // const {TextDomainContext} = useTextDomainContext();
  // const {gettext} = useContext(TextDomainContext);
  // const handleDelete = useCallback(async () => {
  //   onConfirmed();
  // }, [onConfirmed]);

  return (
    <></>
    // <Modal
    //   show={show}
    //   onHide={onHide}
    //   animation
    //   size="lg"
    //   aria-labelledby="contained-modal-title-vcenter"
    //   centered
    // >
    //   <Modal.Header closeButton>
    //     <Modal.Title>{gettext("Confirmation")}</Modal.Title>
    //   </Modal.Header>
    //   <Modal.Body>{confirmationText}</Modal.Body>
    //   <Modal.Footer>
    //     <Button variant="secondary" onClick={onHide}>
    //       {gettext("Close")}
    //     </Button>
    //     {/*{loading ? (*/}
    //     {/*  <Spinner animation="border" variant="dark" />*/}
    //     {/*) : (*/}
    //     <Button variant="primary" onClick={handleDelete}>
    //       {gettext("Delete")}
    //     </Button>
    //     {/*)}*/}
    //   </Modal.Footer>
    // </Modal>
  );
}
