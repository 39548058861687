import React, {useContext, useEffect, useMemo, useState} from "react";
import {useTextDomainContext, useUser} from "../../providers";
import {useLocation, useNavigate} from "react-router";
import {Button, IconButton, Tooltip} from "@mui/material";
import {FaUserEdit} from "react-icons/fa";
import {MdDelete, MdLogin, MdPassword} from "react-icons/md";
import {TableView1} from "../Table";
import PropTypes from "prop-types";
import {useCompanyUsersList, usePartnerUsersList, useSystemUsersList, useUsersList} from "../../../hooks";
import {AddUser} from "./AddUser";
import {isEqual} from "lodash";
import {HiMiniBuildingOffice2} from "react-icons/hi2";
import {ChangePassword} from "./";

// import DeleteModel from "./components/DeleteModel";
// import LoginModel from "./components/LoginModel";
// import UserStatusFilter from "./UserStatusFilter";
// import EditModal from "./components/EditModal";

export const UsersList = (props) => {
  //region Loading data
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const navigate = useNavigate();
  const location = useLocation();
  const {user} = useUser();

  const partnerId = useMemo(() => {
    let id = "";
    if (user.partner.id) {
      id = user.partner.id;
    }
    return id;
  }, [user.partner.id]);

  const partnerName = useMemo(() => {
    let name = "";
    if (user.partner.name) {
      name = user.partner.name;
    }
    return name;
  }, [user.partner.name]);

  const companyId = useMemo(() => {
    let id = "";
    if (user.currentTeamId) {
      id = user.currentTeamId;
    }
    return id;
  }, [user.currentTeamId]);

  const companyName = useMemo(() => {
    let name = "";
    if (user.currentTeam) {
      name = user.currentTeam;
    }
    return name;
  }, [user.currentTeam]);

  const {
    usersList: allUsers,
    loading: loadingAllUsers
  } = useUsersList();
  const {
    usersList: systemUsers,
    loading: loadingSystemUsers
  } = useSystemUsersList();
  const {
    usersList: partnerUsers,
    loading: loadingPartnerUsers
  } = usePartnerUsersList(partnerId);
  const {
    usersList: companyUsers,
    loading: loadingCompanyUsers
  } = useCompanyUsersList(companyId);
  //endregion Loading data

  //region React Hook (useState)
  // const [statusFilter, setStatusFilter] = useState("Active");
  // const [editableRow, seteditableRow] = useState({
  // });
  // const [delete_show, setdelete] = useState(false);
  const [addFormOpen, setAddFormOpen] = useState(false);
  const [changePasswordFormOpen, setChangePasswordFormOpen] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(gettext("Users"));
  const [editRow, setEditRow] = useState({
  });
  // const [toastList, updateToastList] = useState([]);
  // const [edit_show, setedit] = useState(false);
  // const [chpass_show, setchpass] = useState(false);
  // const [login_show, setlogin] = useState(false);

  //endregion React Hook (useState)

  //region React Hook (useEffect)
  useEffect(() => {
    switch (props.userFilter) {
      case "all":
        setTitle(gettext("All Users"));
        if (!isEqual(usersList, allUsers)) {
          setLoading(loadingAllUsers);
          setUsersList(allUsers);
        }
        break;
      case "system":
        setTitle(gettext("System Users"));
        if (!isEqual(usersList, systemUsers)) {
          setLoading(loadingSystemUsers);
          setUsersList(systemUsers);
        }
        break;
      case "partner":
        setTitle(gettext("Partner Users for ") + partnerName);
        if (!isEqual(usersList, partnerUsers)) {
          setLoading(loadingPartnerUsers);
          setUsersList(partnerUsers);
        }
        break;
      case "company":
        setTitle(gettext("Company Users for ") + companyName);
        if (!isEqual(usersList, companyUsers)) {
          setLoading(loadingCompanyUsers);
          setUsersList(companyUsers);
        }
        break;
      default:
        setUsersList([]);
        break;
    }
  }, [
    allUsers,
    companyName,
    companyUsers,
    gettext,
    loadingAllUsers,
    loadingCompanyUsers,
    loadingPartnerUsers,
    loadingSystemUsers,
    partnerName,
    partnerUsers,
    props.userFilter,
    systemUsers,
    usersList
  ]);
  //endregion React Hook (useEffect)

  // const { searchString } = props;
  // const handleClose_delete = () => setdelete(false);
  // const handleShow_delete = () => setdelete(true);
  // const handleOpen = () => setAddModalOpen(true);
  // const handleClose = () => setAddModalOpen(false);
  // const handleClose_edit = () => setedit(false);
  // const handleShow_edit = () => setedit(true);
  // const handleClose_login = () => setlogin(false);
  // const { usersList, loading } = useUsersList(searchString, statusFilter);
  // const handleClose_chpass = message => {
  //   if (message === "success") {
  //     updateToastList([
  //       {
  //         title: "Success",
  //         description: "Password updated successfully",
  //         type: "success"
  //       }
  //     ]);
  //   }
  //   setchpass(false);
  // };
  // const handleShow_chpass = () => setchpass(true);
  // const handleShow_login = () => setlogin(true);
  /**
   *
   * @param row
   */
  // function edit_functionality(row) {
  //   seteditableRow(row);
  //
  //   handleShow_edit();
  // }

  /**
   *
   * @param row
   */
  // function chpass_functionality(row) {
  //   seteditableRow(row);
  //
  //   handleShow_chpass();
  // }

  /**
   *
   * @param row
   */
  function login_functionality(row) {
    // seteditableRow(row);
    // handleShow_login();
  }

  /**
   *
   * @param row
   */
  function delete_functionality(row) {
    // seteditableRow(row);
    // handleShow_delete();
  }

  /**
   *
   * @param row
   */
  function roles_functionality(row) {
    navigate(`/system-admin/users/${row.id}/update-role`);
  }

  /**
   *
   * @param row
   */
  function customers_functionality(row) {
    navigate(`${location.pathname}/${row.id}/customers`, {
      state: {
        user: row
      }
    });
  }

  /**
   *
   * @param row
   * @param action
   */

  /**
   *
   * @param row
   * @param action
   */
  function performAction(row, action) {
    switch (action) {
      case "edit":
        handleEdit(row);
        break;
      case "delete":
        delete_functionality(row);
        break;
      case "roles":
        roles_functionality(row);
        break;
      case "customers":
        customers_functionality(row);
        break;
      case "change-password":
        handleChangePassword(row);
        // chpass_functionality(row);
        break;
      case "login":
        login_functionality(row);
        break;
      default:
        break;
    }
  }

  /**
   *
   * @param params
   */
  function columnFormatter(params) {
    return (
      <div className="w-100 d-flex justify-content-center">
        {!!props.showEdit && (
          <Tooltip title={gettext("Edit")} arrow>
            <IconButton onClick={() => performAction(params.row, "edit")}>
              <FaUserEdit size={16}/>
            </IconButton>
          </Tooltip>
        )}

        {!!props.showDeleteSingle && (
          <Tooltip title={gettext("Delete")} arrow>
            <IconButton onClick={() => performAction(params.row, "delete")}>
              <MdDelete size={16}/>
            </IconButton>
          </Tooltip>
        )}

        {!!props.showChangePassword && (
          <Tooltip title={gettext("Change Password")} arrow>
            <IconButton onClick={() => performAction(params.row, "change-password")}>
              <MdPassword size={16}/>
            </IconButton>
          </Tooltip>
        )}

        {/* {!restricted && ( */}
        {/*   <Tooltip title={gettext("Update Roles")} arrow> */}
        {/*     <IconButton onClick={() => performAction(params.row, "roles")}> */}
        {/*       <IoKeySharp size={16} /> */}
        {/*     </IconButton> */}
        {/*   </Tooltip> */}
        {/* )} */}

        {!!props.showCompanies && (
          <Tooltip title={gettext("Companies")} arrow>
            <IconButton onClick={() => performAction(params.row, "customers")}>
              <HiMiniBuildingOffice2 size={16}/>
              {/*<IoPersonSharp size={16} />*/}
            </IconButton>
          </Tooltip>
        )}

        {!!props.showLoginAs && (
          <Tooltip title={gettext("Login as")} arrow>
            <IconButton onClick={() => performAction(params.row, "login")}>
              <MdLogin size={16}/>
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  }

  const columns = [
    {
      field: "id",
      headerName: gettext("ID"),
      checkboxSelection: true,
      flex: 1 // Adjust this flex value as needed
    },
    {
      field: "name",
      headerName: gettext("Name"),
      headerAlign: "left",
      type: "string",
      flex: 1
    },
    {
      field: "email",
      headerName: gettext("Email"),
      headerAlign: "left",
      type: "string",
      flex: 1
    },
    {
      field: "roles",
      headerName: gettext("Role"),
      headerAlign: "left",
      type: "string",
      flex: 1
    },
    {
      field: "countryCode",
      headerName: gettext("Country"),
      headerAlign: "left",
      type: "string",
      flex: 1
    },
    {
      field: "timezone",
      headerName: gettext("Time Zone"),
      headerAlign: "left",
      type: "string",
      flex: 1
    },
    // {
    //   field: "status",
    //   headerName: gettext("Status"),
    //   headerAlign: "left",
    //   type: "string",
    //   flex: 1
    // },
    {
      field: "partnerName",
      headerName: gettext("Partner"),
      headerAlign: "left",
      type: "string",
      flex: 1
    },
    {
      field: "actions",
      headerName: gettext("Actions"),
      hideable: false,
      flex: 1,
      maxWidth: 150,
      renderCell: params => columnFormatter(params)
    }
  ];

  const initialState = {
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 10
      }
    },
    columns: {
      columnVisibilityModel: {
        id: false,
        name: true,
        email: true,
        role: false,
        country: false,
        timezone: false,
        status: true,
        partnerName: false
      }
    }
  };
  // const handleGetSelectedValues = (selectedValues) => {
  //
  // };
  const preToolbar = () => {
    return (
      <>
        {/* <Button variant="contained" component={Link} to={"/data/deals/add"}> */}
        {/*   {gettext("Add contract")} */}
        {/* </Button> */}
        {/* <Button disabled={disabledDeleteButton} variant="contained" color={"error"}> */}
        {/*   Delete */}
        {/* </Button> */}
      </>
    );
  };

  const postToolbar = () => {
    return (
      <>
        {props.showAdd && (
          <Button
            variant="contained"
            onClick={() => {
              setAddFormOpen(true);
            }}
            sx={{
              ml: 1
            }}>
            {gettext("Add User")}
          </Button>
        )}
      </>
    );
  };

  const handleCloseAddForm = (event) => {
    setAddFormOpen(false);
    setEditRow({
    });
  };

  const handleCloseChangePasswordForm = (event) => {
    setChangePasswordFormOpen(false);
    setEditRow({
    });
  };

  const handleEdit = (row) => {
    setEditRow(row);
    setAddFormOpen(true);
  };

  const handleChangePassword = (row) => {
    setEditRow(row);
    setChangePasswordFormOpen(true);
  };

  return (
    <>
      <TableView1
        title={title}
        rows={usersList}
        // rows={[]}
        columns={columns}
        loading={loading}
        initialState={initialState}
        preToolbar={preToolbar()}
        postToolbar={postToolbar()}
        showGridToolbar
        showGridToolbarExport={!!props.showExport}
        beforeTable={
          <>
            {/* <UserStatusFilter */}
            {/*   setStatusFilter={setStatusFilter} */}
            {/*   gettext={gettext} */}
            {/* /> */}
            <br/>
          </>
        }

      />
      <AddUser open={addFormOpen} handleClose={handleCloseAddForm} row={editRow}/>
      <ChangePassword open={changePasswordFormOpen} handleClose={handleCloseChangePasswordForm} row={editRow}/>
      {/* <EditModal */}
      {/*   show={edit_show} */}
      {/*   onHide={handleClose_edit} */}
      {/*   editableRow={editableRow} */}
      {/* /> */}
      {/* <EditModel
                show={edit_show}
                onHide={handleClose_edit}
                editableRow={editableRow}
            /> */}
      {/*<ChPassModel*/}
      {/*  show={chpass_show}*/}
      {/*  onHide={handleClose_chpass}*/}
      {/*  editableRow={editableRow}*/}
      {/*/>*/}
      {/* <DeleteModel */}
      {/*   show={delete_show} */}
      {/*   onHide={handleClose_delete} */}
      {/*   editableRow={editableRow} */}
      {/*   getTeamMembers={props.getTeamMembers} */}
      {/* /> */}
      {/* <LoginModel */}
      {/*   show={login_show} */}
      {/*   onHide={handleClose_login} */}
      {/*   editableRow={editableRow} */}
      {/* /> */}
      {/*<Toast toastList={toastList}/>*/}
    </>
  );
};

UsersList.propTypes = {
  showAdd: PropTypes.bool,
  showExport: PropTypes.bool,
  showEdit: PropTypes.bool,
  showDeleteSingle: PropTypes.bool,
  showChangePassword: PropTypes.bool,
  showCompanies: PropTypes.bool,
  showLoginAs: PropTypes.bool,
  userFilter: PropTypes.oneOf(["all", "system", "partner", "company"]).isRequired
};
