/**
 *
 * @param theme
 */
export default function AlertTitle(theme) {
  return {
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: "600",
          lineHeight: 1
        }
      }
    }
  };
}
