import {TableLoadingStateEnergyStatement, TableView1} from "../../components";
import React, {useContext, useEffect, useState} from "react";
import {useLang, useTextDomainContext} from "../../providers";
import * as PropsType from "prop-types";
import {ToLocaleNumber} from "../../../utils";

export const EnergyOverallStatement = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const locale = useLang();
  // const {user} = useUser();
  // const {currentTeam: customer} = user || {
  // };
  // const loading = false;
  // const filters = {
  //   meterType: props.meterType,
  //   customer: customer,
  //   year: props.year,
  // };
  //
  // const {
  //   data,
  //   loading
  // } = useConsumptionEnergyData(filters);

  const [fileNameForExport, setFileNameForExport] = useState("energy-overall-statement");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFileNameForExport(props.fileNameForExport);
  }, [props.fileNameForExport]);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  // useEffect(() => {
  //   if (data) {
  //     const columnsData = EnergyOverallColumns(data, gettext, locale, props.year);
  //     const rowsData = EnergyOverallRows(data, props.year);
  //     if (columnsData.length) {
  //       setColumns(columnsData);
  //     } else {
  //       setColumns([]);
  //     }
  //     if (rowsData.length) {
  //       setRows(rowsData);
  //     } else {
  //       setRows([]);
  //     }
  //   }
  // }, [data, gettext, locale, props.year]);


  const columns = [
    {
      field: "meter",
      headerName: gettext("Order No"),
      width: 200,
    },
    {
      field: "meterNumber",
      headerName: gettext("Meter No"),
      width: 150,
    },
    {
      field: "customer",
      headerName: gettext("Company"),
      width: 250,
    },
    // {
    //   field: "address",
    //   headerName: gettext("Address"),
    //   width: 300,
    // },
    {
      field: "streetName",
      headerName: gettext("Street"),
      width: 200,
    },
    {
      field: "buildingNumber",
      headerName: gettext("House No."),
      width: 100,
    },
    {
      field: "postcode",
      headerName: gettext("Zip Code"),
      width: 100,
    },
    {
      field: "cityName",
      headerName: gettext("City"),
      width: 150,
    },
    {
      field: "customerCVR",
      headerName: gettext("Company No."),
      width: 100,
    },

    {
      field: "total",
      headerName: gettext("Sum"),
      // flex: 1,
      width: 150,
      type: "number",
      valueFormatter: (value) => ToLocaleNumber(locale, value, 0),
    },
    {
      field: "jan",
      headerName: gettext("January"),
      // flex: 1,
      width: 100,
      type: "number",
      valueFormatter: (value) => ToLocaleNumber(locale, value, 0),
    },
    {
      field: "feb",
      headerName: gettext("February"),
      // flex: 1,
      width: 100,
      type: "number",
      valueFormatter: (value) => ToLocaleNumber(locale, value, 0),
    },
    {
      field: "mar",
      headerName: gettext("March"),
      // flex: 1,
      width: 100,
      type: "number",
      valueFormatter: (value) => ToLocaleNumber(locale, value, 0),
    },
    {
      field: "apr",
      headerName: gettext("April"),
      // flex: 1,
      width: 100,
      type: "number",
      valueFormatter: (value) => ToLocaleNumber(locale, value, 0),
    },
    {
      field: "may",
      headerName: gettext("May"),
      // flex: 1,
      width: 100,
      type: "number",
      valueFormatter: (value) => ToLocaleNumber(locale, value, 0),
    },
    {
      field: "jun",
      headerName: gettext("June"),
      // flex: 1,
      width: 100,
      type: "number",
      valueFormatter: (value) => ToLocaleNumber(locale, value, 0),
    },
    {
      field: "jul",
      headerName: gettext("July"),
      // flex: 1,
      width: 100,
      type: "number",
      valueFormatter: (value) => ToLocaleNumber(locale, value, 0),
    },
    {
      field: "aug",
      headerName: gettext("August"),
      // flex: 1,
      width: 100,
      type: "number",
      valueFormatter: (value) => ToLocaleNumber(locale, value, 0),
    },
    {
      field: "sep",
      headerName: gettext("September"),
      // flex: 1,
      width: 100,
      type: "number",
      valueFormatter: (value) => ToLocaleNumber(locale, value, 0),
    },
    {
      field: "oct",
      headerName: gettext("October"),
      // flex: 1,
      width: 100,
      type: "number",
      valueFormatter: (value) => ToLocaleNumber(locale, value, 0),
    },
    {
      field: "nov",
      headerName: gettext("November"),
      // flex: 1,
      width: 100,
      type: "number",
      valueFormatter: (value) => ToLocaleNumber(locale, value, 0),
    },
    {
      field: "dec",
      headerName: gettext("December"),
      // flex: 1,
      width: 100,
      type: "number",
      valueFormatter: (value) => ToLocaleNumber(locale, value, 0),
    },
  ];
  const initialState = {
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 50
      }
    }
    // columns: {
    //   columnVisibilityModel: {
    //     id: false,
    //   }
    // }
  };

  const handleChangeEnergyStatement = (obj) => {
    if (typeof props.onChangeEnergyStatement === "function") {
      props.onChangeEnergyStatement(obj);
    }
  };

  return (
    <>
      {data?.consumptionEnergyData?.aggregatedData && data?.consumptionEnergyData?.aggregatedData.length > 0 && (
        <TableView1
          title={gettext("Annual energy consumption (kWh)")}
          rows={data?.consumptionEnergyData?.aggregatedData}
          columns={columns}
          loading={loading}
          initialState={initialState}
          showGridToolbar
          showEnergyStatementType
          showGridToolbarExport
          showGridToolbarQuickFilter
          fileNameForExport={fileNameForExport}
          onChangeEnergyStatement={handleChangeEnergyStatement}
          selectedEnergyStatement="overall-statement"
        />
      )}
      {loading && (
        TableLoadingStateEnergyStatement()
        // gettext("Loading")
      )}
      {!loading && !data?.consumptionEnergyData && (
        TableLoadingStateEnergyStatement()
        // gettext("Still Loading")
      )}
      {!loading && data?.consumptionEnergyData?.aggregatedData && data?.consumptionEnergyData?.aggregatedData.length === 0 && (
        <>
          {gettext("No data found")}<br/>
          {gettext("If the data is heavy, it may still be loading.")}<br/>
        </>
      )}
    </>
  );
};

EnergyOverallStatement.propTypes = {
  onChangeEnergyStatement: PropsType.func,
};