import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { ListItemIcon, ListItemText } from "@mui/material";
import { usePagePrivileges } from "hooks/privileges";
import { isLocationActive } from "app/common/Methods";
import { alpha } from "@mui/material/styles";
import PropTypes from "prop-types";

/**
 *
 * @param props
 * @return {Element}
 * @constructor
 */
const Level1MenuItem = (props) => {
  const { link, label, icon, codes } = props;
  const location = useLocation();

  const allow = usePagePrivileges(codes);

  const [displayOption, setDisplayOption] = useState(false);

  const [activeColor, setActiveColor] = useState("");

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(isLocationActive(location, link));
  }, [location, link]);

  useEffect(() => {
    setActiveColor(isActive ? "primary.50" : "primary.100");
  }, [isActive]);

  useEffect(() => {
    if (allow) {
      setDisplayOption(true);
    } else {
      setDisplayOption(false);
    }
  }, [allow]);

  return (
    <>
      {displayOption && (
        <NavLink to={link} style={{
          textDecoration: "none"
        }}>
          <ListItem disablePadding sx={{
            color: activeColor
          }}>
            <ListItemButton
              disableGutters
              sx={{
                p: 1,
                borderRadius: "8px",
                backgroundColor: isActive
                  ? alpha("#abb8c3", 0.28)
                  : "transparent"
              }}
            >
              <ListItemIcon sx={{
                minWidth: "25px",
                color: activeColor
              }}>
                {icon}
              </ListItemIcon>
              <ListItemText primary={label} />
              {/* <Typography variant="div" color="inherit"> */}
              {/*   {label} */}
              {/* </Typography> */}
            </ListItemButton>
          </ListItem>
        </NavLink>
      )}
    </>
  );
};

Level1MenuItem.propTypes = {
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  codes: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default Level1MenuItem;
