import {UsersList} from "../../components";

export const PartnerUsers = (props) => {
  return (
    <UsersList
      // showAdd
      // showEdit
      // showDeleteSingle
      // showChangePassword
      // showCompanies
      userFilter="partner"
    />
  );
};
