import * as React from "react";
import {useContext, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import {ExportToCsv, ExportToExcel, ExportToPdf} from "../../../utils";
import {useTextDomainContext} from "../../providers";
import {TfiImport} from "react-icons/tfi";
import PropTypes from "prop-types";

export const ExportDropdown = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [data, setData] = useState(typeof props.data === "undefined" ? [] : props.data);
  const [fileName, setFileName] = useState(typeof props.fileName === "undefined" ? "export" : props.fileName);

  useEffect(() => {
    if (typeof props.data !== "undefined") {
      setData(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    if (typeof props.fileName !== "undefined") {
      setFileName(props.fileName);
    }
  }, [props.fileName]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportToExcel = () => {
    ExportToExcel(data, `${fileName}.xlsx`);
    handleClose();
  };

  const handleExportToCsv = () => {
    ExportToCsv(data, `${fileName}.csv`);
    handleClose();
  };

  const handleExportToPdf = () => {
    ExportToPdf(data, `${fileName}.pdf`);
    handleClose();
  };

  return (
    <>
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<TfiImport size={14}/>}
        disabled={!data?.length}
        sx={{
          ml: 1,
          color: "grey.600",
          fontWeight: 400,
        }}
      >
        {gettext("Export")}
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={handleExportToExcel}
          sx={{
            fontSize: "0.875rem",
            color: "grey.600",
            fontWeight: 300
          }}
        >
          {gettext("Export to Excel")}
        </MenuItem>
        <MenuItem
          onClick={handleExportToCsv}
          sx={{
            fontSize: "0.875rem",
            color: "grey.600",
            fontWeight: 300
          }}
        >
          {gettext("Export to CSV")}
        </MenuItem>
        <MenuItem
          onClick={handleExportToPdf}
          sx={{
            fontSize: "0.875rem",
            color: "grey.600",
            fontWeight: 300
          }}
        >
          {gettext("Export to PDF")}
        </MenuItem>
      </Menu>
    </>
  );
};

ExportDropdown.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  fileName: PropTypes.string
};
