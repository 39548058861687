import {useQuery} from "@apollo/client";
import {GET_USERS_LIST} from "./commands";

/**
 *
 * @param searchString
 * @param statusFilter
 */
// export function useUsersList(searchString, statusFilter) {
//   const { data, loading } = useQuery(GET_USERS_LIST, {
//     variables: {
//       searchString,
//       statusFilter
//     },
//     fetchPolicy: "no-cache"
//   });

//   return {
//     usersList: data?.comMyUsers || [],
//     loading
//   };
// }
export function useUsersList(searchString = "", statusFilter = "") {
  const {
    data,
    loading
  } = useQuery(GET_USERS_LIST, {
    variables: {
      searchString,
      statusFilter
    },
    // fetchPolicy: "no-cache",
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
  });

  // Extracting the necessary data and formatting it as needed
  const formattedData = data?.allUsers.map(user => ({
    id: user.id,
    name: user.name,
    email: user.email,
    countryCode: user.countryCode,
    timezone: user.timezone,
    roles: user.roles,
    lang: user.lang,
    companyId: user.companyId,
    partner: user.partner,
    // status: user.status,
    partnerName: user.partner ? user.partner.name : "N/A" // Accessing partner's name
  })) || [];

  return {
    usersList: formattedData,
    loading
  };
}
