import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { getOr } from "lodash/fp";
import { GET_CUSTOMERS_BUILDINGS } from "./commands";

/**
 *
 * @param customers
 */
export function useBuildingFilter(customers) {
  const [buildingCustomers, setBuildingCustomers] = useState(null);
  const [
    fetchCustomerData,
    { data: customerData, loading, refetch: reFetchCustomerData }
  ] = useLazyQuery(GET_CUSTOMERS_BUILDINGS, {
    variables: {
      customerName: customers
    }
  });

  useEffect(() => {
    if (customers) {
      fetchCustomerData();
      reFetchCustomerData();
    }
  }, [fetchCustomerData, reFetchCustomerData, customers]);

  useEffect(() => {
    if (!loading && customerData) {
      const customers = getOr([], "comCustomerAddresses", customerData);
      const data = [];
      customers.forEach(item => {
        data.push({
          value: item.address,
          label: item.address,
          electricity: item.electricity,
          heating: item.heating,
          water: item.water,
          gas: item.gas,
          energy: item.energy
        });
      });
      data.sort((a, b) => {
        if (a.value < b.value) return -1;
        if (a.value > b.value) return 1;
        return 0;
      });
      setBuildingCustomers(data);
    }
  }, [customerData, loading]);

  return {
    buildings: buildingCustomers,
    loading
  };
}
