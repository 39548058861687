import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {useTextDomainContext, useUser} from "../../providers";
import React, {useContext, useEffect, useState} from "react";
import {Link} from "@mui/material";
import {useGetUserCompanies} from "../../../hooks";
import {CountryTimezoneModal} from "../Modal";

export const TopNotifications = () => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  // const partnerKey = GetPartnerKey();
  const {
    companiesList,
    loading
  } = useGetUserCompanies();
  const {user} = useUser();
  const email = user?.email || "";
  const userId = user?.id || "";

  const [showDataHubInfo, setShowDataHubInfo] = useState(false);
  const [showCountryModal, setShowCountryModal] = useState(false);
  const [openTimezoneModal, setOpenTimezoneModal] = useState(true);
  const [customerKey, setCustomerKey] = useState("");

  useEffect(() => {
    if (!loading && user?.timezone && user?.countryCode === "DK") {
      if (companiesList?.length) {
        setShowDataHubInfo(false);
        // setShowDataHubInfo(true);
      } else {
        setShowDataHubInfo(true);
      }
    }
  }, [companiesList, loading, user, openTimezoneModal]);

  useEffect(() => {
    if (userId) {
      setCustomerKey(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (user) {
      if (user.countryCode && user.timezone) {
        setShowCountryModal(false);
      } else {
        setShowCountryModal(true);
      }
    }
  }, [user]);

  const handleDataHubAlertClose = () => {
    setShowDataHubInfo(false);
  };

  const handleCountryLinkClick = () => {
    setOpenTimezoneModal(true);
  };

  const handleTimezoneModalClose = () => {
    setOpenTimezoneModal(false);
    // window.location.reload();
  };

  return (
    <>
      {showDataHubInfo && (
        <Alert severity="info" onClose={handleDataHubAlertClose}>
          <AlertTitle>{gettext("Access is Required!")}</AlertTitle>
          {gettext("Please authorize us on")}&nbsp;
          <Link href={`/r/fb/${customerKey}/${email}`} target="_blank">
            {gettext("DataHub")}&nbsp;
          </Link>
          {gettext("or")}&nbsp;
          {gettext("contact us")}&nbsp;
          {gettext("for any queries.")}&nbsp;
        </Alert>
      )}

      {showCountryModal && (
        <>
          <Alert severity="info" onClose={handleDataHubAlertClose}>
            <AlertTitle>{gettext("Country and Timezone is Required!")}</AlertTitle>
            {gettext("To ensure the data is tailored to your local time, we require your")}&nbsp;
            <Link onClick={handleCountryLinkClick} sx={{
              cursor: 'pointer'
            }}>
              {gettext("country and timezone information")}&nbsp;
            </Link>
          </Alert>

          <CountryTimezoneModal open={openTimezoneModal} handleClose={handleTimezoneModalClose}/>
        </>
      )}
    </>
  );
};
