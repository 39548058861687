import {useTextDomainContext} from "../../providers";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {useDeleteUserCompany, useUserCompanies} from "../../../hooks";
import {TableView1} from "../Table/TableView1";
import {Button, IconButton, Tooltip} from "@mui/material";
import PropTypes from "prop-types";
import {MdDelete} from "react-icons/md";
import {ConfirmNotification} from "../Dialogs";
// import { Spinner } from "react-bootstrap";
import {AddUserCompany} from "./AddUserCompany";

export const UserCustomers = (props) => {
  const {uid: userId} = useParams();
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const navigate = useNavigate();
  // const { user } = useUser();
  // const location = useLocation();
  const {
    customers,
    userName,
    loading
  } = useUserCompanies(userId);

  const {
    deleteUserCompany,
    deleting
  } = useDeleteUserCompany();

  //region React Hook (useState)
  const [showAdd, setShowAdd] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [addFormOpen, setAddFormOpen] = useState(false);
  const [dialogParameters, setDialogParameters] = useState({
  });
  const [showConfirm, setShowConfirm] = useState(false);
  const [editRow, setEditRow] = useState({
  });
  //endregion React Hook (useState)

  //region React Hook (useEffect)
  useEffect(() => {
    setShowAdd(props.showAdd);
  }, [props.showAdd]);

  useEffect(() => {
    setShowDelete(props.showDelete);
  }, [props.showDelete]);
  //endregion React Hook (useEffect)

  //region Handlers
  const handleOnClickDeleteMultiple = (ids) => {
    setDialogParameters({
      action: "delete-multi",
      ids: ids
    });
    setShowConfirm(true);
  };

  const handleOnClickDeleteSingle = (row) => {
    setDialogParameters({
      action: "delete-single",
      customerName: row.name,
      userId: userId
    });
    setShowConfirm(true);
  };

  const handleGetResult = (obj) => {
    if (obj.result === "yes") {
      deleteUserCompany({
        userId: obj?.parameters?.userId,
        customerName: obj?.parameters?.customerName
      });
    }
    setShowConfirm(false);
  };

  const handleCloseAddForm = (event) => {
    setAddFormOpen(false);
    setEditRow({
    });
  };

  // const handleEdit = (row) => {
  //   setEditRow(row);
  //   setAddFormOpen(true);
  // };

  const performAction = (row, action) => {
    switch (action) {
      case "delete":
        handleOnClickDeleteSingle(row);
        break;
      default:
        break;
    }
  };
  //endregion Handlers

  //region Table Settings
  const postToolbar = () => {
    return (
      <>
        <Button variant="contained" color={"secondary"} onClick={() => {
          navigate(-1);
        }} sx={{
          mx: 1
        }}>
          {gettext("Back")}
        </Button>
        {showAdd && (
          <Button variant="contained" onClick={() => {
            setAddFormOpen(true);
          }}>
            {gettext("Add Customer")}
          </Button>
        )}
      </>
    );
  };

  const columnFormatter = (params) => {
    return (
      <div className="w-100 d-flex justify-content-center">
        {showDelete && (
          <Tooltip title={gettext("Delete")} arrow>
            <IconButton onClick={() => performAction(params.row, "delete")}>
              <MdDelete size={16}/>
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  };

  const columns = [
    {
      field: "id",
      headerName: gettext("ID"),
      checkboxSelection: true
    },
    {
      field: "name",
      headerName: gettext("Name"),
      flex: 1,
      // minWidth: 150,
      headerAlign: "left",
      type: "string"
    },
    {
      field: "label",
      headerName: gettext("Label"),
      flex: 1,
      // minWidth: 150,
      headerAlign: "left",
      type: "string"
    },
    {
      field: "actions",
      headerName: gettext("Actions"),
      hideable: false,
      minWidth: 120,
      maxWidth: 200,
      // flex: 1,
      // minWidth: 80,
      // maxWidth: 80,
      // headerAlign: "center",
      // type: "actions",
      renderCell: params => columnFormatter(params)
    }
  ];

  const initialState = {
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 50
      }
    },
    columns: {
      columnVisibilityModel: {
        id: false,
        name: true,
        label: true
      }
    }
  };
  //endregion Table Settings

  return (
    <>
      <TableView1
        title={gettext("Companies for ") + userName}
        rows={customers}
        // rows={[]}
        columns={columns}
        loading={loading}
        checkboxSelection={false}
        initialState={initialState}
        // preToolbar={preToolbar()}
        postToolbar={postToolbar()}
        // headerRight={headerRight()}
        showGridToolbar={true}
        showGridToolbarDeleteButton={false}
        showGridToolbarDensitySelector={false}
        showGridToolbarColumnsButton={false}
        showGridToolbarQuickFilter={false}
        showGridToolbarFilterButton={false}
        showGridToolbarExport={false}
        // onRowSelectionModelChange={handleOnRowSelectionModelChange}
        onClickDeleteMultiple={handleOnClickDeleteMultiple}
      />

      <AddUserCompany open={addFormOpen} handleClose={handleCloseAddForm} row={editRow}/>

      <ConfirmNotification
        isOpene={showConfirm}
        parameters={dialogParameters}
        getResult={handleGetResult}
      />
      {deleting ? (
        <div
          className="position-absolute bg-dark opacity-50 w-100 h-100 d-flex justify-content-center align-items-center">
          {/*<Spinner animation="border" variant="light" />*/}
        </div>
      ) : null}
    </>
  );
};

UserCustomers.propTypes = {
  showAdd: PropTypes.bool,
  showDelete: PropTypes.bool
};
