import {useQuery} from "@apollo/client";
import {GET_PARTNER_USERS_LIST} from "./commands";

/**
 *
 * @param partnerId String
 * @return {{usersList: (*|*[]), loading: boolean}}
 */
export function usePartnerUsersList(partnerId) {
  const {
    data,
    loading
  } = useQuery(GET_PARTNER_USERS_LIST, {
    variables: {
      partnerId
    },
    // fetchPolicy: "no-cache",
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
  });

  // Extracting the necessary data and formatting it as needed
  const formattedData = data?.partnerUsers.map(user => ({
    id: user.id,
    name: user.name,
    email: user.email,
    countryCode: user.countryCode,
    timezone: user.timezone,
    roles: user.roles,
    lang: user.lang,
    companyId: user.companyId,
    partner: user.partner,
    // status: user.status,
    partnerName: user.partner ? user.partner.name : "N/A" // Accessing partner's name
  })) || [];

  return {
    usersList: formattedData,
    loading
  };
}
