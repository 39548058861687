import {Button} from "@mui/material";

export const OutlinedButton = (props) => {
  const sx = {
    // backgroundColor: "common.black",
    color: "common.black",
    borderColor: "grey.200",
    borderRadius: 1,
    // py: 1,
    // px: 3,
  };

  // console.log(props);

  return (
    <Button
      {...props}
      variant="outlined"
      sx={{
        ...sx,
        ...props.sx
      }}
      // onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};

OutlinedButton.propTypes = {
  // children: PropTypes.string,
  // sx: PropTypes.object,
  // onClick: PropTypes.func,
};