import {Button, Grid, TextField, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {useTextDomainContext} from "../../providers";
import {Box} from "@mui/system";
import {useGetVersion, useUpdateSettings} from "../../../hooks";
import {enqueueSnackbar} from "notistack";

export const DeveloperVersion = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const {settings} = useGetVersion();
  const {saveSettings} = useUpdateSettings(
    () => {
      enqueueSnackbar(gettext("The deal has updated"), {
        variant: "success"
      });
    },
    error => {
      if (error === "410") {
        enqueueSnackbar(gettext("You are not authorized to perform this action"), {
          variant: "error"
        });
      } else {
        enqueueSnackbar(error, {
          variant: "error"
        });
      }
    }
  );

  const [major, setMajor] = useState(0);

  const [minor, setMinor] = useState(0);

  const [release, setRelease] = useState(0);

  const [updatedVersion, setUpdatedVersion] = useState(`${major}.${minor}.${release}`);

  useEffect(() => {
    if (typeof settings?.major !== "undefined") {
      setMajor(settings.major);
    }
  }, [settings.major]);

  useEffect(() => {
    if (typeof settings?.minor !== "undefined") {
      setMinor(settings.minor);
    }
  }, [settings.minor]);

  useEffect(() => {
    if (typeof settings?.release !== "undefined") {
      setRelease(settings.release);
    }
  }, [settings.release]);

  useEffect(() => {
    setUpdatedVersion(`${settings.major}.${settings.minor}.${settings.release}`);
  }, [settings.major, settings.minor, settings.release]);

  const handleChangeMajor = (event) => {
    setMajor(event.target.value);
  };

  const handleChangeMinor = (event) => {
    setMinor(event.target.value);
  };

  const handleChangeRelease = (event) => {
    setRelease(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const variables = {
      key: "version",
      data: JSON.stringify({
        major,
        minor,
        release
      })
    };

    saveSettings(variables).then(() => {
      setUpdatedVersion(`${major}.${minor}.${release}`);
    });
  };

  return (
    <Grid
      container
      alignItems="center"
      paddingTop={3}
      paddingBottom={3}
    >
      {/* Left Column */}
      <Grid item xs={12}>
        <Grid style={{
          textAlign: "left",
          paddingLeft: "10px"
        }}>
          <Typography variant="h4">
            {gettext("Version Management")}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{
        p: 1
      }}>
        <Typography variant="body">
          {gettext("Use this page to update the application version. Once you change the version, the client-side application will automatically download and run the latest code from the server.")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{
          mt: 2
        }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                fullWidth
                id="major"
                label="Major"
                name="major"
                type="number"
                value={major}
                onChange={handleChangeMajor}
                inputProps={{
                  min: 0
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                fullWidth
                id="minor"
                label="Minor"
                name="minor"
                type="number"
                value={minor}
                onChange={handleChangeMinor}
                inputProps={{
                  min: 0
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                fullWidth
                id="release"
                label="Release"
                name="release"
                type="number"
                value={release}
                onChange={handleChangeRelease}
                inputProps={{
                  min: 0
                }}
              />
            </Grid>
          </Grid>
          <Button type="submit" fullWidth variant="contained" sx={{
            mt: 3,
            mb: 2
          }}>
            Update Version
          </Button>
        </Box>
        {updatedVersion && (
          <Typography variant="h6" component="h2" sx={{
            mt: 4
          }}>
            Current Version: {updatedVersion}
          </Typography>
        )}
      </Grid>

    </Grid>
  );
};
