import { Box } from "@mui/system";
import React from "react";

export const AuthPageCarousel = (props) => {

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignContent: "space-between",
          p: 3,
          m: 3,
          color: "white",
          maxWidth: "100%",
          height: `calc(100vh - 150px )`,
          borderRadius: 1
        }}
      >
        <Box>
          <h2>{props.item.name}</h2>
        </Box>
        <Box>
          <h1>{props.item.heading}</h1>
          <p>{props.item.description}</p>
        </Box>
      </Box>
    </>
  );
};
